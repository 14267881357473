import { CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import React, { useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import { StyledButton, StyledCrossIcon, StyledFormik } from "../../assets/styles/CommonStyles";
import strings from "../../constant/strings";
import FieldWrapper from "../InputField/TextFieldWrapper";
import * as mainService from "../../services/mainService.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { lipidProfileSchema } from "../../utils/forms/schema.lipidProfileSchema";

const LipidProfiles = ({ setOpenModal, getUpdatedValue }) => {
    const classes = useStyles();
    const [buttonLoading, setButtonLoading] = useState(false);

    const { userData } = useSelector((state) => ({
        userData: state.auth.userData
    }));

    let initialValues = {
        total_cholesterol: "",
        LDL_C: "",
        VLDL: "",
        HDL_C: "",
        triglycerides: "",
        notes: ""
    }

    const handleSubmit = async (values, {resetForm}) => {
        const data = {
            values,
            token: userData.token
        }
        const res = await mainService.addLipidProfileApi(data);
        if (res.response_type === "fail") {
            setButtonLoading(false);
            toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
        } else {
            resetForm();
            setButtonLoading(false);
            getUpdatedValue(true);
            setOpenModal(false);
            toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
        }
    }
    return (
        <div>
            <Grid className={classes.secondayCont}>
                <h2 className={classes.mainCont}>{strings.lipidProfile}</h2>
                <StyledCrossIcon
                    src={"/assets/img/x.png"}
                    alt="User"
                    onClick={() => setOpenModal(false)}
                />
            </Grid>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={lipidProfileSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        handleChange,
                        handleBlur,
                        errors, values,
                        handleSubmit,
                    }) => (
                        <StyledFormik>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FieldWrapper
                                        name="total_cholesterol"
                                        label={`${strings.cholesterol}`}
                                        type="number"
                                        value={values.total_cholesterol}
                                        onChange={(e) => {
                                            handleChange("total_cholesterol")(e);
                                        }}
                                        onBlur={handleBlur}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <FieldWrapper
                                        name="LDL_C"
                                        type="number"
                                        label={`${strings.ldlc}`}
                                        value={values.LDL_C}
                                        onChange={(e) => {
                                            handleChange("LDL_C")(e);
                                        }}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FieldWrapper
                                        name="VLDL"
                                        label={`${strings.vldl}`}
                                        type="number"
                                        value={values.VLDL}
                                        onChange={(e) => {
                                            handleChange("VLDL")(e);
                                        }}
                                        onBlur={handleBlur}
                                    />

                                </Grid>
                                <Grid item xs={12}>
                                    <FieldWrapper
                                        name="HDL_C"
                                        type="number"
                                        label={`${strings.hdlc}`}
                                        value={values.HDL_C}
                                        onChange={(e) => {
                                            handleChange("HDL_C")(e);
                                        }}
                                        onBlur={handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FieldWrapper
                                        name="triglycerides"
                                        type="number"
                                        label={`${strings.triglycerides}`}
                                        value={values.triglycerides}
                                        onChange={(e) => {
                                            handleChange("triglycerides")(e);
                                        }}
                                        onBlur={handleBlur}
                                    />
                                </Grid>

                            </Grid>
                            <Box m={1} pt={1}>
                                <StyledButton
                                    name="submit"
                                    disabled={Object.keys(errors).length > 0 || buttonLoading}
                                    onClick={handleSubmit}
                                    variant="contained">
                                    {buttonLoading ? (
                                        <CircularProgress
                                            style={{ color: "white" }}
                                            size={20}
                                            thickness={5}
                                        />
                                    ) : `${strings.save}`}

                                </StyledButton>
                            </Box>
                        </StyledFormik>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default LipidProfiles;