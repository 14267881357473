import React from "react";
import { makeStyles } from '@mui/styles';

import { DeptHeading, StyledHeading, StyledHeadingText,HeadingText, StyledHeadingText01} from "../../../assets/styles/CommonStyles";
import colors from "../../../constant/colors";
import fontWeights from "../../../constant/fontWeights";

const useStyles = makeStyles(() => ({
  textStyle: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight700,
    fontSize: "24px",
    lineHeight: "32px",
    color: colors.grey1
  }
}));
const HeadingComponent = ({ text, navigate }) => {
  const classes = useStyles();

  return (
    <>
      <StyledHeading className={navigate === 'doctor_profile' ? classes.textStyle : ""}>{text.primary}</StyledHeading>
      <StyledHeadingText>{text.secondary}</StyledHeadingText>
      {navigate === "organisation" && <HeadingText>{text.opd}</HeadingText>} 
    </>
  )
}

export default HeadingComponent;