import { makeStyles } from '@mui/styles';

import colors from '../../constant/colors';
import fontWeights from '../../constant/fontWeights';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight700,
    fontSize: "24px",
    lineHeight: "32px",
    color: colors.grey1
  },
  secondayCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  customDatePicker: {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      background: '#F8F7F7',   
    },
  },
}));

export { useStyles };
