import { createAction } from "@reduxjs/toolkit";

export const organizationProfileAction = createAction('user/organization/get-profile', (payload) => {
   return {
      payload:payload,
    };
  });

  export const updateOrganizationProfileAction = createAction('user/organization/update-profile', (payload) => {
       return {
       payload:payload,
     };
   });