import React, {useContext} from 'react';

import { toast } from 'react-toastify';

import { Context } from '../../../store';
import {
	getPrivacyAPI
} from '../../../api/website';
import {
	SET_ERROR,
	SET_COMMON_LOADING
} from '../../../store/types';


import {
    StyledContainer
} from './styles';

function PrivacyPolicy() {
	const [state, dispatch] = useContext(Context);

	const [privacyPolicyData, setPrivacyPolicy] = React.useState(null);

	React.useEffect(() => {
		loader(true);
		getPrivacyAPI().then(res => {
			if(res.data.length > 0) {
				setPrivacyPolicy(res.data[0].text);
			}
			
			loader(false);
		}).catch(err => {
			loader(false);
			toast.error(err.response.data.error)
			dispatch({
				type: SET_ERROR,
				payload: err.response.data.error
			});
		})
	}, []);

	const loader = (value) => {
		dispatch({
			type: SET_COMMON_LOADING,
			payload: value
		});
	}


	return (
		<StyledContainer>
			<section class="faq-section">
				<div class="container">
					<div class="row">
						<div class="col-md-6 offset-md-3">

							<div class="faq-title text-center pb-3">
								<h2>Privacy policy</h2>
							</div>
						</div>
						<div class="col-md-12 mb-5">

							{
								privacyPolicyData && <p
													className="excert"
													dangerouslySetInnerHTML={{
														__html: privacyPolicyData
													}}
												></p>
							}

						</div>
					</div>
				</div>
			</section>

		</StyledContainer>
	)
}

export default PrivacyPolicy
