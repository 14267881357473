import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,  
  error: null,
  organizationAppointmentList: [],
  
};

const organizationAppointmentSlice = createSlice({
  name: 'organizationAppointment',
  initialState,
  reducers: {
    //apointment list
    getOrganizationAppointmentListRequest(state) {
      state.organizationAppointmentList = [];
      state.loading = true;
      state.error = null;
    },
    getOrganizationAppointmentListSuccess(state, { payload }) {
      state.organizationAppointmentList = payload;
      state.loading = false;
      state.error = null;
    },
    getOrganizationAppointmentListFailure(state, { payload }) {
      state.organizationAppointmentList = [];
      state.loading = false;
      state.error = payload;
    },

}
});

export const { getOrganizationAppointmentListRequest, getOrganizationAppointmentListSuccess, getOrganizationAppointmentListFailure, 
  } = organizationAppointmentSlice.actions;

export default organizationAppointmentSlice.reducer;
