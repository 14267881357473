import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import SubAdminList from "../../components/ui/Organisation/SubAdmin";

const OrganisationSubAdminList = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <SubAdminList />
      </AppLayout>
    </Grid>
  )
}

export default OrganisationSubAdminList;