import { Grid } from "@mui/material";
import { Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import { commonMuiStyles } from "../../assets/styles/CommonMUIStyles.js";
import {
  StyledCrossIcon,
  StyledSecondaryButton,
} from "../../assets/styles/CommonStyles";
import { medicationStyles } from "../../assets/styles/MedicationStyles.js";
import strings from "../../constant/strings";
import * as mainService from "../../services/mainService.js";
import {
  admissionSchema,
  medHistorySchema,
  medicalDetailsSchema,
} from "../../utils/forms/schema.addPatientSubadmin.js";
import AdmissionDetails from "../ui/AddPatientBySubadmin/AdmissionDetails.js";
import MedicalDetails from "../ui/AddPatientBySubadmin/MedicalDetails.js";
import MedicalHistory from "../ui/AddPatientBySubadmin/MedicalHistory.js";

const AddPatientSubadmin = ({ setOpenModal, openModal }) => {
  const classes = useStyles();
  const medicationClasses = medicationStyles();
  const [onLoading, setOnLoading] = useState(false);
  const [screen, setScreen] = useState(0);
  const styleClasses = commonMuiStyles();
  const [doctorLists, setDoctorLists] = useState();

  let admissionInitialValues = {
    admission_date: "",
    reason: "",
    roomNumber: "",
    roomType: "",
    referernceName: "",
    referernceContactNo: "",
    mlcNo: "",
    firNo: "",
    patientUniqueId: "",
    countryCode: "",
    doctorId: "",
  };

  let medDetailsInitialValues = {
    diagonosis: "",
    BPSystolic: "",
    BPDiastolic: "",
    spo2: "",
    temp: "",
    pulse: "",
    rr: "",
    illnessSumary: "",
    cheifComplaints: "",
    generalAppearce: "",
  };

  let medHistoryInitialValues = { pastMedical: "", familyMed: "", history: "" };

  const { userData, doctorList } = useSelector((state) => ({
    userData: state.auth.userData,
    doctorList: state.appointment.doctorList || [],
  }));

  useEffect(async () => {
    let docList = await doctorList.map((item) => ({
      label: item.name,
      level: item._id,
    }));
    setDoctorLists(docList);
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    setOnLoading(true);
    delete values.roomType;

    const res = await mainService.admitPatientBySubadminApi(
      values,
      userData.token
    );
    if (res.response_type === "fail") {
      setOnLoading(false);
      resetForm();
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        x: { width: "auto", borderRadius: 8 },
      });
    } else {
      setOnLoading(false);
      setOpenModal(false);
      resetForm();
      toast.success(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    }
  };

  function removeEmptyKeys(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === "") {
        delete obj[key];
      }
    }
  }

  const handleScreen = (values) => {
    if (screen === 0) {
      if (values.referernceContactNo != "") {
        let phone = values.countryCode.replace("+", "");
        values.referernceContactNo = values.referernceContactNo.replace(
          phone,
          ""
        );
        delete values.countryCode;
      }
    }
    removeEmptyKeys(values);
    setScreen((prev) => prev + 1);
  };

  const handleCross = () => {
    setScreen(0);
    setOpenModal(false);
  };

  const getHead = useCallback(() => {
    switch (screen) {
      case 0:
        return "Patient information";
      case 1:
        return "Medical Details";
      case 2:
        return "Medical History";
    }
  }, [screen]);

  const getButtonText = useCallback(() => {
    switch (screen) {
      case 0:
        return "Add Patient Details";
      case 1:
        return "Add Medical Details";
      case 2:
        return "Add Medical History";
      case 3:
        return "Add Patient";
    }
  }, [screen]);

  return (
    <div>
      <Grid className={classes.secondayCont}>
        <h2 className={classes.mainCont}>{strings.addPatient}</h2>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="User"
          onClick={handleCross}
        />
      </Grid>
      <div>
        <Formik
          initialValues={
            screen === 0
              ? admissionInitialValues
              : screen === 1
              ? medDetailsInitialValues
              : medHistoryInitialValues
          }
          validationSchema={
            screen === 0
              ? admissionSchema
              : screen === 1
              ? medicalDetailsSchema
              : medHistorySchema
          }
          onSubmit={screen === 2 ? handleSubmit : handleScreen}
        >
          {({
            handleChange,
            handleBlur,
            errors,
            handleSubmit,
            values,
            touched,
            setTouched,
            setFieldValue,
            resetForm,
          }) => {
            const handleDropdownValue = (fieldName, data) => {
              setFieldValue(fieldName, data.level);
            };

            const handlePhone = (e, value) => {
              setFieldValue("countryCode", value);
              setFieldValue("phoneNumber", e);
            };

            return (
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  className={`${styleClasses.fontText14}`}
                  display="flex"
                  justifyContent="center"
                >
                  {getHead()}
                </Grid>
                {screen === 0 ? (
                  <AdmissionDetails
                    debugger
                    admissionInitialValues={admissionInitialValues}
                    handleDropdownValue={handleDropdownValue}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    setTouched={setTouched}
                    touched={touched}
                    doctorLists={doctorLists}
                  />
                ) : screen === 1 ? (
                  <MedicalDetails
                    handleDropdownValue={handleDropdownValue}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                ) : (
                  <MedicalHistory
                    handleDropdownValue={handleDropdownValue}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                )}
                <Grid
                  item
                  xs={12}
                  alignItems="center"
                  display="flex"
                  justifyContent={"center"}
                >
                  <StyledSecondaryButton
                    name="submit"
                    disabled={Object.keys(errors).length > 0 || onLoading}
                    onClick={handleSubmit}
                    variant="contained"
                  >
                    {getButtonText()}
                  </StyledSecondaryButton>
                </Grid>
              </Grid>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default AddPatientSubadmin;
