import React, { useState } from "react";
import {
  MainContainerStyle,
  StyledFormik,
  StyledViewMoreButton,
} from "../../../assets/styles/CommonStyles";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { Grid, Typography } from "@mui/material";
import { Formik, validateYupSchema } from "formik";
import FieldWrapper from "../../InputField/TextFieldWrapper";
import { addDiagnosis } from "../../../utils/forms/schema.addDiagnosis";

function Diagnosis() {
  const classes = commonMuiStyles();
  const [focus, setFocus] = useState(false);
  const initialValues = { diagnosis: "" };
  const user = localStorage.getItem("userType");

  const handleSubmit = (values) => {
    console.log("submitted", values);
  };

  return (
    <>
      <MainContainerStyle className={classes.fontText12}>
        {user === "doctor" ? (
          <>
            <Formik
              initialValues={initialValues}
              validationSchema={addDiagnosis}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, errors, handleChange, onFocus, onBlur, values }) => (
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <FieldWrapper
                      name="diagnosis"
                      type="text"
                      multiline={true}
                      label={ values.diagnosis.length === 0 ?  focus ? "" : "Type your diagnosis and observation...." : ''
                      }
                      rows={6.7}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                      sx = {{'& .MuiOutlinedInput-root': {
                        borderRadius: '8px  8px 0px 0px !important',
                        background: '#fff !important'
                      },
                    '& .MuiOutlinedInput-notchedOutline': {border: '0px'}}}
                    />{" "}
                  </Grid>
                  <Grid item xs={12}>
                    <StyledViewMoreButton onClick={() => handleSubmit()}>
                     Submit
                    </StyledViewMoreButton>
                  </Grid>
                </Grid>
              )}
            </Formik>
          </>
        ) : (
          <Typography
            className={classes.fontText12}
            sx={{
              textAlign: "left !important",
              overflow: "auto",
              height: "210px",
              padding: '10px'
            }}
          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s, when
            an unknown printer took a galley of type and scrambled it to make a
            type specimen book. Lorem Ipsum is simply dummy text of the printing
            and typesetting industry. Lorem Ipsum has been the industry's
            standard dummy text ever since the 1500s, when an unknown printer
            took a galley of type and scrambled it to make a type specimen book.
          </Typography>
        )}
      </MainContainerStyle>
    </>
  );
}

export default Diagnosis;
