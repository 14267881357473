import React from "react";
import Tables from "../../controls/Tables";
import { IPDMedication } from "../../../constant/staticLists";
import { StyledViewMoreButton } from "../../../assets/styles/CommonStyles";

export const IPDMedicationData = [
  {
    name: "Brufen",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
  {
    name: "Paracetamol",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
  {
    name: "Dolo 600",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
  {
    name: "Paracetamol",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
  {
    name: "Paracetamol",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
  {
    name: "Paracetamol",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
  {
    name: "Paracetamol",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
  {
    name: "Barnol",
    date: "2nd Nov 2022",
    form: "Capsule",
    strength: "50",
    strengthType: "Mg",
    duration: "Morning",
  },
];


function Medication({setOpenModal, handleOpen, setSelectedListItem}) {

  return (
    <>
      <div style={{ height: 230, overflow: "auto" }}>
        <Tables
          fieldsOrder={IPDMedication}
          data={IPDMedicationData}
          navigate="scrollable"
          setOpenModal = { setOpenModal }
          setSelectedListItem = { setSelectedListItem }
        />
      </div>
      <StyledViewMoreButton onClick={()=>handleOpen(6)} >
        Add form of Medication
      </StyledViewMoreButton>
    </>
  );
}

export default Medication;
