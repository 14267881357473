import { object, string, number, ref } from "yup";

export const resetPassword = object({
    password: string()
        .required("Current Password is required"),
    newPassword: string()
        .required('Please Enter new password')
        .min(8, "Minimum 7 characters required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30})/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
    newPasswordCopy: string().oneOf([ref('newPassword'), null], 'Passwords must match')
});


export const userProfile = object({
    name: string(),
    address: string(),
    contactNumber: string(),
    height: string(),
    dob: string(),
    gender: string(),
    weight: string(),
    bloodGroup: string(),
})

export const emergencyContacts = object({
    primary: object({
        name: string(),
        contactNumber: string(),
        relation: string(),
    }),
    second: object({
        name: string(),
        contactNumber: string(),
        relation: string(),
    }),
    third: object({
        name: string(),
        contactNumber: string(),
        relation: string(),
    })
})

export const orgProfile = object({
    org_name: string(),
    name: string(),
    phone_number: string(),
    country: string(),
    address: string(),
})

export const subadminProfile = object({
    email: string().email(),
    organizationId: string(),
    name: string(),
    gender: string(),
    address: string(),
    phone_number: number(),
    designation: string(),
    permission: string(),
    status: string(),
})