import { createAction } from "@reduxjs/toolkit";

export const getPatientListAction = createAction('organization/list/patient',(payload) => {
        return {
      payload: payload,
    };
  });
// export const getPatientDetailsAction = createAction()
export const getDoctorListAction = createAction('organization/list/doctor',(payload) => {
 
  return {
      
  payload: payload,
};
});

export const getSubadminListAction = createAction('organization/list/subadmin',(payload) => {
  return {
payload: payload,
};
});


