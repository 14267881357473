import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import SubadminRooms from "../../components/ui/Subadmin/Rooms";


const Rooms = () => {

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <SubadminRooms />
      </AppLayout>
    </Grid>
  )
}

export default Rooms;