import { apiRequest } from "./service";
import { LOGIN, SIGNUP, FORGOT_PASSWORD, GET_PATIENT_PROFILE, UPDATE_PATIENT_PROFILE, LOGOUT, DOCTOR_LOGIN, UPDATE_PASSWORD, VERIFY_TOKEN ,DOCTOR_LOGOUT, ORGANIZATION_LOGIN, ORGANIZATION_LOGOUT, SUBADMIN_LOGOUT, SUBADMIN_LOGIN, UPDATE_PROFILE_BY_ORGANIZATION, ORGANIZATION_SIGNUP, UPDATE_PROFILE_BY_SUBADMIN} from "./apiUrl";
let user = localStorage.getItem("userType")
export const forgotPasswordAPI = (payload) => {
  return apiRequest({
    apiUrl: FORGOT_PASSWORD,
    method: "POST",
    payload,
  });
}

export const updatePasswordAPI = (payload) => {
  const {token, password} = payload;
  return apiRequest({
    apiUrl: `${UPDATE_PASSWORD}${token}`,
    method: "PUT",
    payload: password,
  });
}

export const verifyTokenAPI = (payload) => {
  return apiRequest({
    apiUrl: `${VERIFY_TOKEN}${payload}`,
    method: "GET",  
  });
}

export const loginApi = (payload) =>
  apiRequest({
    apiUrl: LOGIN,
    method: "POST",
    payload,
  });

export const doctorloginApi = (payload) =>
  apiRequest({
    apiUrl: DOCTOR_LOGIN,
    method: "POST",
    payload,
  });

  export const organizationloginApi = (payload) =>
  apiRequest({
    apiUrl: ORGANIZATION_LOGIN,
    method: "POST",
    payload,
  });

export const signupApi = (payload) =>
  apiRequest({
    apiUrl: SIGNUP,
    method: "POST",
    payload,
  });

export const getProfile = (payload) =>
  apiRequest({
    apiUrl: GET_PATIENT_PROFILE,
    method: "GET",
    token: payload.token,
  });

export const updateProfile = (payload, token) => {
  
  console.log("user",payload)
  const url = user === "patient"?UPDATE_PATIENT_PROFILE:UPDATE_PROFILE_BY_SUBADMIN
  return apiRequest({
    apiUrl: url,
    method: "PUT",
    payload,
    token
  });
}
export const updateProfileSubadmin = (payload, token) => {
  
  console.log("user",payload)
  const url = user === "patient"?UPDATE_PATIENT_PROFILE:UPDATE_PROFILE_BY_SUBADMIN
  return apiRequest({
    apiUrl: url,
    method: "PUT",
    payload:payload.data,
    token:payload.token
  });
}


export const logout = (token) => {
  return apiRequest({
    apiUrl: `${LOGOUT}`,
    method: "POST",
    token,
    payload: {}
  })
}

export const doctorlogout = (token) => {
  return apiRequest({
    apiUrl: `${DOCTOR_LOGOUT}`,
    method: "POST",
    token,
    payload: {}
  })
}
export const organizationlogout = (token) => {
  return apiRequest({
    apiUrl: `${ORGANIZATION_LOGOUT}`,
    method: "POST",
    token,
    payload: {}
  })
}
export const organizationResetprofile = (payload, token) => {
  return apiRequest({
    apiUrl: UPDATE_PROFILE_BY_ORGANIZATION,
    method: "PUT",
    payload,
    token
  });
}
export const subadminlogout = (token) => {
  return apiRequest({
    apiUrl: `${SUBADMIN_LOGOUT}`,
    method: "POST",
    token,
    payload: {}
  })
}
export const subadminloginApi = (payload) =>
  apiRequest({
    apiUrl: SUBADMIN_LOGIN,
    method: "POST",
    payload,
  });
  export const organizationsignupApi = (payload) =>{  
    return apiRequest({
      apiUrl: ORGANIZATION_SIGNUP,
      method: "POST",
      payload     
    })}