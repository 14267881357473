import {object, string, number, array} from 'yup';

export const addServiceSchema = object({
    name: string()
    .min(4, 'length is too short')
    .max(40, 'length is too long')
    .required('name is required'),
    date: string()
    .required('Date is required'),
    time: string()
    .required('time is required'),
    amount: number()
    .required('Amount is required')
    .typeError('Only number are acceptable')
    .min(0,'Price cannot be less than 0'),
    currency: string()
    .required('Please select currency type')
})