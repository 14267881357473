import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';

import { getOrganizationAppointmentListApi } from '../../../services/mainService';
import { getOrganizationAppointmentListFailure, getOrganizationAppointmentListRequest, getOrganizationAppointmentListSuccess } from './organizationAppointmentSlice';
import { getOrganizationAppointmentListAction } from './organizationAppointmentActions';

//appointment list
function* getOrganizationAppointmentList(action) {
  try {
    yield put(getOrganizationAppointmentListRequest())  
    const apiResponse = yield call(getOrganizationAppointmentListApi, action.payload);
    if (Object.values(apiResponse).length === 0) {
      // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
    } else {
      if (apiResponse.response_type === "success") {
        const data = apiResponse.response.data;
        yield put(getOrganizationAppointmentListSuccess(data));

      } else {
        toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 1000, style: {width: 'auto', borderRadius: 8 }});
        yield put(getOrganizationAppointmentListFailure(apiResponse.response.message))
      }
    }
  } catch (error) {  }
}



 export function* organizationAppointmentSaga() {
   yield takeLatest(getOrganizationAppointmentListAction.type, getOrganizationAppointmentList);


}