import styled from 'styled-components';

export const StyledContainer = styled.div`
    .faq-section {
        background: #fdfdfd;
        min-height: 100vh;
        padding: 10vh 0 0;
    }
    .faq-title h2 {
    position: relative;
    margin-bottom: 45px;
    display: inline-block;
    font-weight: 600;
    line-height: 1;
    }
    .faq-title h2::before {
        content: "";
        position: absolute;
        left: 50%;
        width: 60px;
        height: 2px;
        background: #19ce67;
        bottom: -25px;
        margin-left: -30px;
    }
    .excert > h5 {
        width: 100%;
        text-align: justify !important;
        margin-bottom: 8px !important;
    }
    .excert > ul > li > span {
        font-size: 1rem !important;
    }
    .excert > h5 > span:first-child {
        display: block;
        margin-bottom: 8px !important;
    }
    .excert > h5:first-child span:nth-child(7) { 
        display: block;
    }
    .faq-title h2::before {
        left: 0;
        margin-left: 0px;
        width: 100%;
    }
`;
