import { Close, ClosedCaption, UploadFileOutlined, WarningAmber } from '@mui/icons-material'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select, TableContainer, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system'
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { addMedicalReport, deleteMedicalReport } from '../../../services/mainService';
import { useDispatch, useSelector } from 'react-redux';
import { getPatientMedicalReportAction } from '../../../redux/user/userActions';
import { isMobile } from "react-device-detect";
import { handleFileUploadToS3 } from '../../../utils';
import { LoadingContainer, NoDetailsContainer, StyledButton, StyledSecondaryButton } from '../../../../src/assets/styles/CommonStyles';
import strings from '../../../constant/strings';
import { dashoboardUseStyles } from '../../../assets/styles/DoctorDashboardStyles';
import { reportHeadingList, validExtensions } from '../../../constant/staticLists';
import Tables from '../../controls/Tables';
import CancelIcon from '@mui/icons-material/Cancel';
import Dialogs from '../../controls/Dialogs';
import axios from 'axios';
import { PATIENT_UPLOAD } from '../../../services/apiUrl';
import * as mainService from "../../../services/mainService"

const WrapperContainer = styled(Grid)(({ theme }) => ({
  '& .uploadFileWrapper': {
    '& > .MuiGrid-root': {
      paddingLeft: 0,
      paddingBottom: 8,
      '& .MuiTypography-root': {
        height: 18,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 14,
        lineHeight: '18px',
        color: '#2B2B2B'
      },
      '& + .uploadFileBox': {
        '& input': {
          opacity: 0,
          zIndex: -1,
        },
        padding: 0,
        height: 200,
        display: 'inline-block',
        borderRadius: 8,
        border: '2px dashed #fff',
        background: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%) border-box',
        borderRadius: '8px',
        boxShadow: 'border-box',
        width: '100%',
        '& > .MuiBox-root': {
          height: 200,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'linear-gradient(90deg, rgba(9, 126, 183, 0.05) 0%, rgba(42, 171, 108, 0.05) 100%)',
          '& > .MuiBox-root': {
            width: 48,
            height: 48,
            background: 'linear-gradient(90deg, rgba(9, 126, 183, 0.2) 0%, rgba(42, 171, 108, 0.2) 100%)',
            borderRadius: 48,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 16,
            '& > .MuiSvgIcon-root': {
              fill: 'url(#header-shape-gradient)',
              margin: 'auto',
              height: 24,
              width: 24
            },
          },
          '& .MuiTypography-root': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '18px',
            textTransform: 'capitalize',
            background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
            '-webkit-background-clip': 'text',
            backgroundClip: 'text',
            '-webkit-text-fill-color': '#5E5E5E',
            textFillColor: '#5E5E5E',
          },
          '& .filetypes': {
            textTransform: 'lowercase',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: '18px',
            marginBottom: -30,
          },
          '& .MuiStack-root': {
            display: 'flex',
            flexDirection: 'row',
            marginBottom: 4,
            '& .MuiTypography-root': {
              textTransform: 'inherit',
              fontWeight: 700,
              '& + .MuiTypography-root': {
                paddingLeft: 3,
                textTransform: 'inherit',
                '-webkit-text-fill-color': 'transparent',
                textFillColor: 'transparent',
              },
            },
          }
        }
      }
    }
  },
  '& .MuiInputBase-root': {
    position: 'absolute',
    // width: '249px',
    height: '48px',
    background: '#F8F7F7',
    border: '1px solid #CCCCCC',
    borderRadius: '8px',
    '& input': {
      border: 'none'
    }
  },
  '& .selectBox': {
    boxSizing: 'border-box',
    position: 'absolute',
    // width: '88px',
    height: '48px',
    background: '#F8F7F7',
    border: '1px solid #CCCCCC',
    borderRadius: '8px',
    '& input': {
      border: 'none'
    }
  },
  '.textSize': {
    width: '120px',
    lineHeight: '24px',
  }
}))


const FileWrapper = styled(Grid)(() => ({
  width: '67px',
  height: '18px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#2B2B2B',
  marginBottom: '24px'
}))


const StyledTableContainer = styled(TableContainer)(() => ({
  width: 'calc(100% + 64px)',
  marginLeft: '-40px',
  marginBottom: '-24px'
}))

const StyledTableContainerDoctor = styled(TableContainer)(() => ({
  width: 'calc(100% + 64px)',
}))


const StyledDialogTitle = styled(DialogTitle)(() => ({
  position: 'relative',
  width: '100%',
  height: '32px',
  '& button': {
    position: 'absolute',
    right: 8,
    top: 6,
    margin: 'auto',
    '& svg': {
      height: 24,
      width: 24,
    }
  }
}))

const StyledDialogContent = styled(DialogContent)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg': {
    fill: '#F36363',
    width: '72px',
    height: '72px'
  }
}))

const StyledDialogContentText = styled(DialogContentText)(() => ({
  height: '18px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#5E5E5E',
}))

const StyledDialogActions = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 20px',
  minWidth: '384px'
}))

const StyledCancelButton = styled(Button)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 80px',
  width: '160px',
  height: '48px',
  border: '1px solid #5E5E5E',
  borderRadius: '8px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  color: '#5E5E5E',
}))

const StyledDeleteButton = styled(Button)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 80px',
  width: '160px',
  height: '48px',
  border: '1px solid #F36363',
  borderRadius: '8px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '24px',
  background: '#F36363',
  color: '#FFFFFF',
}))

const StyledSpan = styled.span`
    background: var(--primary, linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
`;

const StyledDiv = styled.div`
    color: var(--black-2, #5E5E5E);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-align: center; /* 128.571% */`;
export default React.memo(function MedicalReports({ navigate }) {
  const classes = dashoboardUseStyles();
  const [state, setState] = useState(false);
  const [selectedFile, setFiles] = useState("")
  const [displayCount, setDisplayCount] = useState(3);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [show, setShow] = useState("");
  const url= PATIENT_UPLOAD
  const [open, setOpen] = useState(false);
  let Component = navigate !== "doctor" ? StyledTableContainer : StyledTableContainerDoctor
  const { userData, medicalReport, patientAppointmentInfoListLoader } = useSelector(state => ({
    medicalReport: state.user?.medicalReport || [],
    userData: state.auth.userData,
    patientAppointmentInfoListLoader: state.user.patientAppointmentInfoListLoader
  }))

  const dispatch = useDispatch();

  //let list = useMemo(() => navigate != 'doctor' ? medicalReport : patientMedicalReport, [patientMedicalReport, medicalReport])

  useEffect(() => {
    if (navigate != 'doctor')
      dispatch(getPatientMedicalReportAction({ token: userData.token }))
  }, [])

  const onDrop = useCallback(function (acceptedFiles) {
    const limit = 1;
    const fileExtension = acceptedFiles[0].name.split('.').pop().toLowerCase();
    if (!validExtensions.includes(fileExtension)) {
      setOpen(true);
      setShow("Please select file format from pdf,png,jpeg,jpg")
    }
    else if (acceptedFiles[0].size / 1024 / 1024 > limit) {
      setOpen(true);
      setShow("The size of file cannot exceed more than 10 MB.Please try with file of less size ")
    }
    else {
      setFiles(acceptedFiles[0]);     
    }    
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { onClick, onDrop: onDropRoot, ...rootRest } = getRootProps();
  const { ref, onChange, ...rest } = getInputProps();

  const handleSubmitFunctions = async (values, { resetForm }) => {
    if (selectedFile === "") {
      toast.error("Please select file", { position: "bottom-center", closeButton: false, autoClose: 5000, style: { width: 'auto', borderRadius: 8 } });
    }
    else {
      setButtonLoading(true)
      const clonedValues = JSON.parse(JSON.stringify(values))
      console.log("values",values)
      console.log("clonedValues",selectedFile)
      const formData = new FormData();
      formData.append('file', selectedFile);

      try {
        console.log("formdata", formData)
        const response1 = await mainService.fileUpload(url, formData,userData.token)   
        
        // const temp = [...filesList, response.data.data]
        // setFiles(temp);
        //  setFieldValue("documents", temp);
    
        clonedValues.report =response1.response.data;
        const response = await addMedicalReport({ values: clonedValues, token: userData.token })
        if (response.response_type === "success") {
          toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
          dispatch(getPatientMedicalReportAction({ token: userData.token }))
          setButtonLoading(false)
          resetForm();
          setFiles("")
        } else {
          toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
          setButtonLoading(false)
          resetForm();
          setFiles("")
        }


        
      } catch (error) {
        console.error('Error:', error);
      }
   
    }

  }

  const handleDelete = async (id) => {
    if (id) {
      const response = await deleteMedicalReport({ id: id, token: userData.token });
      if (response.response_type === "success") {
        toast.success(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
        dispatch(getPatientMedicalReportAction({ token: userData.token }))
        setState({ open: false, id: null })
      } else {
        toast.error(response?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
      }
    } else {
      toast.error('Please select Id', { position: "bottom-center", closeButton: false, autoClose: 2000, style: { width: 'auto', borderRadius: 8 } });
    }
  }

  const handleShowMore = () => {
    setDisplayCount(displayCount + 3);
  };
  const removeFile = () => {
    setFiles("")
  }

  return <>
    {navigate === "doctor" ?
      <div className={`${classes.headingTextStyle} ${classes.margin}`}>
        {strings.prevReports}
      </div>
      :
      <WrapperContainer container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={2} className='uploadFileWrapper'>
            <Formik
              initialValues={{
                report: "",
                type: "",
                name: ""
              }}
              validationSchema={object({
                name: string().required("Name is required"),
                type: string().required("Type is required"),
                report: string(),
              })}
              onSubmit={handleSubmitFunctions}
            >
              {({
                handleChange,
                handleBlur,
                errors,
                setValues,
                touched,
                handleSubmit,
                values,
                resetForm
              }) => {
                return (<>
                  <Grid item xs={12}>
                    <Typography>{strings.uploadFile}</Typography>
                  </Grid>
                  <Grid item xs={12} className='uploadFileBox' {...rootRest} onDrop={(...args) => onDropRoot.call({ setValues }, ...args, setValues)}>
                    <Box onClick={onClick} sx={{ cursor: 'pointer' }}>
                      <Box>
                        <UploadFileOutlined />
                      </Box>
                      <Stack>
                        <StyledDiv>Drag and drop files, or <StyledSpan onClick={onClick} sx={{ cursor: 'pointer' }}>Browse</StyledSpan></StyledDiv>
                      </Stack>

                      <div className='filetypes'>pdf, jpg, jpeg & png files</div>
                      <input type="file" name="report" ref={ref} {...rest}
                        onChange={(e) => { onChange(e); }} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {(!!selectedFile && selectedFile !== "") && <div><UploadFileOutlined />{selectedFile?.name} <IconButton onClick={removeFile}><CancelIcon /></IconButton></div>}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justifyContent={'space-between'} spacing={4}>
                      <Grid item xs={12} md={4} lg={4} background="yellow">
                        <Grid container justifyContent={'space-between'} spacing={1}>
                          <Grid item xs={6} lg={8} md={8}>
                            <TextField
                              fullWidth
                              placeholder='Enter the document name'
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={errors.name}
                              helperText={!!errors.name && !!touched.name && errors.name}
                            />
                          </Grid>
                          <Grid item xs={6} lg={4} md={4}>
                            <FormControl sx={{ width: '100%' }}>
                              <InputLabel>Type</InputLabel>
                              <Select
                                name="type"
                                className='selectBox'
                                label='Type'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                fullWidth
                                error={errors.type}
                                helperText={!!errors.type && !!touched.type && errors.type}
                              >
                                <MenuItem value="1">Prescription</MenuItem>
                                <MenuItem value="2">Report</MenuItem>
                                <MenuItem value="3">Letter</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={12} lg={2} display={"flex"} justifyContent={isMobile ? 'center' : 'flex-end'}>
                        <StyledSecondaryButton type="submit" onClick={handleSubmit.bind(this, values)} disabled={Object.keys(errors).length > 0 } >
                          <div className='textSize'>
                            {buttonLoading ? <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                              thickness={5}
                            /> : 'Upload'}
                          </div>
                        </StyledSecondaryButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </>)
              }}
            </Formik>
            <FileWrapper item xs={12}>
              Your Files
            </FileWrapper>
          </Grid>
        </Grid>
      </WrapperContainer>
    }
    {patientAppointmentInfoListLoader ? <LoadingContainer>{strings.loading}</LoadingContainer> : <Component>
      {!!medicalReport && medicalReport.length > 0 ?
        <Tables
          data={medicalReport.slice(0, displayCount)}
          navigate={navigate}
          fieldsOrder={reportHeadingList}
          handleDeleteReports={handleDelete}
        />
        :
        <NoDetailsContainer className={classes.noDataTextStyle}>{strings.noReportsAvailable}</NoDetailsContainer>
      }

      {displayCount < medicalReport.length && (
        <StyledButton
          name="submit"
          onClick={handleShowMore}
          variant="contained">
          {strings.showMore}
        </StyledButton>
      )}
    </Component>}
    <Dialog
      open={state.open}
      onClose={setState.bind(null, { id: null, open: false })}
    >
      <StyledDialogTitle>
        <IconButton onClick={setState.bind(null, { open: false, id: null })}>
          <Close />
        </IconButton>
      </StyledDialogTitle>
      <StyledDialogContent>
        <WarningAmber />
        <StyledDialogContentText>
          Are you sure you want to delete this file?
        </StyledDialogContentText>
      </StyledDialogContent>
      <StyledDialogActions>
        <StyledCancelButton onClick={setState.bind(null, { open: false, id: null })}>Cancel</StyledCancelButton>
        <StyledDeleteButton onClick={handleDelete}>Delete</StyledDeleteButton>
      </StyledDialogActions>
    </Dialog>
    {open && <Dialogs setOpen={setOpen} open={open} content={show} />}
  </>
})