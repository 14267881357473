import React from 'react';
import { Box, Typography } from '@mui/material';

const SubscriptionPending = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(135deg, rgba(9, 126, 183, 1), rgba(42, 171, 108, 1))', // Linear gradient background
        color: '#fff',
        textAlign: 'center',
        padding: 2,
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        Subscription Details
      </Typography>
      <Typography variant='h5'sx ={{marginBottom:2}}>
      Pending
      </Typography>
      <Typography variant="h6" sx={{ marginTop: 4 }}>
         wait for the payment to succeed
      </Typography>
    </Box>
  );
};

export default SubscriptionPending;
