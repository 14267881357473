import React, { useEffect, useState } from "react";
import ProfileSetting from "../../ProfileSetting";
import strings from "../../../../constant/strings";
import ResetPassword from "../../../ResetPassword";
import { Grid } from "@mui/material";
import SubadminDetails from "./SubadminDetails";
import { subadminProfileAction } from "../../../../redux/user/subadmin/subadminAction";
import { useDispatch, useSelector } from "react-redux";

const SubadminProfileSetting = () => {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));

  useEffect(() => {
    dispatch(subadminProfileAction(userData.token));
  }, []);

  const tabs = [
    { id: 0, name: "Sub-admin details" },
    { id: 1, name: "Reset password" },
  ];

  return (
    <>
      <ProfileSetting
        tabValue={tabValue}
        setTabValue={setTabValue}
        tabs={tabs}
        profileHead = { strings.subadminProfile}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} mt = {2}>
          {tabValue === 0 ? <SubadminDetails /> : <ResetPassword />}
        </Grid>
      </Grid>
    </>
  );
};

export default SubadminProfileSetting;
