import { Grid } from "@mui/material";
import React, { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import { StyledViewMoreButton } from "../../../../assets/styles/CommonStyles";
import { subadminDashboardStyles } from "../../../../assets/styles/subadminDashboardStyle";
import { subadminOPDdashboard } from "../../../../constant/staticLists";
import strings from "../../../../constant/strings";
import HeadingComponent from "../../HeadingComponent";
import OPDappointment from "../../OPDappointment.js";
import DoctorList from "../../Organisation/Doctor/index.js";
import PatientList from "../../Organisation/Patient/index.js";
import DoctorListing from "./DoctorListing";
import PatientLists from "./PatientLists";
import IPDlist from "../../IPD/IPDlist.js";

const SubadminHome = () => {
  const classes = subadminDashboardStyles();
  const history = useHistory();
  const [screen, setScreen] = useState(0);

  const handleIPD = () => {
    history.push("/subadmin/ipd");
  };

  return (
    <>
      {screen === 0 ? (
        <Grid container spacing={2} sx={{ margin: "16px 0" }}>
          <Grid item xs={12}>
            <HeadingComponent
              text={{
                primary: strings.home,
                secondary: strings.homeHeadingText1,
              }}
            />
          </Grid>
          <Grid item xs={12} lg={8} md={8}>
            <HeadingComponent
              navigate="organisation"
              text={{
                opd: strings.opd,
              }}
            />
            <div className={`${classes.contBorderStyle} ${classes.contStyle}`}>
              <OPDappointment
                fieldOrder={subadminOPDdashboard}
                navigate="subadminDashboard"
              />
            </div>
            <StyledViewMoreButton
              onClick={() => history.push("/subadmin/appointments")}
            >
              View OPD appointment list
            </StyledViewMoreButton>
          </Grid>
          <Grid item xs={12} lg={4} md={4}>
            <PatientLists setScreen={setScreen} />
          </Grid>
          <Grid item xs={12} lg={8} md={8}>
            <HeadingComponent
              navigate="organisation"
              text={{
                opd: strings.ipd,
              }}
            />
            {/* <div className={`${classes.contBorderStyle} ${classes.contStyle}`}>
              <IPDlist navigate="subadminDashboard" />
            </div> */}

            {/* <StyledViewMoreButton onClick={handleIPD}>
              View IPD appointment list
            </StyledViewMoreButton> */}
          </Grid>
          {/* <Grid item xs={12} lg={4} md={4}>
            <DoctorListing setScreen={setScreen} />
          </Grid> */}
        </Grid>
      ) : screen === 2 ? (
        <PatientList navigate="subadmin" setScreen={setScreen} />
      ) : screen === 3 ? (
        <DoctorList navigate="subadmin" setScreen={setScreen} />
      ) : (
        ""
      )}
    </>
  );
};

export default SubadminHome;
