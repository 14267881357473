import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { IPDdashoboardUseStyles } from "../../../assets/styles/IPDDashboard";
import colors from "../../../constant/colors";
import { modalStyle } from "../../../assets/styles/CommonStyles";
import PatientInfo from "../../Modal/PatientInfo";
import InformationPair from "../../controls/InformationPair";
import { basicPatientDetail } from "../../../constant/staticLists";
import ProfileImage from '../../../assets/img/userProfile.png';

export const basicPatientData = 
    { id: "624afc8cc16ec212e4" ,
     age : 24 ,
    phone_number: "+91-9897656744" }
  

function PatientDetails() {
  const classes = commonMuiStyles();
  const dashboardClasses = IPDdashoboardUseStyles();
  return (
    <>
      <Grid container spacing={0} mt={1}>
        <Grid
          item
          xs={4}
          className={classes.centerStyle}         
        >
          <img
            src={ProfileImage}
            alt="img"
            className={dashboardClasses.image86}
          ></img>
        </Grid>
        <Grid item xs={8}>
          <Typography className={dashboardClasses.font16} color={colors.black1}>
            Swati Sharma
          </Typography>
          <InformationPair fieldOrder={basicPatientDetail} data={basicPatientData} />
        </Grid>
      </Grid>
      
    </>
  );
}
export default PatientDetails;
