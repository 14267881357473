import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import SubadminIPD from "../../components/ui/Subadmin/IPD";


const IPD = () => {
  return (
    <Grid container spacing={0}>
      <AppLayout>
        <SubadminIPD />
      </AppLayout>
    </Grid>
  )
}

export default IPD;