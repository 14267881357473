import { createAction } from "@reduxjs/toolkit";

export const getRoomListAction = createAction('ipd/roomList/subadmin',(payload) => {
        return {
      payload: payload,
    };
  });

export const getAdmitedPatientAction = createAction('ipd/admitted-patient/subadmin',(payload) => {
    return {
  payload: payload,
  };
});

export const getAdmitedPatientServiceAction = createAction('ipd/patient-service/subadmin',(payload) => {
  return {
payload: payload,
};
});