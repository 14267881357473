import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subadminProfileData: {},
  loading: false,
  error: null,

};

const subadminSlice = createSlice({
  name: 'subadmin',
  initialState,
  reducers: {
    getProfileBySubadminRequest(state) {
      state.subadminProfileData = state.subadminProfileData;
      state.loading = true;
      state.error = null;
    },
    getProfileBySubadminSuccess(state, { payload }) {
      state.subadminProfileData = payload;
      state.loading = false;
      state.error = null;
    },
    getProfileBySubadminFailure(state, { payload }) {
      state.subadminProfileData = {};
      state.loading = false;
      state.error = payload;
    },

    updateProfileBySubadminRequest(state) {
      state.subadminProfileData = {};
      state.loading = true;
      state.error = null;
    },
    updateProfileBySubadminSuccess(state, { payload }) {
      state.subadminProfileData = payload;
      state.loading = false;
      state.error = null;
    },
    updateProfileBySubadminFailure(state, { payload }) {
      state.subadminProfileData = {};
      state.loading = false;
      state.error = payload;
    },

  },
});

export const {getProfileBySubadminRequest, getProfileBySubadminSuccess, getProfileBySubadminFailure,
updateProfileBySubadminRequest, updateProfileBySubadminSuccess, updateProfileBySubadminFailure} = subadminSlice.actions;

export default subadminSlice.reducer;
