import React, { useCallback, useEffect, useState } from "react";
import { Grid, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import styled from "styled-components";
import { Box } from "@mui/system";
import SearchFields from "./SearchFields";
import DoctorsCard from "./DoctorsCard";
import { StyledSecondaryButton } from "../../../assets/styles/CommonStyles";
import CalenderBlock from "../../../components/control/CalenderBlock";
import AvailableSlotsBlock from "./AvailableSlotsBlock";
import PatientInputForm from "./PatientInputForm";
import Checkout from "./Checkout";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorListAction, getDoctorSlotsAction } from "../../../redux/appointment/patient/patientAppointmentActions";
import dayjs from "dayjs";
import strings from "../../../constant/strings";
import moment from "moment";
import colors from "../../../constant/colors";

const actionCellStyles = {
  "& .MuiIconButton-root": {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    background: colors.textColor,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
    "background-clip": "text",
    "text-fill-color": "transparent",
    "& svg": {
      width: "18px",
      height: "18px",
      marginRight: "5px",
      fill: `${colors.blue}`,
    },
  },
};

export const StyledBoxViewAllAppointments = styled(Box)(() => ({
  position: "absolute",
  top: "0px",
  bottom: "0px",
  left: "0px",
  ...actionCellStyles,
}));

const StyledBoxSteps = styled(Box)(() => ({
  height: "100%",
  alignItems: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "14px",
  lineHeight: "18px",
  background: colors.textColor,
  "-webkit-background-clip": "text",
  "-webkit-text-fill-color": "transparent",
  backgroundClip: "text",
  textFillColor: "transparent",
}));

const StyledGridContainer = styled(Grid)(() => ({
  fontFamily: "Poppins",
  fontStyle: "normal",
  "& .heading-font": {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#2B2B2B",
    marginBottom: "13px",
  },
}));

const AppointMentBooking = ({
  setBookAppointment,
  step,
  setStep,
  bookAppointment,
  confirmBooking,
  navigate
}) => {
  const dispatch = useDispatch();
  const [doctorlist, setDoctorList] = useState([]);
  const [itemValue, setItemValue] = useState({});
  const [date, setDate] = useState(dayjs());
  const [visit, setVisitType] = useState(0);
  const [slot, setSlot] = useState({});
  const { doctorList, userData } = useSelector((state) => ({
    doctorList: state.appointment.doctorList || [],
    userData: state.auth.userData,
    setDoctorList: state.appointment.patientAppointmentList || [],
  }));
  let user = localStorage.getItem("userType")
  const [data, setData] = useState({});

   
  useEffect(() => {
    setDoctorList(doctorList);
  }, [doctorList]);

  const handleStepChange = useCallback((value, item, visit) => {
    console.log("userData",value)
    console.log("visit",visit)
    setItemValue(item);
    setVisitType(visit);
    setData((prevData) => ({
      ...prevData,
      doctorId: item._id,
      payment_type: visit ==1?2:1
   

    }));
    setStep(value);
    if (value === 2) {
      setStep(value);
      dispatch(
        getDoctorSlotsAction({
          token: userData.token,
          id: item._id,
          date: moment(new Date()).format('YYYY-MM-DD'),
          type: visit,
        })
      );
    }
  }, []);
  useEffect(() => {
      dispatch(getDoctorListAction(userData.token));
  }, []);
  const dateValue = (value) => {
    const formattedDate = moment(new Date(value)).format("YYYY-MM-DD")
    setDate(formattedDate);
    dispatch(
      getDoctorSlotsAction({
        token: userData.token,
        id: itemValue._id,
        date: formattedDate,
        type: visit,
      })
    );
  };

  const visitType = (value) => {
    setVisitType(value);
    setSlot({});
    dispatch(
      getDoctorSlotsAction({
        token: userData.token,
        id: itemValue._id,
        date: date,
        type: value,
      })
    );
  };

  const handleClickBack = () => {
    if (step - 1 == "1") {
      setSlot({});
    }
    if (step > 1) return setStep((pre) => pre - 1);
    if (step === 1) {
      setBookAppointment(false);
    }
  };

  const getText = useCallback(() => {
    if (bookAppointment === "checkout") {
      return "Back to personal details";
    }
    if (bookAppointment) {
      if (step === 2) {
        return "Back to Doctor’s list";
      }
      if (step === 3) {
        return "Back to Appointments";
      }
      if (step === 4) {
        return "Back to personal details";
      }
    }
    return "View all Appointments";
  }, [step, bookAppointment]);

  const handleValue = (value) => {
    const searchResults = doctorlist.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setDoctorList(searchResults);
    if (value === "") {
      setDoctorList(doctorList);
    }
  };

  const slotId = (item) => {
    setSlot(item);
    setData((prevData) => ({
      ...prevData,
      slotId: item._id,
     
    }));
  };

  const detailObject = (object) => {
    console.log('object----',object)
    setData((prevData) => ({
      ...prevData,
      ...object,
     
    }));
    setStep(4);
  };

  return (
    <StyledGridContainer container spacing={0}>
      <Grid
        item
        justifyContent={"center"}
        alignItems={"center"}
        xs={12}
        position={"relative"}
        height={"35px"}
      >
        <StyledBoxViewAllAppointments>
          <IconButton onClick={handleClickBack}>
           <ChevronLeftIcon />
            {getText()}
          </IconButton>
        </StyledBoxViewAllAppointments>
        {step < 4 ? (
          <StyledBoxSteps justifyContent={"center"} display={"flex"}>
            Step {step} of 3
          </StyledBoxSteps>
        ) : (
          ""
        )}
      </Grid>
      {step === 1 && (
        <Grid
          item
          xs={12}
          justifyContent={"start"}
          pt={"16px"}
          sx={{
            height: "50px",
            boxSizing: "content-box",
          }}
        >
          <SearchFields width={"380px"} handleValue={handleValue} placeholder={strings.searchByDoctorName} />
        </Grid>
      )}
      <Grid item xs={12} pt={"32px"} sx={{ boxSizing: "content-box" }}>
        <Grid container spacing={0} width={"100%"}>
          {step === 1 &&
            !!doctorlist &&
            doctorlist.length > 0 &&
            doctorlist.map((item, index) => (
              <DoctorsCard
                handleStepChange={handleStepChange}
                key={item._id}
                index={index}
                step={step}
                item={item}
                showFullList={true}
                slot={slot}
                visitType={visitType}
              />
            ))}
          {step === 2 && (
            <>
              <Grid item xs={12} md={6} lg={4} xl={4} width={"100%"}>
                <DoctorsCard
                  handleStepChange={handleStepChange}
                  step={step}
                  item={itemValue}
                  visitType={visitType}
                  slot={slot}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} width={"100%"}>
                <Typography className="heading-font">Book Calender</Typography>
                <CalenderBlock
                  dateValue={dateValue}
                  date={date}
                  setDate={setDate}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4} xl={4} width={"100%"}>
                <Typography className="heading-font">
                  Available Slots {visit ? "(In clinic)" : "(Online)"}{" "}
                </Typography>
                <AvailableSlotsBlock slotId={slotId} slot={slot} />
              </Grid>
              <Grid item xs={12} justifyContent={"flex-end"} display={"flex"}>
                <StyledSecondaryButton
                  onClick={() => handleStepChange(3, itemValue, visit)}
                  disabled={!data.slotId}
                  sx={{
                    width: '33% !important',
                    fontWeight: 500,
                    fontSize: "14px",
                    lineHeight: "24px",
                    minWidth: "350px",
                    marginTop: 2,
                  }}
                >
                  Proceed to Patient Input
                </StyledSecondaryButton>
              </Grid>
            </>
          )}
          {step === 3 && (
            <PatientInputForm detailObject={detailObject} data={data} navigate={navigate} />
          )}
          {step === 4 && (
            <Checkout
              data={data}
              item={itemValue}
              slot={slot}
              confirmBooking={confirmBooking}
              visitType={visitType}
              date={date}
              visit={visit}
            />
          )}
        </Grid>
      </Grid>
    </StyledGridContainer>
  );
};

export default React.memo(AppointMentBooking);
