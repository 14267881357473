import { makeStyles } from "@mui/styles";
import colors from "../../../../../../constant/colors";

const useStyles = makeStyles(() => ({
       
    selectedRadio :{   
        border:  `2px solid ${colors.blue}`,
        color: colors.blue,
    },
}));

export { useStyles };
