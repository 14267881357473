import React, { useState } from "react";
import { Modal, Wrapper } from "../styles";
import {
  StyledCrossIcon,
  StyledDivider,
  StyledFormik,
  StyledImageCont,
  StyledMargin,
  StyledText,
  StyledButton,
  SuccessLogo,
  StyledMainModalCont,
} from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import strings from "../../../../constant/strings";
import { Grid, Box, CircularProgress } from "@mui/material";
import { useStyles } from "../../../../assets/styles/RadioButtonStyles";
import {
  StyledTextBold,
  StyledTextContent,
} from "../../../../assets/styles/SuccessPageStyles";
import DoctorSignUpSteps from "./commonComponents/DoctorSignUpSteps";
import { organizationsignupApi } from "../../../../services/authService";
import { toast } from "react-toastify";

const Verification = ({ data, setLoginToggle, loginToggle, setSignUpModal }) => {
  const classes = useStyles();
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async() => {
    setButtonLoading(true);
    const res = await organizationsignupApi(data)
    console.log(res)
		if (res.response_type === "fail"){
      setButtonLoading(false);
			toast.error(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
		} else {
			toast.success(res?.response?.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
      setButtonLoading(false);
      setSignUpModal(false);
      // setLoginToggle(true);
		}
  }

  return (
    <>
      <SuccessLogo>
        <img
          src="/assets/img/success.png"
          width="184px"
          height="187px"
          alt="success"
        />
      </SuccessLogo>

      <StyledMargin>
        <StyledTextBold>
          {strings.congratulations} {data.name}!
        </StyledTextBold>
      </StyledMargin>

      <StyledMargin>
        <StyledText>
          Congratulations! Your signup details have been securely saved in our
          system. To complete the final step and activate your account, kindly
          click the button below to send the activation link to{" "}
          <span style={{ fontWeight: "600" }}> {data.email}</span>. Also your{" "}
          <span style={{ fontWeight: "600" }}> 30-days</span> trial period will
          start after your verification.
        </StyledText>
      </StyledMargin>
      <Box m={2} pt={1}>
        <div className={classes.buttonContainer}>
          <StyledButton style={{color:"white"}} onClick={handleSubmit}>
            {buttonLoading ? (
              <CircularProgress
                style={{ color: "white" }}
                size={20}
                thickness={5}
              />
            ) : (
              strings.sendActivationLink
            )}
          </StyledButton>
        </div>
      </Box>
    </>
  );
};

export default Verification;
