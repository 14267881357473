import { Dialog, DialogActions, DialogContentText, DialogContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, tableCellClasses, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from "react";
import { UploadFileOutlined } from "@mui/icons-material";
import styled from "styled-components";
import { Box } from "@mui/system";
import { useHistory } from "react-router-dom";
import { StyledCrossIcon, StyledSecondaryButton } from "../../../assets/styles/CommonStyles";
import moment from "moment";
import defaultUserPng from '../../../assets/img/user.png';
import gpayPng from "../../../assets/img/gpay.avif";
import paytmPng from "../../../assets/img/paytm.webp";
import upiPng from "../../../assets/img/upi.png";
import HDFCPng from "../../../assets/img/HDFC.png";
import * as mainService from "../../../services/mainService.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { handleFileUploadToS3 } from "../../../utils";
import useRazorpay from 'react-razorpay';
import { CURRENCIES } from '../../../constant/staticLists.js';

const actionCellStyles = {
    '& .MuiIconButton-root': {
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        'fontFamily': 'Poppins',
        'fontStyle': 'normal',
        'fontWeight': 400,
        'fontSize': '14px',
        'lineHeight': '18px',
        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'background-clip': 'text',
        'text-fill-color': 'transparent',
        '& svg': {
            width: '18px',
            height: '18px',
            marginRight: '5px',
            fill: 'url(#header-shape-gradient)'
        }
    }
}


const StyledTableCell = styled(TableCell, { shouldForwardProp: (prop) => prop !== 'actionCell' })(({ theme, actionCell }) => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#2B2B2B',
    [`&.${tableCellClasses.head}`]: {
        boxSizing: 'border-box',
        height: '38px',
        background: '#F8F9FC',
        borderWidth: '1px 0px',
        borderStyle: 'solid',
        borderColor: '#F5F5F7',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '14px',
        lineHeight: '18px',
        color: '#5E5E5E',
        marginTop:'10px'
        // ...actionCell && { width: '150px' }
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledActionCells = styled(StyledTableCell)(() => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    '& svg': {
        width: '18px',
        height: '18px',
        color: '#5E5E5E'
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        //   backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StyledTableContainer = styled(TableContainer)(() => ({
    width: '100%',
}))

const CustomDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: '8px', // Change this to your desired border radius
    },
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: '36px',
}));

const CustomDialogAction = styled(DialogActions)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '25px',
}));

const CustomDialogContentText = styled(DialogContentText)(({ theme }) => ({
    color: 'var(--black, #2B2B2B)',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
}));

const StyledSpan = styled.span`
background: var(--primary, linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 24px;`;

const StyledImgCont = styled.div`
padding-bottom: 25px;
`;

const StyledImg = styled.img`
width: 150px;
height: 150px;`;


function createData(name, type, status) {
    return { name, type, calories: moment(new Date()).format('DD MMM YYYY'), status };
}

const rows = [
    createData('Frozen yoghurt', 'In Person', 'approved'),
];

const nameCellStyles = {
    '& > span': {
        marginLeft: '10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    '& img': {
        boxShadow: '-4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25)',
        borderRadius: '8px',
        width: '83px',
        height: '78px',
        marginRight: '10px',
        marginBottom: '10px',
        '& + span': {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '500',
            color: '#2B2B2B',
            margin: '0px -35px',
            lineHeight: '18px',
            '& .doctor-name': {
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#2B2B2B',
                display: 'flex',
                justifyContent: 'center',
                '& + .specialist': {
                    fontSize: '12px',
                    lineHeight: '16px',
                    display: 'flex',
                    justifyContent: 'center',
                    background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                    backgroundClip: 'text',
                    'textFillColor': 'transparent',
                    '-webkit-background-clip': 'text',
                    '-webkit-text-fill-color': 'transparent',
                }
            }
        }
    },
}

const OnlinePaymentsStyledBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // width: '195px',
    height: '110px',
    '& img': {
        width: '78px',
        height: '49.26px',
        marginBottom: '18px'
    },
    '& span': {
        display: 'flex',
        flexDirection: 'row',
        '& .MuiTypography-root': {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '18px',
        },
        '& .MuiTypography-root:first-child': {
            color: '#5E5E5E'
        },
        '& .MuiTypography-root:last-child': {
            background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
            backgroundClip: 'text',
            'textFillColor': 'transparent',
            '-webkit-background-clip': 'text',
            '-webkit-text-fill-color': 'transparent',
        }
    }
}))

const onlinePayments = [
    { img: gpayPng, firstHeading: 'Gpay No', firstValue: '9910459772', secondHeading: 'Gpay ID', secondValue: 'atswati1999@oksbi' },
    { img: paytmPng, firstHeading: 'Paytm No', firstValue: '9910459772', secondHeading: 'Paytm ID', secondValue: 'atswati1999@oksbi' },
    { img: upiPng, firstHeading: 'UPI No', firstValue: '9910459772', secondHeading: 'UPI ID', secondValue: 'atswati1999@oksbi' },
    { img: HDFCPng, firstHeading: 'Account No', firstValue: '991045001111349772', secondHeading: 'IFSC Code', secondValue: 'HDFC01BANK' }
]

const Checkout = ({ data, date, item, slot, visit }) => {
    let user = localStorage.getItem('userType')
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [curr,setCurr]= useState()
    const { userData } = useSelector(state => ({
        userData: state.auth.userData
    }))
    const [Razorpay] = useRazorpay();
    const key =process.env.REACT_APP_PAYMENT_KEY
    const availableTimeSlot1 = moment(slot?.start_time).format("hh:mm ")
    const availableTimeSlot2 = moment(slot?.end_time).add(1, 'seconds').format("hh:mm A")
    const getCodeByEnum = (enumValue) => {
        
        const currency = CURRENCIES.find((CURRENCIES) => CURRENCIES.enum === enumValue);
       
        return  currency?currency.symbol:null; // Return null if not found
      };
      useEffect(()=>{
        setCurr(getCodeByEnum(slot?.currency))
      },[])
    
    const handleClick = async () => {
        let value = { data: data, token: userData.token }
        let fileData = data.documents;
        setButtonLoading(true)
        console.log("data",fileData)
        const res = await mainService.createAppointmentApi(value);
        if (res.response_type =="fail"){
            setButtonLoading(false)
            toast.error(res?.response?.message,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto", borderRadius:8}});

        }else{
           
           
            toast.success(res?.response?.message,{position:'bottom-center',closeButton:false,autoClose:1000,style:{width:"auto",borderRadius:8}});
            if (user === "patient" && data?.payment_type == 2){
                try {
                  
                   console.log("re",res?.response?.data?.notes?.slotId)
                    const data ={
                       
                        slotId:res?.response?.data?.notes?.slotId
                    }
                    setOpen(true)
                    // const res1 = await mainService.patientCheckoutApi({token:userData.token,
                    //    data
                    // })
                    // console.log("res1",res1)
                    // if (res1.response_type == "fail"){
                    //     setButtonLoading(false)
                    //     toast.error(res1?.response?.message,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto",borderRadius:8}});
                    // }else{
                    //     setButtonLoading(false)
                    //     console.log("success")
                    // }
                }catch(error){
                    console.error("Error occured during checkout",error)
                    throw error;
                }
            }else if(user === "patient" && data?.payment_type == 1){
       
                handlePayment(res?.response?.data)
        console.log("re",res)

            }
            else if (user === "subadmin" && data?.payment_type ==2){
                try {
                  
                    console.log("re",res?.response?.data?.notes?.slotId)
                     const data ={
                        
                         slotId:res?.response?.data?.notes?.slotId
                     }
                     setOpen(true)
                     // const res1 = await mainService.patientCheckoutApi({token:userData.token,
                     //    data
                     // })
                     // console.log("res1",res1)
                     // if (res1.response_type == "fail"){
                     //     setButtonLoading(false)
                     //     toast.error(res1?.response?.message,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto",borderRadius:8}});
                     // }else{
                     //     setButtonLoading(false)
                     //     console.log("success")
                     // }
                 }catch(error){
                     console.error("Error occured during checkout",error)
                     throw error;
                 }
            }else {
                handlePayment(res?.response?.data)
 
            }
        }
   


    }
    const handlePayment = async (params) => {
        // const order = await createOrder(params); //  Create order on your backend
      console.log("params",params)
     
        const options = {
          key, 
          amount: params?.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: params?.currency,
          name: params?.notes?.patientId,
          description: params?.notes,
          notes:params?.notes,
        //   image: ,
          order_id: params?.id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: async function  (response) {
            
           const paymentResponse = await mainService.verifyPaymentPatientApi({token:userData.token,data:response})
           console.log("PaymentResponse",paymentResponse)
          if (paymentResponse.response_type =="fail" ){
            setButtonLoading(false)
            toast.error(paymentResponse?.response?.message,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto",borderRadius:8}});
          }else {
            setOpen(true)
            setButtonLoading(false)
          }
        },
         
       
        //   theme: {
        //     color: "#F37254",
        //   },
        };
      
        const rzp1 = new Razorpay(options);
      
        rzp1.on("payment.failed", function (response) {
            toast.error(`${response.error.reason}${response.error.description}`,{position:"bottom-center",closeButton:false,autoClose:1000,style:{width:"auto",borderRadius:8}});
        });
      
        rzp1.open();
      }

    return (
        <>
            {/* <Grid item xs={12} sx={{ fontSize: '14px', lineHeight: '18px', color: '#2B2B2B' }}>
                Online Payment methods
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={1} sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', borderRadius: '8px', minHeight: '140px' }}>
                    {onlinePayments.map(x => (
                        <OnlinePaymentsStyledBox>
                            <img src={x.img} alt="bank" />
                            <span><Typography>{x.firstHeading}</Typography><span>: </span><Typography>{x.firstValue}</Typography></span>
                            <span><Typography>{x.secondHeading}</Typography><span>: </span><Typography>{x.secondValue}</Typography></span>
                        </OnlinePaymentsStyledBox>
                    ))}
                </Paper>
            </Grid> */}
            <Grid item xs={12} sx={{ fontSize: '14px', lineHeight: '18px', color: '#2B2B2B', marginTop: 2 }}>
                Appointments details
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={1}>
                    <StyledTableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align='center'>Doctor's Details</StyledTableCell>
                                    <StyledTableCell align="center">Appointment Date</StyledTableCell>
                                    <StyledTableCell align="center">Time Slot</StyledTableCell>
                                    <StyledTableCell align="center">Additional Documents</StyledTableCell>
                                    <StyledTableCell align="center">Appointment Fee</StyledTableCell>
                                    <StyledTableCell actionCell align="center">Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {rows.map((row) => (
                                    <StyledTableRow key={row.name} sx={{ position: 'relative', height: '108px' }}>
                                        <StyledTableCell component="tr" scope="row" sx={nameCellStyles}>
                                            <span>
                                                <img src={defaultUserPng} alt="doctor" />
                                                <span>
                                                    <span className="doctor-name">{!!item && item.name}</span>
                                                    <span className="specialist">{!!item && item.department}</span>
                                                </span>
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{moment(date).format('DD-MM-YYYY')}</StyledTableCell>
                                        <StyledTableCell align="center">{`${availableTimeSlot1} - ${availableTimeSlot2}`}</StyledTableCell>
                                        <StyledTableCell align="center" sx={{ '& span': { '& svg': { height: "18px", width: "18px" }, fontWeight: 500 } }}>
                                            <span>
                                                <UploadFileOutlined /> {" + "}
                                                {data?.documents.length || 0}
                                            </span>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{`${curr} ${!!slot && slot.price}`}</StyledTableCell>
                                        <StyledActionCells align="center" sx={actionCellStyles} >
                                            <span style={{ height: '77px', margin: '16px 0px', display: 'flex', alignItems: 'center' }}>
                                                <StyledSecondaryButton disabled={buttonLoading}
                                                    onClick={handleClick} sx={{ textTransform: 'capitalize', width: '200px !important',fontWeight: 500, background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)', '-webkit-text-fill-color': 'initial' }}>
                                                    {buttonLoading ? (
                                                        <CircularProgress
                                                            size={20}
                                                            thickness={5}
                                                        />
                                                    ) : 'Confirm Appointment'
                                                    }
                                                </StyledSecondaryButton>
                                            </span>
                                        </StyledActionCells>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </StyledTableContainer>
                </Paper>
            </Grid>
            <CustomDialog
                open={open}
                //onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={() => setOpen(false)}
          />
                <CustomDialogContent className="padding-item ">
                    <StyledImgCont>
                        <StyledImg src={'/assets/img/success.png'} alt='confirmation-image'></StyledImg>
                    </StyledImgCont>
                    {rows.map((row) => <CustomDialogContentText id="alert-dialog-description">
                        Hey <StyledSpan>{user === 'patient' ? userData.name : ''}</StyledSpan> your <StyledSpan>{visit.payment_type==1 ? 'In Clinic appointment' : 'Online appointment'}</StyledSpan> with <StyledSpan>{!!item && item.name}</StyledSpan> is scheduled on <StyledSpan>{row.calories}</StyledSpan> from <StyledSpan>{`${availableTimeSlot1} - ${availableTimeSlot2}`}</StyledSpan>
                    </CustomDialogContentText>)}
                </CustomDialogContent>
         <CustomDialogAction>
                    <StyledSecondaryButton sx={{ width: '50%' }} className="padding-item" autoFocus onClick={() => history.push(user ==="patient"?'/patient/Dashboard':"/subadmin/home")}>
                        Re-Book Appointment
                    </StyledSecondaryButton>
            </CustomDialogAction>
           </CustomDialog>
        </>)
}


export default React.memo(Checkout);