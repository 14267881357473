import { Button, Grid, Typography } from "@mui/material";

import React from "react";
import strings from "../../../../constant/strings";
import DetailsCard from "./DetailsCard";
import { dashoboardUseStyles } from "../../../../assets/styles/DoctorDashboardStyles";

function AppointmentBookingSummary({ handleForward, handleBackward, selectedListItem }) {
  const classes = dashoboardUseStyles()
  return (
    <>
      <Typography mt={10}></Typography>
      <Button className={`${classes.fontStyle400} ${classes.textColor}`}  onClick={handleBackward}>{strings.backToAppointment}</Button>
      
      <Grid container spacing={2} mt={10}  sx={{ padding: 2, borderBottom: '2px solid white' }}>
        <Grid item xs={12} md={6} lg={6} >
          <DetailsCard navigate="appointment"
          heading = {strings.appointmentSummary}
          selectedListItem={selectedListItem}
           />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <DetailsCard navigate="patientInfo"
          handleForward = {handleForward}
          heading = {strings.patientInformation}
          selectedListItem={selectedListItem}  />
        </Grid>
      </Grid>
    </>
  );
}

export default AppointmentBookingSummary;
