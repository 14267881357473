import { makeStyles } from "@mui/styles";

import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const dashoboardUseStyles = makeStyles(() => ({
  opdTextStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.grey1,
  },
  secondaryCont: {
    backgroundColor: colors.white,
    border: "1.2px solid #F5F5F7",
    boxShadow:
      "-4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25)",
    borderRadius: 8,
    // padding: 16,
  },
  ipdTextStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.grey1,
    marginTop: 16,
  },
  noListStyle: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  addPatientStyle: {
    width: "100%",
    background: colors.primary,
    color: colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 38,
    borderRadius: 8,
    cursor: "pointer",
  },
  mainCont: {
    padding: 8,
    backgroundColor: colors.white,
    border: "1.2px solid #F5F5F7",
    borderRadius: "8px",
    boxShadow:
      "-4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25)",
  },
  headingTextCont: {
    width: "100%",
    padding: "8px 0px 16px 0px",
    borderBottom: `1px solid ${colors.white1}`,
  },
  headingTextStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight700,
    fontSize: "14px",
    lineHeight: "18px",
    color: colors.black1,
  },
  innerTextCont: {
    width: "100%",
    display: "flex",
    marginTop: "16px",
  },
  labelStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    marginRight: "16px",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "14px",
    color: colors.black1,
    lineHeight: "40px",
  },
  valueStyle: {
    color: colors.grey1,
  },
  secondaryCont: {
    width: "100%",
    padding: "16px 0px 16px 0px",
  },
  dateTextStyle: {
    background: colors.primary,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  textColor: {
    background: colors.textColor,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  margin: {
    margin: "16px 0px 16px 0px",
  },
  noDataAvailable: {
    width: "100%",
    textAlign: "center",
    margin: "16px 0px 16px 0px",
  },
  noDataTextStyle: {
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: fontWeights.fontWeight500,
    fontSize: "16px",
    color: colors.grey1,
    lineHeight: "18px",
  },
  fontStyle400: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "18px",
  },
}));

export { dashoboardUseStyles };
