import { Grid } from "@mui/material";
import React from "react";
import AppLayout from "../SideBar";
import Home from "../../components/ui/Organisation/Dashboard/index";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const OrganisationDashboard = () => {
 

  return (
    <Grid container spacing={0}>
      <AppLayout>
        <Home />
      </AppLayout>
    </Grid>
  )
}

export default OrganisationDashboard;