import strings from "./strings";

export const userList = [
  {
    label: `${strings.normalUser}`,
    value: "patient",
    img: "/assets/img/Frame21.png",
    isSelected: false,
  },
  {
    label: `${strings.doctor}`,
    value: "doctor",
    img: "/assets/img/Groupimg.png",
    isSelected: false,
  },
  {
    label: `${strings.clinic}`,
    value: "organization",
    img: "/assets/img/hospitali.png",
    isSelected: false,
  },
  {
    label: `${strings.subAdmin}`,
    value: "subadmin",
    img: "/assets/img/jsicon.png",
    isSelected: false,
  },
];

export const HealthParameterList = [
  {
    index: 1,
    primaryKey: "bloodPressureData",
    dataKey: "dia_blood_pressure&sys_blood_pressure",
    primaryHeading: "Blood Pressure",
    secondaryHeading: "Diastolic&Systolic",
    unit: "mmHg",
  },
  {
    index: 2,
    primaryKey: "bloodSugarData",
    dataKey: "blood_sugar",
    primaryHeading: "Blood Sugar",
    secondaryHeading: "Avg. Weekly Blood Sugar",
    unit: "mg/dL",
  },
  {
    index: 3,
    primaryKey: "spo2sData",
    dataKey: "percentage",
    primaryHeading: "Blood Oxygen",
    secondaryHeading: "Weekly SPO2 Range",
    unit: "%",
  },
  {
    index: 4,
    primaryKey: "bodyTemperatureData",
    dataKey: "temperature&fahrenheit",
    primaryHeading: "Body Temperature",
    secondaryHeading: "Celsius&Fahrenheit",
    graphType: "funnelChart",
    unit: "C",
  },
  {
    index: 6,
    primaryKey: "heartRateData",
    dataKey: "pulse",
    primaryHeading: "Heart Rate",
    secondaryHeading: "Avg. Weekly Heart Rate",
    unit: "BPM",
  },
  {
    index: 5,
    primaryKey: "respiratorieData",
    dataKey: "respiratory_rates",
    primaryHeading: "Respiratory Rate",
    secondaryHeading: "Avg. Weekly Rate",
    unit: "Breaths/min",
  },
];

export const LipidProfileList = [
  {
    index: 1,
    primaryHeading: "Triglycerides",
    key: "triglycerides",
    value: 0,
    min: 0,
    max: 500,
    lowerRange: 150,
    upperRange: 200,
  },
  {
    index: 2,
    primaryHeading: "Cholestrol",
    key: "total_cholesterol",
    value: 0,
    min: 0,
    max: 300,
    lowerRange: 200,
    upperRange: 240,
  },
  {
    index: 3,
    primaryHeading: "HDL-C",
    key: "HDL_C",
    value: 0,
    min: 0,
    max: 70,
  },
  {
    index: 4,
    primaryHeading: "VLDL",
    key: "VLDL",
    value: 0,
    min: 0,
    max: 35,
  },
  {
    index: 5,
    primaryHeading: "LDL_C",
    key: "LDL_C",
    value: 0,
    min: 0,
    max: 200,
    lowerRange: 100,
    upperRange: 130,
  },
];

export let bloodSugarLevel = {
  FASTING: 1,
  "2_HOURS_POST_BREAKFAST": 2,
  PRE_LUNCH: 3,
  "2_HOURS_POST_LUNCH": 4,
  PRE_DINNER: 5,
  "2_HOURS_POST_DINNER": 6,
  BED_TIME: 7,
  "3_AM": 8,
  RANDOM: 9,
};

export let spo2Scale = {
  PULSE_OXIMETER: 1,
  ARTERIAL_BLOOD_GAS: 2,
};

export let temperatureScale = {
  CELSIUS: 1,
  FAHRENHEIT: 3,
};

export let pulseLocation = {
  WRIST: 1,
  NECK: 2,
};

export let genderList = {
  Male: 1,
  Female: 2,
  Other: 3,
};

export let bloodGroupType = {
  "A+": "A+",
  "A-": "A-",
  "B+": "B+",
  "B-": "B-",
  "O+": "O+",
  "O-": "O-",
  "AB+": "AB+",
  "AB-": "AB-",
};

export let bloodGroup = {
  "A+": "1",
  "A-": "2",
  "B+": "3",
  "B-": "4",
  "O+": "5",
  "O-": "6",
  "AB+": "7",
  "AB-": "8",
};

export let occupationList = {
  Anesthesiologist: "Anesthesiologist",
  Cardiologist: "Cardiologist",
  Dentist: "Dentist",
  Dermatologist: "Dermatologist",
  Gastroenterologist: "Gastroenterologist",
  Neurologist: "Neurologist",
};

export const opdList = [
  { index: 0, label: "Patient name" },
  { index: 1, label: "Appointment date" },
  { index: 2, label: "Appointment Slot" },
  { index: 3, label: "Actions" },
];

export const ipdList = [
  { index: 0, label: "Patient name" },
  { index: 1, label: "Patient ID" },
  { index: 2, label: "Room type" },
  { index: 3, label: "Room Number" },
  { index: 4, label: "Actions" },
];

export const opdInputList = [
  {
    name: "John",
    appointmentDate: "12-02-2023",
    appointmentSlot: "5:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Elena",
    appointmentDate: "5-04-2023",
    appointmentSlot: "8:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Tom",
    appointmentDate: "24-05-2023",
    appointmentSlot: "1:00 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Kim",
    appointmentDate: "30-06-2023",
    appointmentSlot: "2:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Shweta",
    appointmentDate: "16-07-2023",
    appointmentSlot: "6:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Vaishali",
    appointmentDate: "24-08-2023",
    appointmentSlot: "3:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Vandana",
    appointmentDate: "19-09-2023",
    appointmentSlot: "9:30 pm",
    appointmentType: "5:30 pm",
  },
  {
    name: "Shweta",
    appointmentDate: "21-09-2023",
    appointmentSlot: "5:00 pm",
    appointmentType: "5:30 pm",
  },
];

export const patientList = [
  {
    id: "22j23jkk232k5k32nj545",
    name: "Product 1",
    image: "https://example.com/images/product1.jpg",
    price: 10.99,
  },
  {
    id: "25nkj243bjb2j23",
    name: "Product 2",
    image: "https://example.com/images/product2.jpg",
    price: 19.99,
  },
  {
    id: "56ihj3kj4i64435jk5",
    name: "Product 3",
    image: "https://example.com/images/product3.jpg",
    price: 15.99,
  },
];

export const appointmentOpdListTab0 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  actions: "Actions",
  accept_decline: "Accept/Decline",
};

export const appointmentOpdListTab1_2 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  button: "Messages",
  status: "Status",
  medication: "Medication",
  actions: "Actions",
};

export const PatientBillingInvoiceList = {
  invoiceNumber: "Invoice number",
  createdAt: "Date",
  amount: "Amount",
  billingStatus: "Status",
  invoiceUrl: "Action",
};

export const patientAppointmentHeadingList = {
  appointed_doctor: "Appointed Doctor",
  patient_appointment_start_time: "Date & Time",
  appointment_type: "Appointment Type",
  payment_type: "Payment Type",
  pending_amount: "Fees",
  patient_status: "Status",
  patient_appoint_action: "Action",
};

export const patientAppointmentHeadingListTab3 = {
  appointed_doctor: "Appointed Doctor",
  patient_appointment_start_time: "Date & Time",
  appointment_type: "Appointment Type",
  payment_type: "Payment Type",
  pending_amount: "Fees",
  patient_status: "Status",
  book_again_appointment_action: "Action",
};

export const patientAppointmentHeadingListTab4 = {
  appointed_doctor: "Appointed Doctor",
  patient_appointment_start_time: "Date & Time",
  appointment_type: "Appointment Type",
  payment_type: "Payment Type",
  pending_amount: "Fees",
  patient_status: "Status",
};

export const appointmentOpdListTab3 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  actions: "Actions",
};

export const appointmentOpdListTab4 = {
  patient_name: "Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  appointment_type: "Appointment Type",
  status: "Status",
};

export const appointmentHeadingList = [
  { index: 0, label: "Appointed Doctor" },
  { index: 1, label: "Date & Time" },
  { index: 2, label: "Appointment type" },
  { index: 3, label: "Payment Type" },
  { index: 4, label: "Fees" },
  { index: 5, label: "Status" },
];

export const tabs = [
 
  { id: 1, name: "Upcoming" },
  { id: 2, name: "Completed" },
  { id: 3, name: "Cancelled" },
];

export const tabsIPD = [
  { id: 0, name: "Admitted" },
  { id: 1, name: "Discharged" },
];

export const medTabs = [
  { id: 0, name: "Medical Reports" },
  { id: 1, name: "Personal Medical Details" },
  { id: 2, name: "E-Prescription" },
];

export const symptoms = [
  { name: "Hypertension" },
  { name: "Fever" },
  { name: "Fatigue" },
  { name: "Headache" },
  { name: "Cough" },
  { name: "Shortness of breath" },
  { name: "Sore throat" },
  { name: "Runny or stuffy nose" },
  { name: "Joint pain" },
  { name: "Nausea and vomiting" },
  { name: "Diarrhea" },
  { name: "Chest pain" },
  { name: "Abdominal pain" },
  { name: "Skin rash or redness" },
  { name: "Swelling in the extremities" },
  { name: "Dizziness or lightheadedness" },
  { name: "Changes in vision" },
  { name: "Difficulty speaking or swallowing" },
  { name: "Memory problems or confusion" },
  { name: "Unintended weight loss or gain" },
];

export const appointmentFor = [
  { label: "Self", value: 0 },
  { label: "Others", value: 1 },
];

export const scheduleTabs = [
  {
    id: 0,
    name: strings.onlineAppointment,
  },
  {
    id: 1,
    name: strings.inclinicAppointment,
  },
];

export let daySchedule = {
  "Show Day schedule": "d",
  "Show Weekly schedule": "w",
};

export const threeTabs = [
  // { id: 0, name: "Request" },
  { id: 1, name: "Today" },
  { id: 2, name: "Upcoming" },
  { id: 3, name: "Completed" },
  { id: 4, name: "Cancelled" },
];

export const appointmentTabList = [
  {
    id: 0,
    name: strings.patientInformation,
  },
  {
    id: 1,
    name: strings.healthParameter,
  },
  {
    id: 2,
    name: strings.medicalHistory,
  },
  {
    id: 3,
    name: strings.reportsPrescription,
  },
  {
    id: 4,
    name: strings.previousAppointment,
  },
];

export const patientInfo = {
  name: "Name",
  email: "Email Address",
  contactNumber: "Contact Number",
  gender: "Gender",
  dob: "Date of birth",
  address: "Address",
  weight: "Weight",
  height: "Height",
};

export const previousAppointmentList = {
  appointment_type: "Appointment type",
  diagnosisAndObservation: "Diagnosis and observation",
  name: "Medication name",
  symptoms: "Symptoms",
  strength: "Strength",
  strengthType: "Strength Type",
  needDoctor: "Why do you need a doctor?",
  dosageQuantity: "Dosage quantity",
  documents: "Documents",
  otherInstructions: "Other instruction",
};

export const ePrescriptionHeadingList = {
  appointed_doctor: "Doctor's Name",
  updatedAt: "Date & Time",
  fileName: "File Name",
  doctor_appointement_action: "Action",
};

export const reportHeadingList = {
  name: "File Name",
  updatedAt: "Date & Time",
  // file_url: "File Name",
  all_type: "All Type",
  doctor_appointement_report_action: "Action",
};

export const daysOfWeek = [
  { level: 0, label: "M", nameOfDay: "Monday" },
  { level: 1, label: "T", nameOfDay: "Tuesday" },
  { level: 2, label: "W", nameOfDay: "Wednesday" },
  { level: 3, label: "T", nameOfDay: "Thursday" },
  { level: 4, label: "F", nameOfDay: "Friday" },
  { level: 5, label: "S", nameOfDay: "Saturday" },
  { level: 6, label: "S", nameOfDay: "Sunday" },
];

export const shiftData = [
  { level: 1, label: "First Shift" },
  { level: 2, label: "Second Shift" },
];

export const slotType = [
  { level: 0, label: "Online" },
  { level: 1, label: "In-Clinic" },
];

export const dosageIntake = [
  { id: 1, level: "once daily", label: "Once daily" },
  { id: 2, level: "twice daily", label: "Twice daily" },
  { id: 3, level: "when needed", label: "When needed" },
  { id: 4, level: "3 times daily", label: "3 times daily" },
  { id: 5, level: "4 times daily", label: "4 times daily" },
  { id: 6, level: "5 times daily", label: "5 times daily" },
  { id: 7, level: "every other day", label: "Every other day" },
  { id: 8, level: "every 2 hours", label: "Every 2 hours" },
  { id: 9, level: "every 3 hours", label: "Every 3 hours" },
  { id: 10, level: "every 4 hours", label: "Every 4 hours" },
  { id: 11, level: "every 6 hours", label: "Every 6 hours" },
  { id: 12, level: "every 8 hours", label: "Every 8 hours" },
  { id: 13, level: "every 12 hours", label: "Every 12 hours" },
  { id: 14, level: "every hour", label: "Every hour" },
  { id: 15, level: "every other hour", label: "Every other hour" },
  { id: 16, level: "once a week", label: "Once a week" },
  { id: 17, level: "twice a week", label: "Twice a week" },
  { id: 18, level: "thrice a week", label: "Thrice a week" },
  { id: 19, level: "4 days in a week", label: "4 days in a week" },
  { id: 20, level: "5 days in a week", label: "5 days in a week" },
  { id: 21, level: "once every 2 weeks", label: "Once every 2 weeks" },
  { id: 22, level: "once a month", label: "Once a month" },
  { id: 23, level: "twice a month", label: "Twice a month" },
  { id: 24, level: "thrice a month", label: "Thrice a month" },
  { id: 25, level: "every other month", label: "Every other month" },
];

export const getBackgroundColor = (status) => {
  if ([1, 4].includes(status)) return "#2AAB6C";
  if ([2, 3, 5].includes(status)) return "#F36363";
  return "#EAAD11";
};

export const validExtensions = ["jpg", "jpeg", "pdf", "png"];
export const getStatusText = (status) => {
  const statusMap = {
    0: "Pending",
    1: "Approved",
    2: "Reject",
    3: "Cancelled by patient",
    4: "Completed",
    5: "Cancelled by doctor",
  };
  return statusMap[status] || "Rejeced by doctor";
};

export const settingTabs = [
  { id: 0, name: "Personal Details" },
  { id: 1, name: "Reset Password" },
];

export const orgOPDAppointmentList = {
  appointed_doctor: "Doctor Name",
  patient_name: "Patient Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  createdAt: "Booking Date",
  pending_amount: "Amount",
  appointment_type: "Appointment Type",
  orgActions: "Actions",
};

export const subadminOPDAppointmentList = {
  appointed_doctor: "Doctor Name",
  patient_name: "Patient Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
  pending_amount: "Amount",
  appointment_type: "Appointment Type",
  actions: "Actions",
};

export const subadminOPDdashboard = {
  appointed_doctor: "Doctor Name",
  patient_name: "Patient Name",
  appointment_start_time: "Appointment Date",
  doctor_appointment_slot: "Appointment Slot",
};

export const orgIPDAppointmentList = {
  patient_name: "Patient Name",
  patient_id: "Patient ID",
  room_type: "Room Type",
  appointment_start_time: "Admitted Date",
  room_number: "Room Number",
  orgActions: "Actions"
};

export const subAdminIPDAppointmentListHome = {
  patient_name: "Patient Name",
  patient_id: "Patient ID",
  room_type: "Room Type",
  appointment_start_time: "Admitted Date",
  room_number: "Room Number",
};

export const BookingSummaryList = [
  { id: 0, name: "Appointment Date" },
  { id: 1, name: "Appointment Time" },
  { id: 2, name: "Consultation fee" },
  { id: 3, name: "Booking Status" },
];

export const patientInfoList = [
  { id: 0, name: "Patient Contact" },
  { id: 1, name: "Biological gender" },
];

export const permission = [
  { level: 1, label: "IPD" },
  { level: 2, label: "OPD" },
  { level: 3, label: "Both(IPD and OPD)" },
];

export const permissionSubadmin = [
  { level: 1, label: "IPD" },
  { level: 2, label: "OPD" },
];

export const status = [
  { level: 0, label: "Inactive" },
  { level: 1, label: "Active" },
];
export const CURRENCIES = [
  {
    code: "USD",
    name: "United States Dollar",
    country: "United States",
    enum: 1,
    symbol: "$"
  },
  {
    code: "EUR",
    name: "Euro",
    country: "Eurozone countries",
    enum: 2,
    symbol: "€"
  },
  {
    code: "GBP",
    name: "British Pound Sterling",
    country: "United Kingdom",
    enum: 3,
    symbol: "£"
  },
  {
    code: "JPY",
    name: "Japanese Yen",
    country: "Japan",
    enum: 4,
    symbol: "¥"
  },
  {
    code: "CAD",
    name: "Canadian Dollar",
    country: "Canada",
    enum: 5,
    symbol: "C$"
  },
  {
    code: "AUD",
    name: "Australian Dollar",
    country: "Australia",
    enum: 6,
    symbol: "A$"
  },
  {
    code: "CHF",
    name: "Swiss Franc",
    country: "Switzerland",
    enum: 7,
    symbol: "CHF"
  },
  {
    code: "CNY",
    name: "Chinese Yuan Renminbi",
    country: "China",
    enum: 8,
    symbol: "¥"
  },
  {
    code: "INR",
    name: "Indian Rupee",
    country: "India",
    enum: 9,
    symbol: "₹"
  },
  {
    code: "RUB",
    name: "Russian Ruble",
    country: "Russia",
    enum: 10,
    symbol: "₽"
  },
  {
    code: "BRL",
    name: "Brazilian Real",
    country: "Brazil",
    enum: 11,
    symbol: "R$"
  },
  {
    code: "ZAR",
    name: "South African Rand",
    country: "South Africa",
    enum: 12,
    symbol: "R"
  },
  {
    code: "MXN",
    name: "Mexican Peso",
    country: "Mexico",
    enum: 13,
    symbol: "$"
  },
  {
    code: "SGD",
    name: "Singapore Dollar",
    country: "Singapore",
    enum: 14,
    symbol: "S$"
  },
  {
    code: "NZD",
    name: "New Zealand Dollar",
    country: "New Zealand",
    enum: 15,
    symbol: "NZ$"
  },
  {
    code: "KRW",
    name: "South Korean Won",
    country: "South Korea",
    enum: 16,
    symbol: "₩"
  },
  {
    code: "TRY",
    name: "Turkish Lira",
    country: "Turkey",
    enum: 17,
    symbol: "₺"
  },
  {
    code: "SAR",
    name: "Saudi Riyal",
    country: "Saudi Arabia",
    enum: 18,
    symbol: "ر.س"
  },
  {
    code: "AED",
    name: "United Arab Emirates Dirham",
    country: "United Arab Emirates",
    enum: 19,
    symbol: "د.إ"
  },
  {
    code: "HKD",
    name: "Hong Kong Dollar",
    country: "Hong Kong",
    enum: 20,
    symbol: "HK$"
  },
  {
    code: "SEK",
    name: "Swedish Krona",
    country: "Sweden",
    enum: 21,
    symbol: "kr"
  },
  {
    code: "NOK",
    name: "Norwegian Krone",
    country: "Norway",
    enum: 22,
    symbol: "kr"
  },
  {
    code: "DKK",
    name: "Danish Krone",
    country: "Denmark",
    enum: 23,
    symbol: "kr"
  },
  {
    code: "ILS",
    name: "Israeli Shekel",
    country: "Israel",
    enum: 24,
    symbol: "₪"
  },
  {
    code: "PLN",
    name: "Polish Złoty",
    country: "Poland",
    enum: 25,
    symbol: "zł"
  },
  {
    code: "THB",
    name: "Thai Baht",
    country: "Thailand",
    enum: 26,
    symbol: "฿"
  },
  {
    code: "MYR",
    name: "Malaysian Ringgit",
    country: "Malaysia",
    enum: 27,
    symbol: "RM"
  },
  {
    code: "IDR",
    name: "Indonesian Rupiah",
    country: "Indonesia",
    enum: 28,
    symbol: "Rp"
  },
  {
    code: "PHP",
    name: "Philippine Peso",
    country: "Philippines",
    enum: 29,
    symbol: "₱"
  },
  {
    code: "EGP",
    name: "Egyptian Pound",
    country: "Egypt",
    enum: 30,
    symbol: "£"
  },
  {
    code: "VND",
    name: "Vietnamese Đồng",
    country: "Vietnam",
    enum: 31,
    symbol: "₫"
  },
  {
    code: "CLP",
    name: "Chilean Peso",
    country: "Chile",
    enum: 32,
    symbol: "$"
  },
  {
    code: "COP",
    name: "Colombian Peso",
    country: "Colombia",
    enum: 33,
    symbol: "$"
  },
  {
    code: "PEN",
    name: "Peruvian Sol",
    country: "Peru",
    enum: 34,
    symbol: "S/"
  },
  {
    code: "ARS",
    name: "Argentine Peso",
    country: "Argentina",
    enum: 35,
    symbol: "$"
  },
  {
    code: "TWD",
    name: "New Taiwan Dollar",
    country: "Taiwan",
    enum: 36,
    symbol: "NT$"
  },
  {
    code: "BDT",
    name: "Bangladeshi Taka",
    country: "Bangladesh",
    enum: 37,
    symbol: "৳"
  },
  {
    code: "PKR",
    name: "Pakistan Rupee",
    country: "Pakistan",
    enum: 38,
    symbol: "₨"
  },
  {
    code: "NGN",
    name: "Nigerian Naira",
    country: "Nigeria",
    enum: 39,
    symbol: "₦"
  },
  {
    code: "KES",
    name: "Kenyan Shilling",
    country: "Kenya",
    enum: 40,
    symbol: "KSh"
  },
  {
    code: "ETB",
    name: "Ethiopian Birr",
    country: "Ethiopia",
    enum: 41,
    symbol: "Br"
  },
  {
    code: "GHS",
    name: "Ghanaian Cedi",
    country: "Ghana",
    enum: 42,
    symbol: "₵"
  },
  {
    code: "UAH",
    name: "Ukrainian Hryvnia",
    country: "Ukraine",
    enum: 43,
    symbol: "₴"
  },
  {
    code: "KZT",
    name: "Kazakhstani Tenge",
    country: "Kazakhstan",
    enum: 44,
    symbol: "₸"
  },
  {
    code: "RON",
    name: "Romanian Leu",
    country: "Romania",
    enum: 45,
    symbol: "lei"
  },
  {
    code: "CZK",
    name: "Czech Koruna",
    country: "Czech Republic",
    enum: 46,
    symbol: "Kč"
  },
  {
    code: "HUF",
    name: "Hungarian Forint",
    country: "Hungary",
    enum: 47,
    symbol: "Ft"
  },
  {
    code: "SZL",
    name: "Swazi Lilangeni",
    country: "Eswatini (formerly Swaziland)",
    enum: 48,
    symbol: "E"
  },
  {
    code: "MAD",
    name: "Moroccan Dirham",
    country: "Morocco",
    enum: 49,
    symbol: "د.م."
  },
  {
    code: "TND",
    name: "Tunisian Dinar",
    country: "Tunisia",
    enum: 50,
    symbol: "د.ت"
  },
  {
    code: "DZD",
    name: "Algerian Dinar",
    country: "Algeria",
    enum: 51,
    symbol: "دج"
  },
  {
    code: "QAR",
    name: "Qatari Riyal",
    country: "Qatar",
    enum: 52,
    symbol: "ر.ق"
  },
  {
    code: "OMR",
    name: "Omani Rial",
    country: "Oman",
    enum: 53,
    symbol: "ر.ع."
  },
  {
    code: "BHD",
    name: "Bahraini Dinar",
    country: "Bahrain",
    enum: 54,
    symbol: ".د.ب"
  },
  {
    code: "JOD",
    name: "Jordanian Dinar",
    country: "Jordan",
    enum: 55,
    symbol: "د.ا"
  },
  {
    code: "LBP",
    name: "Lebanese Pound",
    country: "Lebanon",
    enum: 56,
    symbol: "ل.ل"
  },
  {
    code: "IQD",
    name: "Iraqi Dinar",
    country: "Iraq",
    enum: 57,
    symbol: "ع.د"
  },
  {
    code: "IRR",
    name: "Iranian Rial",
    country: "Iran",
    enum: 58,
    symbol: "﷼"
  },
  {
    code: "CRC",
    name: "Costa Rican Colón",
    country: "Costa Rica",
    enum: 59,
    symbol: "₡"
  },
  {
    code: "GTQ",
    name: "Guatemalan Quetzal",
    country: "Guatemala",
    enum: 60,
    symbol: "Q"
  },
  {
    code: "PAB",
    name: "Panamanian Balboa",
    country: "Panama",
    enum: 61,
    symbol: "B/."
  },
  {
    code: "HNL",
    name: "Honduran Lempira",
    country: "Honduras",
    enum: 62,
    symbol: "L"
  },
  {
    code: "NIO",
    name: "Nicaraguan Córdoba",
    country: "Nicaragua",
    enum: 63,
    symbol: "C$"
  },
  {
    code: "SVC",
    name: "Salvadoran Colón",
    country: "El Salvador",
    enum: 64,
    symbol: "₡"
  },
  {
    code: "DOP",
    name: "Dominican Peso",
    country: "Dominican Republic",
    enum: 65,
    symbol: "RD$"
  },
  {
    code: "CUP",
    name: "Cuban Peso",
    country: "Cuba",
    enum: 66,
    symbol: "₱"
  },
  {
    code: "UYU",
    name: "Uruguayan Peso",
    country: "Uruguay",
    enum: 67,
    symbol: "$U"
  },
  {
    code: "PYG",
    name: "Paraguayan Guarani",
    country: "Paraguay",
    enum: 68,
    symbol: "₲"
  }
];



export const docDegree = [
  { level: 0, label: "Bachelor of Medicine, Bachelor of Surgery (MBBS)" },
  { level: 1, label: "Doctor of Medicine (MD)" },
  { level: 2, label: "Master of Surgery (MS)" },
  { level: 3, label: "Doctor of Philosophy (Ph.D.) in Medicine" },
  { level: 4, label: "Doctor of Medicine (DM)" },
  { level: 5, label: "Master of Dental Surgery (MDS)" },
  { level: 6, label: "Bachelor of Dental Surgery (BDS)" },
  { level: 7, label: "Bachelor of Ayurvedic Medicine and Surgery (BAMS)" },
  { level: 8, label: "Bachelor of Homeopathic Medicine and Surgery (BHMS" },
  { level: 9, label: "Bachelor of Unani Medicine and Surgery (BUMS)" },
];

export const language = [
  { level: 0, label: "English" },
  { level: 1, label: "Hindi" },
  { level: 2, label: "Marathi" },
  { level: 3, label: "Telugu" },
  { level: 4, label: "Tamil" },
  { level: 5, label: "French" },
  { level: 6, label: "Spanish" },
  { level: 7, label: "German" },
  { level: 8, label: "Malayalam" },
];

export const departmentList = {
  AllergyAndImmunology: "Allergy and immunology",
  Anesthesiology: "Anesthesiology",
  Dermatology: "Dermatology",
  DiagnosticRadiology: "Diagnostic radiology",
  EmergencyMedicine: "Emergency medicine",
  FamilyMedicine: "Family medicine",
  InternalMedicine: "Internal medicine",
  MedicalGenetics: "Medical genetics",
  Neurology: "Neurology",
  NuclearMedicine: "Nuclear medicine",
  ObstetricsAndGynaecology: "Obstetrics and gynaecology",
  Ophthalmology: "Ophthalmology",
  Pathology: "Pathology",
  Pediatrics: "Pediatrics",
  PhysicalMedicineAndRehabilitation: "Physical medicine and rehabilitation",
  PreventiveMedicine: "Preventive medicine",
  Psychiatry: "Psychiatry",
  RadiationOncology: "Radiation oncology",
  Surgery: "Surgery",
  Urology: "Urology",
};

export const designation = { Accountant: 1 };

export const PatientListFields = {
  name: "Patient Name",
  patient_id: "Patient ID",
  dob: "Date of Birth",
  patient_contact: "Patient Contact",
  gender: "Gender",
  emergency_name: "Emergency Name",
  emergency_contact: "Emergency Contact",
  orgActions: "Actions",
};

export const DoctorListFields = {
  doctor_name: "Doctor Name",
  doctor_id: "Doctor ID",
  permission: "Permission",
  department: "Specialisation",
  contact: "Contact number",
  email: "Email address",
  employeeStatus: "Status",
  orgActions: "Actions",
};

export const SubadminListFields = {
  name: "Name",
  doctor_id: "Admin ID",
  gender: "Gender",
  designation: "Designation",
  contact: "Contact number",
  email: "Email address",
  address: "Address",
  employeeStatus: "Status",
  orgActions: "Actions",
};

export const roomTypeList = [
  { level: 0, label: "General ward" },
  { level: 1, label: "ICU" },
];

export const roomAvailability = [
  { level: 0, label: "Available" },
  { level: 1, label: "Occupied" },
  { level: 2, label: "Not in Service" },
];

export const basicPatientDetail = {
  id: "ID:",
  age: "Age:",
  phone_number: "Contact:",
};

export const detailedPatientDetails = {
  name: "Name:",
  email: "Email:",
  phone_number: "Contact:",
  gender: "Gender",
  dob: "Date of Birth:",
  address: "Address",
  emergency_contact:"Emergency Contact"
};
export const detailedDoctorDetails = {
  name: "Name:",
  email: "Email:",
  phone_number: "Contact:",
  gender: "Gender",
  dob: "Date of Birth:",
  address: "Address",
  department:"Department",
  experience:"Experience",
  language:"Language"
};
export const detailedSubadminDetails = {
  name: "Name:",
  email: "Email:",
  phone_number: "Contact:",
  gender: "Gender",
  dob: "Date of Birth:",
  address: "Address",
  // department:"Department",
  designation:"Designation",
  permission:"Permission"
};

export const patientInformation = {
  name: "Name:",
  admission_date: "Admission Date:",
  room_no: "Room No:",
  room_type: "Room Type:",
  admission_consult: "Admission under Consultation:",
  reason: "Reason for admission:",
  ref_phone_number: "Reference Contact Number:",
  mlc: "MLC No.",
  fir: "FIR No.",
};

export const patientMedicalDetails = {
  provisional: " Provisional Diagnosis",
  bp_dia: "BP Diastolic",
  bp_sys: "BP Systolic",
  temp: "Temp (F)",
  spo2: "SPO2 (%)",
  pulse: "Pulse (per min)",
  rr: "RR (per min)",
  illness_summary: "Presentig illness summary",
};

export const prevIPD = {
  name: "Doctor",
  duration: "Previous IPD Duration",
};

export const prevIPDAppointment = {
  name: "Doctor",
  date: "Date",
  type: "Type",
};

export const serviceList = {
  service: "Service",
  date_time: "Date & Time",
  price: "Price",
};

export const IPDReportTabs = [
  { id: 0, name: "Medication" },
  { id: 1, name: "Health Parameters" },
  { id: 2, name: "Medical history" },
  { id: 3, name: "Reports & E-Prescription" },
];

export const IPDMedication = {
  name: "Medication name",
  date: "Date of medicine",
  form: "Form of medicine",
  strength: "Strength",
  strengthType: "Strength type",
  duration: "Time interval",
  ipdAction: "Action",
};
