import React, { useEffect, useState } from "react";
import { Grid, Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TabComponent from "../../../Tab";
import { orgOPDAppointmentList, tabs } from "../../../../constant/staticLists";
import SearchFields from "../../../../pages/PatientPanel/PatientAppointments/SearchFields";
import strings from "../../../../constant/strings";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import Tables from "../../../controls/Tables";
import HeadingComponent from "../../HeadingComponent";
import { getOrganizationAppointmentListAction } from "../../../../redux/appointment/organization/organizationAppointmentActions";
import { LoadingContainer, NoDetailsContainer } from "../../../../assets/styles/CommonStyles";

function OpdAppointmentList({ step, setAppointmentListType, handleForward, setSelectedListItem, selectedListItem }) {
  const classes = useStyles();
  const styleClasses = commonMuiStyles();
  const [tabValue, setTabValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  const dispatch = useDispatch();
  const { userData, organizationAppointmentList, loading } = useSelector((state) => ({
    userData: state.auth.userData,
    organizationAppointmentList: state.organizationAppointment.organizationAppointmentList,
    loading: state.organizationAppointment.loading,
  }));

  useEffect(() => {
    const getOrganizationAppointments = (status) => {
      dispatch(
        getOrganizationAppointmentListAction({
          token: userData.token,
          status,
        })
      );
    };

    switch (tabValue) {
      case 0:
        getOrganizationAppointments(1);
        break;
      case 1:
        getOrganizationAppointments(4);
        break;
      case 2:
        getOrganizationAppointments(2);
        break;
      case 3:
        getOrganizationAppointments(2);
        break;
      default:
        break;
    }
  }, [tabValue, dispatch, userData.token]);

  const handleTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleValue = (e) => {
    setSearchTerm(e.target.value);
    // Additional logic to filter the appointment list can be implemented here
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Calculate the start and end index for pagination
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = organizationAppointmentList.slice(startIndex, endIndex);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <HeadingComponent
          navigate="organisation"
          text={{ opd: strings.opd }}
        />
      </Grid>
      <Grid item xs={12} md={8} lg={8}>
        <TabComponent
          tabs={tabs}
          tabValue={tabValue}
          setTabValue={setTabValue}
          handleTab={handleTab}
          customTab={styleClasses.customTab}
          customTabIndicator={styleClasses.customTabIndicator}
        />
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {step === 1 && (
          <SearchFields
            pr={"16px"}
            handleValue={handleValue}
            placeholder={strings.searchByPatNameDocName}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <LoadingContainer>{strings.loading}</LoadingContainer>
        ) : paginatedData && paginatedData.length > 0 ? (
          <>
            <Tables
              data={paginatedData}
              fieldsOrder={orgOPDAppointmentList}
              setSelectedListItem={setSelectedListItem}
              selectedListItem={selectedListItem}
              handleForward={handleForward}
            />
            <Grid container justifyContent="center" mt={2}>
              <Pagination
                count={Math.ceil(organizationAppointmentList.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Grid>
          </>
        ) : (
          <NoDetailsContainer>No appointments available</NoDetailsContainer>
        )}
      </Grid>
    </Grid>
  );
}

export default OpdAppointmentList;
