import { capitalizeFirstLetter } from "../constant/staticHelper";
import { designation, genderList } from "../constant/staticLists";

export const handleDownloadReport = (url) => {
    const a = document.createElement('a');
    a.setAttribute('download', 'reactflow.png');
    a.setAttribute('href', url);
    a.click();
  }

  export const genderLists = Object.keys(genderList).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: genderList[key],
  }));

  export const designationLists = Object.keys(designation).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: designation[key],
  }));

  // export const designationLists = Object.keys(designation).map((key) => ({
  //   label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
  //   level: designation[key],
  // }));

  export const renderGender = (item) => {
    switch(item){
      case 1 : return "Male";
      case 2 : return "Female";
      case 3 : return "Other";
    }
  };

  export const renderPermission = (item) => {
    switch(item){
      case 1 : return 'IPD'
      case 2 : return 'OPD'
      case 3 : return 'IPD/OPD'
    }
  }

  // export const designationLists = Object.keys(designation).map((key) => ({
  //   label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
  //   level: designation[key],
  // }));