import React, { useLayoutEffect, useState } from "react";
import { Box, Grid, IconButton, Typography, Button, Pagination, PaginationItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ChevronLeftOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import HeadingComponent from "../HeadingComponent";
import SearchFields from "../../../pages/PatientPanel/PatientAppointments/SearchFields";
import Tables from "../../controls/Tables";
import { LoadingContainer, NoDetailsContainer, StyledProceedButton } from "../../../assets/styles/CommonStyles";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import strings from "../../../constant/strings";
import colors from "../../../constant/colors";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const EmployeeListUI = ({
  primaryText,
  secondaryText,
  addButton,
  searchPlaceholderText,
  total,
  setOpenModal,
  openModal,
  data,
  fieldsOrder,
  setScreen,
  handleForward,
  setSelectedListItem,
}) => {
  const classes = commonMuiStyles();
  const [list, setList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const itemsPerPage = 10; // Show 10 items per page

  const { loader } = useSelector((state) => ({
    loader: state.organizationList.loading,
  }));

  useLayoutEffect(() => {
    setList(data);
  }, [data]);

  // Handle search results
  const handleValue = (value) => {
    const searchResults = data?.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setList(searchResults);
    if (value === "") {
      setList(data);
    }
    setCurrentPage(1); // Reset to page 1 after search
  };

  // Calculate paginated data based on the current page
  const paginatedData = list?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Total number of pages
  const totalPages = Math.ceil(list.length / itemsPerPage);

  // Handle Page Click
  const handlePageClick = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9} md={9} lg={9}>
          <HeadingComponent
            text={{ primary: primaryText, secondary: secondaryText }}
          />
        </Grid>
        <Grid item xs={3} mt={2} className={classes.rightFlex}>
          <StyledProceedButton
            onClick={() => {
              setOpenModal(!openModal);
            }}
          >
            <AddIcon />
            {addButton}
          </StyledProceedButton>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <IconButton
              onClick={() => setScreen(0)}
              className={`${classes.textGradientColor} ${classes.fontText14}`}
            >
              <ChevronLeftOutlined sx={{ color: colors.blue }} />
              Back to Home
            </IconButton>
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        mt={4}
        alignItems="center"
        className={classes.borderStyle}
      >
        <Grid
          item
          xs={8}
          className={classes.textGradientColor}
          display="flex"
          justifyContent="center"
          sx={{ paddingTop: "10px" }}
        >
          {total} : {!!data && data.length}
        </Grid>
        <Grid item xs={4}>
          <SearchFields
            placeholder={searchPlaceholderText}
            handleValue={handleValue}
          />
        </Grid>
        <Grid
          item
          xs={12}
          mt={2}
          style={{ minHeight: "100%", overflowX: "auto" }}
        >
          {loader ? (
            <LoadingContainer>{strings.loading}</LoadingContainer>
          ) : !!paginatedData && paginatedData.length > 0 ? (
            <Tables
              data={paginatedData}
              fieldsOrder={fieldsOrder}
              setSelectedListItem={setSelectedListItem}
              handleForward={handleForward}
            />
          ) : (
            <NoDetailsContainer>{strings.noList}</NoDetailsContainer>
          )}
        </Grid>
      </Grid>

      {/* Pagination UI */}
      <Grid container justifyContent="center" mt={3} alignItems="center">
        <Typography variant="body2" sx={{ marginRight: 2 }}>
          Pages {currentPage} of {totalPages}
        </Typography>

        {/* Pagination Component */}
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageClick}
          renderItem={(item) => (
            <PaginationItem
              components={{
                previous: ArrowBackIosIcon,
                next: ArrowForwardIosIcon,
              }}
              {...item}
              sx={{
                "&.Mui-selected": {
                  background: colors.linearGradiant,
                  color: "white",
                },
                "&.MuiPaginationItem-root": {
                  borderRadius: "4px",
                },
              }}
            />
          )}
        />
      </Grid>
    </>
  );
};

export default EmployeeListUI;
