import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography } from '@mui/material';

import { StyledSecondaryButton } from "../../../assets/styles/CommonStyles";
import { LocationOnOutlined, MedicationOutlined, TranslateOutlined, VideocamOutlined } from "@mui/icons-material";
import defaultUserPng from '../../../assets/img/user.png'
import styled from "styled-components";
import { Box } from "@mui/system";
import colors from "../../../constant/colors";
import { CURRENCIES } from "../../../constant/staticLists";

const DoctorsListGridStyled = styled(Grid, { shouldForwardProp: (prop) => prop !== 'step' })(({ step }) => ({
    '& .paper-style': {
        boxShadow: `-4px -4px 15px ${colors.shadowGradient}, 4px 4px 15px ${colors.shadowGradient}`,
    },
    '& img': {
        height: '78px',
        width: '83px',
        boxShadow: '-4px -4px 15px rgba(192, 192, 192, 0.25), 4px 4px 15px rgba(192, 192, 192, 0.25)',
        borderRadius: '8px'
    },
    '& .experience': {
        marginLeft: '16px',
        marginTop: '10px',
        padding: '0px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        textAlign: 'left',
        height: "20px",
        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
    },
    '& .consultationText': {
        color: '#5E5E5E',
        fontFamily: 'Poppins',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        textAlign: 'left',
        marginLeft: '8px',
    },
    '& .fees': {
        fontFamily: 'Poppins',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '700',
        lineHeight: '20px',
        marginLeft: '8px',
        background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',   
    },
    '& .image': {
        width: 100,
        height: 100,
        marginLeft: '16px',
        marginTop: '16px',
       
    },
    '& .doctor-name': {       
        padding: '0px',   
        paddingLeft: '8px',   
        marginTop: '16px',
        marginLeft: '27px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#5E5E5E',
        display: 'flex',
        flexDirection: 'column',
        // ...step === 1 && { marginTop: '-62px' },
        justifyContent: 'center',
        '& .MuiTypography-root': {
            height: '20px'
        },
        '& .MuiTypography-root:first-child': {
            color: '#2B2B2B',
            fontSize: '16px',
            lineHeight: '20px',
            fontWeight: 700,
            height: '24px',
            '& + .MuiTypography-root': {
                fontSize: '12px',
                lineHeight: '16px',
                background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                'background-clip': 'text',
                'text-fill-color': 'transparent',
                '& + .MuiTypography-root': {
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#5E5E5E',
                    '& + .MuiTypography-root': {
                        // marginTop: '13px',
                        fontSize: '12px',
                        lineHeight: '20px',
                        color: '#5E5E5E',
                        '& + .MuiTypography-root': {
                            background: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
                            '-webkit-background-clip': 'text',
                            '-webkit-text-fill-color': 'transparent',
                            backgroundClip: 'text',
                            fontWeight: 700,
                            fontSize: '16px',
                            lineHeight: '20px',
                        }
                    }
                }
            }
        },
    },
    '& .doctor-details': {  
        marginLeft: '16px',
        marginTop: '16px', 
        '& .MuiBox-root': {
            display: 'flex',
            
        },
        '& svg': {
            height: '16px',
            width: '16px',
            marginRight: '8px'
        },
        '& .MuiTypography-root': {
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '16px',
            color: '#5E5E5E'
        }
    },
    '& .doctor-visit-button': {
        display: 'flex',
        padding: 0,
        '& .MuiButtonBase-root': {
            borderRadius: '0px',
            width: '190px',
            textTransform: 'capitalize',
            fontWeight: 500,
            // 'font-family': 'Inter',
            'font-style': 'normal',
            'font-weight': '700',
            'font-size': '14px',
            'line-height': '18px',
            color: '#FFFFFF',
            '& svg': {
                width: '24px',
                height: '24px',
                marginRight: '10px'
            },
            ':first-child': {
                borderRadius: '0px 0px 0px 8px',
                background: '#097EB7'
            },
            ':last-child': {
                borderRadius: '0px 0px 8px',
                background: '#2AAB6C'
            }
        }
    }
}))

const DoctorsCard = ({ index, step, handleStepChange = () => true, item , slot}) => {
    console.log("slot",slot)
    const [curr,setCurr]= useState()
    const getCodeByEnum = (enumValue) => {
        
        const currency = CURRENCIES.find((CURRENCIES) => CURRENCIES.enum === enumValue);
       
        return  currency?currency.symbol:null; // Return null if not found
      };
      useEffect(()=>{
        setCurr(getCodeByEnum(slot?.currency))
     },[slot,item,curr])
    const renderValues = (list, value) => {
       
        let tempArray = [];
        if (list.length > 0) {
            list.map((item, index) => {
                tempArray.push(item);
            });
            return tempArray.join("/ ");
        } else {
            return `No ${value} available.`;
        }
    };

    return (
        <DoctorsListGridStyled item xs={12} md={6} lg={4} xl={4} key={index} p={0} step={step}>
            {step === 2 && <Typography className="heading-font">Doctor’s Details</Typography>}
            <Paper elevation={1} className="paper-style" sx={{ borderRadius: '8px', width: '350px', height: 250, marginBottom: 2 }} >
                <Grid container  m={0} mt={2}>
                    <Grid item xs={3} justifyContent={'center'} flexDirection={'column'} alignItems={'center'} sx={{ margin: 0, paddingLeft: '0px' }}>
                        <img src={defaultUserPng} alt="doctor" className="image"/>
                    </Grid>
                    <Grid item xs={8} className="doctor-name">
                        <Typography>{!!item && "Dr. "+item.name}</Typography>
                        <Typography>{!!item && item.department}</Typography>
                        <Typography>{!!item && renderValues(item.degrees, "degree")}</Typography>                       
                    </Grid>
                    <Grid item xs={4} >
                        <Typography className="experience" sx={{ paddingLeft: 0 }}>
                        {(!!item && !!item.experience) ? item.experience : "Not mentioned"} YRS. EXP
                        </Typography>
                    </Grid>
                    {step === 2 && <>
                        <Grid item xs={8}>
                            <Typography className="consultationText">Consultation Fee</Typography>
                            <Typography className="fees">{`${curr?curr:"$"} ${!!slot && slot.price ? slot.price : `${100}`}`} </Typography>
                        </Grid>
                    </>}

                    <Grid item xs={12} className="doctor-details"  pb='16px'>
                        <Box height={'20px'}>
                            <TranslateOutlined />
                            <Typography>{!!item && renderValues(item.languages, "language")}</Typography>
                        </Box>
                        <Box height={'20px'}>
                            <LocationOnOutlined />
                            <Typography>{!!item && item.address}</Typography>
                        </Box>
                    </Grid>
                    {step === 1 &&
                    <Grid item xs={12} pt={0}  className="doctor-visit-button">
                        <StyledSecondaryButton type="button" onClick={()=>handleStepChange(2,item,0)}>
                            <VideocamOutlined />
                            Consult Online
                        </StyledSecondaryButton>
                        <StyledSecondaryButton type="button" onClick={()=>handleStepChange(2,item,1)}>
                            <MedicationOutlined />
                            Visit Clinic/Hospital
                        </StyledSecondaryButton>
                    </Grid>}
                </Grid>
            </Paper>
        </DoctorsListGridStyled>)
}


export default React.memo(DoctorsCard);