const v1 = "api/v1/";
const apiUrl = process.env.REACT_APP_API_BASE_URL;
// const apiUrl = "https://dev.emed4u.com/";

const currentDate = new Date();
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0');
const day = String(currentDate.getDate()).padStart(2, '0');
const formattedDate = `${year}-${month}-${day}`;
export const getUriWithDurationAndDateFormat = (uri, duration = 'w', date) => {
  if (duration) uri += `&duration=${duration.toLowerCase()}`
  return `${uri}&date=${date || formattedDate}`
}

let user = "patient";
const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

function getApiUrl() {
  user = sessionStorage.getItem("userType");
  return `${apiUrl}${v1}${user}`;
}

export const LOGIN = `${apiUrl}${v1}${user}/login`;
export const LOGIN1 = `${apiUrl}${v1}${user}/login`;
export const LOGOUT = `${apiUrl}${v1}${user}/logout`;
export const DOCTOR_LOGIN = `${apiUrl}${v1}doctor/sign-in`;
export const ORGANIZATION_LOGIN=`${apiUrl}${v1}organization/sign-in`;
export const SIGNUP = `${apiUrl}${v1}${user}/signup`;
export const FORGOT_PASSWORD = `${apiUrl}${v1}${user}/forgotpassword`;
export const GET_BLOOD_SUGAR = `${apiUrl}${v1}${user}/get-blood-sugar?timezone=${timezone}`;
export const GET_HEALTH_PARAMS = `${apiUrl}${v1}${user}/get-health-params?timezone=${timezone}`;
export const GET_BODY_TEMPERATURE = `${apiUrl}${v1}${user}/get-body-temperature?timezone=${timezone}`;
export const GET_BLOOD_PRESSURE = `${apiUrl}${v1}${user}/get-blood-pressure?timezone=${timezone}`;
export const GET_HEART_RATE = `${apiUrl}${v1}${user}/get-heart-rate?timezone=${timezone}`;
export const GET_LIPID_DATA = getUriWithDurationAndDateFormat(`${apiUrl}${v1}${user}/get-lipid?timezone=${timezone}`, 'w');
export const GET_RESPIRATORY_RATE = `${apiUrl}${v1}${user}/get-respiratory-rate?timezone=${timezone}`;
export const GET_OXYGEN = `${apiUrl}${v1}${user}/get-spo2?timezone=${timezone}`;
export const ADD_RESPIRATORY_RATE = `${apiUrl}${v1}${user}/add-respiratory-rate`;
export const ADD_HEART_RATE = `${apiUrl}${v1}${user}/add-heart-rate`;
export const ADD_BLOOD_PRESSURE = `${apiUrl}${v1}${user}/add-blood-pressure`;
export const ADD_BLOOD_SUGAR = `${apiUrl}${v1}${user}/add-blood-sugar`;
export const ADD_BLOOD_OXYGEN = `${apiUrl}${v1}${user}/add-spo2`;
export const ADD_BODY_TEMPERATURE = `${apiUrl}${v1}${user}/add-body-temperature`;
export const GET_PATIENT_PROFILE = `${apiUrl}${v1}${user}/get-profile`;
export const UPDATE_PATIENT_PROFILE = `${apiUrl}${v1}${user}/update-patient`;
export const ADD_LIPID_PROFILE = `${apiUrl}${v1}${user}/add-lipid`;
export const ADD_MEDICAL_REPORT = `${apiUrl}${v1}${user}/add-medical-report`;
export const DEL_MEDICAL_REPORT = `${apiUrl}${v1}${user}/delete-medical-report`;
export const GET_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/get-health-condition`;
export const ADD_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/add-health-condition`;
export const UPDATE_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/update-health-condition`;
export const DEL_HEALTH_CONDITIONS = `${apiUrl}${v1}${user}/delete-health-condition`;
export const GET_MEDICATIONS = `${apiUrl}${v1}${user}/get-medication`;
export const UPDATE_MEDICATIONS = `${apiUrl}${v1}${user}/update-medication`;
export const ADD_MEDICATIONS = `${apiUrl}${v1}${user}/add-medication`;
export const DEL_MEDICATIONS = `${apiUrl}${v1}${user}/delete-medication`;
export const GET_SURGURIES = `${apiUrl}${v1}${user}/get-surgery`;
export const UPDATE_SURGURIES = `${apiUrl}${v1}${user}/update-surgery`;
export const ADD_SURGURIES = `${apiUrl}${v1}${user}/add-surgery`;
export const DEL_SURGURIES = `${apiUrl}${v1}${user}/delete-surgery`;
export const GET_ALLERGY = `${apiUrl}${v1}${user}/get-allergy`;
export const UPDATE_ALLERGY = `${apiUrl}${v1}${user}/update-allergy`;
export const ADD_ALLERGY = `${apiUrl}${v1}${user}/add-allergy`;
export const DEL_ALLERGY = `${apiUrl}${v1}${user}/delete-allergy`;
export const GET_VACCINATIONS = `${apiUrl}${v1}${user}/get-vaccination`;
export const UPDATE_VACCINATIONS = `${apiUrl}${v1}${user}/update-vaccination`;
export const ADD_VACCINATIONS = `${apiUrl}${v1}${user}/add-vaccination`;
export const DEL_VACCINATIONS = `${apiUrl}${v1}${user}/delete-vaccination`;
export const QRCODE = `${apiUrl}${v1}${user}`;
export const PATIENT_UPLOAD = `${apiUrl}${v1}${user}/upload-file`;
export const QRPATIENTINFO = `${apiUrl}${v1}public/patient-info?`;
export const GET_PATIENT_APPOINTMENT_LIST = `${apiUrl}${v1}${user}/appointments`;
export const GET_DOCTOR_LIST = `${apiUrl}${v1}${user}/get-doctor`;
export const UPDATE_PASSWORD = `${apiUrl}${v1}${user}/update-password?token=`;
export const SLOTS = `${apiUrl}${v1}${user}/get-doctor-available-slots?timezone=${timezone}&doctorId=`;
export const CREATE_APPOINTMENT = `${apiUrl}${v1}${user}/create-appointment`;
export const VERIFY_TOKEN = `${apiUrl}${v1}${user}/verify-password-reset-token?token=`;
export const VERIFY_PAYMENT_PATIENT = `${apiUrl}${v1}${user}/verify-payment`;
export const BILLING_INVOICE = `${apiUrl}${v1}${user}/get-invoices`;
export const BILLING_INVOICE_FOR_DOCTOR = `${apiUrl}${v1}doctor/get-invoices`;
export const BILLING_INVOICE_DETAIL = `${apiUrl}${v1}${user}/get-invoice`;
export const GET_APPOINTMENT_FOR_DOCTOR = `${apiUrl}${v1}doctor/appointments`;
export const GET_PATIENT_DETAILS_FOR_DOCTOR = `${apiUrl}${v1}doctor/get-patient-details`;
export const GET_DOCTOR_LIPID_DATA = getUriWithDurationAndDateFormat(`${apiUrl}${v1}doctor/get-patient-lipid?timezone=${timezone}`, 'y');
export const GET_DOCTOR_BLOOD_SUGAR = `${apiUrl}${v1}doctor/get-patient-blood-sugar?timezone=${timezone}`;
export const GET_DOCTOR_HEALTH_PARAMS = `${apiUrl}${v1}doctor/get-patient-health-params?timezone=${timezone}`;
export const GET_DOCTOR_BODY_TEMPERATURE = `${apiUrl}${v1}doctor/get-patient-body-temperature?timezone=${timezone}`;
export const GET_DOCTOR_BLOOD_PRESSURE = `${apiUrl}${v1}doctor/get-patient-blood-pressure?timezone=${timezone}`;
export const GET_DOCTOR_HEART_RATE = `${apiUrl}${v1}doctor/get-patient-heart-rate?timezone=${timezone}`;
export const GET_DOCTOR_RESPIRATORY_RATE = `${apiUrl}${v1}doctor/get-patient-respiratory-rate?timezone=${timezone}`;
export const GET_DOCTOR_OXYGEN = `${apiUrl}${v1}doctor/get-patient-spo2?timezone=${timezone}`;
export const GET_PATIENT_PREVIOUS_APPOINTMENTS_FOR_DOCTOR = `${apiUrl}${v1}doctor/get-patient-previous-appointment?timezone=${timezone}&duration=y`;
export const DOCTOR_LOGOUT = `${apiUrl}${v1}doctor/logout`;
export const DOCTOR_UPLOAD = `${apiUrl}${v1}doctor/upload-file`;
export const GET_CURRENCY = `${apiUrl}${v1}public/get-currencies`;
export const GET_DOCTOR_AVAILABLE_TIME = `${apiUrl}${v1}doctor/available-time?timezone=${timezone}`;
export const DOCTOR_ADD_SLOT=`${apiUrl}${v1}doctor/add-slots`;
export const GET_SLOT_BY_DOCTOR=`${apiUrl}${v1}doctor/slots?timezone=${timezone}`;
export const DELETE_SLOT_BY_DOCTOR=`${apiUrl}${v1}doctor/delete-slot?`;
export const SEND_MEDICATION_BY_DOCTOR=`${apiUrl}${v1}doctor/add-eprescription`;
export const CANCEL_APPOINTMENT_BY_PATIENT=`${apiUrl}${v1}patient/cancel-appointment`;
export const CONFIRM_APPOINTMENT_BY_DOCTOR=`${apiUrl}${v1}doctor/confirm-appointment`;
export const PATIENT_CHECKOUT= `${apiUrl}${v1}patient/appointment/checkout`;
export const GET_PROFILE_BY_DOCTOR= `${apiUrl}${v1}doctor/get-profile`;
export const UPDATE_PROFILE_BY_DOCTOR= `${apiUrl}${v1}doctor/update-profile`;
export const GET_MEDICAL_REPORT = `${apiUrl}${v1}${user}/get-medical-report`;
export const GET_PATIENT_MEDICAL_REPORT=`${apiUrl}${v1}doctor/get-patient-medical-reports?timezone=${timezone}&duration=y`;
export const GET_MEDICAL_DETAILS_BY_PATIENT = `${apiUrl}${v1}patient/get-medical-detailes`;
export const GET_MEDICAL_DETAILS_BY_DOCTOR = `${apiUrl}${v1}doctor/get-patient-medical-detailes?duration=y&timezone=${timezone}`;
export const GET_PATIENT_PRESCRIPTION_BY_DOCTOR=`${apiUrl}${v1}doctor/get-patient-prescriptions?timezone=${timezone}&duration=y`;
export const GET_PATIENT_EPRESCRIPTION = `${apiUrl}${v1}patient/get-prescriptions`;
export const ORGANIZATION_SIGNUP = `${apiUrl}${v1}organization/sign-up`;
export const ORGANIZATION_GET_APPOINTMENTLIST=`${apiUrl}${v1}organization/appointments`;
export const GET_ORGANIZATION_HEALTH_PARAMS = `${apiUrl}${v1}organization/get-patient-health-params?timezone=${timezone}`;
export const REMOVE_ORGANIZATION_PATIENT = `${apiUrl}${v1}organization/remove-patient-organization`;
export const GET_PATIENT_DETAILS_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-details`;
export const GET_DOCTOR_DETAILS_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-doctor-details`;
export const GET_SUBADMIN_DETAILS_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-subadmin-details`;
export const GET_ORGANIZATION_LIPID_PROFILE = `${apiUrl}${v1}organization/get-patient-lipid?timezone=${timezone}`;
export const GET_MEDICAL_DETAILS_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-medical-detailes?timezone=${timezone}&duration=y`;
export const GET_MEDICAL_REPORTS_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-medical-reports?timezone=${timezone}&duration=y`;
export const GET_EPRESCIPTION_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-prescriptions?timezone=${timezone}&duration=y`;
export const GET_PATIENT_PREVIOUS_APPOINTMENTS_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-previous-appointment?timezone=${timezone}&duration=y`;
export const GET_PATIENT_BLOOD_SUGAR_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-blood-sugar?timezone=${timezone}`;
export const GET_PATIENT_OXYGEN_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-spo2?timezone=${timezone}`;
export const GET_PATIENT_BLOOD_PRESSURE_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-blood-pressure?timezone=${timezone}`;
export const GET_PATIENT_BODY_TEMPERATURE_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-body-temperature?timezone=${timezone}`;
export const GET_PATIENT_HEART_RATE_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-heart-rate?timezone=${timezone}`;
export const ORGANIZATION_REMOVE_PATIENT=`${apiUrl}${v1}organization/remove-patient-organization`;
export const ORGANIZATION_DOCTOR_UPDATE_STATUS=`${apiUrl}${v1}organization/update-doctor-status`;
export const ORGANIZATION_SUBADMIN_UPDATE_STATUS=`${apiUrl}${v1}organization/update-subadmin-status`;

export const GET_PATIENT_RESPIRATORY_RATE_ORGANIZATION = `${apiUrl}${v1}organization/get-patient-respiratory-rate?timezone=${timezone}`;
export const GET_PATIENT_LIST = `${apiUrl}${v1}organization/get-patients`;
export const GET_DOCTOR_LIST_FOR_ORGANIZATION = `${apiUrl}${v1}organization/get-doctors`;
export const ADD_DOCTOR = `${apiUrl}${v1}organization/add-doctor`;
export const ORGANIZATION_SUBSCRIPTION_API = `${apiUrl}${v1}organization/create-subscription`;
export const ADD_SUBADMIN = `${apiUrl}${v1}organization/add-subadmin`;
export const GET_ORGANIZATION_PROFILE = `${apiUrl}${v1}organization/get-profile`;
export const GET_PLANS = `${apiUrl}${v1}public/get-plans`;
export const UPDATE_PROFILE_BY_ORGANIZATION = `${apiUrl}${v1}organization/update-profile`;
export const GET_SUBADMIN_LIST = `${apiUrl}${v1}organization/get-subadmin`;
export const GET_INVOICES_BY_ORGANIZATION = `${apiUrl}${v1}organization/get-invoices`;
export const ADD_PATIENT_BY_FORM = `${apiUrl}${v1}organization/add-new-patient`;
export const ADD_PATIENT_BY_ID =`${apiUrl}${v1}organization/update-patient-organizationId`;
export const ORGANIZATION_LOGOUT = `${apiUrl}${v1}organization/logout`;
export const ORGANIZATION_GET_SUBCRIPTION = `${apiUrl}${v1}organization/get-subscription`;
export const VERIFY_PAYMENT_ORGANIZATION = `${apiUrl}${v1}organization/verify-payment`;
export const ORGANIZATION_UPLOAD =  `${apiUrl}${v1}organization/upload-file`
export const SUBADMIN_LOGIN = `${apiUrl}${v1}sub-admin/sign-in`;
export const GET_PROFILE_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/get-profile`;
export const UPDATE_PROFILE_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/update-profile`;
export const GET_OPD_APPOINTMENTLIST_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/appointments`;
export const GET_INVOICES_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/get-invoices`;
export const SUBADMIN_LOGOUT = `${apiUrl}${v1}sub-admin/logout`;
export const GET_PATIENT_DETAILS_FOR_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-details`;
export const GET_SUBADMIN_HEALTH_PARAMS = `${apiUrl}${v1}sub-admin/get-patient-health-params?timezone=${timezone}`;
export const GET_SUBADMIN_LIPID_PROFILE = `${apiUrl}${v1}sub-admin/get-patient-lipid?timezone=${timezone}`;
export const GET_PATIENT_BLOOD_SUGAR_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-blood-sugar?timezone=${timezone}`;
export const GET_PATIENT_OXYGEN_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-spo2?timezone=${timezone}`;
export const GET_PATIENT_BLOOD_PRESSURE_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-blood-pressure?timezone=${timezone}`;
export const GET_PATIENT_BODY_TEMPERATURE_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-body-temperature?timezone=${timezone}`;
export const GET_PATIENT_HEART_RATE_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-heart-rate?timezone=${timezone}`;
export const GET_PATIENT_RESPIRATORY_RATE_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-respiratory-rate?timezone=${timezone}`;
export const GET_MEDICAL_DETAILS_FOR_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-medical-detailes?timezone=${timezone}&duration=y`;
export const GET_MEDICAL_REPORTS_FOR_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-medical-reports?timezone=${timezone}&duration=y`;
export const GET_PATIENT_PREVIOUS_APPOINTMENTS_FOR_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-previous-appointment?timezone=${timezone}&duration=y`;
export const GET_PATIENT_LIST_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patients`;
export const GET_DOCTOR_LIST_FOR_SUBADMIN = `${apiUrl}${v1}sub-admin/get-doctors`;
export const GET_EPRESCRIPTION_FOR_SUBADMIN = `${apiUrl}${v1}sub-admin/get-patient-prescriptions?timezone=${timezone}&duration=y`;
export const SLOTS_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/get-doctor-available-slots?timezone=${timezone}&doctorId=`;
export const CREATE_APPOINTMENT_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/create-appointment`;
export const GET_ROOMS = `${apiUrl}${v1}sub-admin/get-rooms`;
export const ADD_ROOM = `${apiUrl}${v1}sub-admin/add-room`;
export const SUBADMIN_UPLOAD = `${apiUrl}${v1}sub-admin/upload-file`;
export const UPDATE_ROOM = `${apiUrl}${v1}sub-admin/update-room`;
export const ADD_PATIENT_BY_SUBADMIN = `${apiUrl}${v1}sub-admin/add-new-patient`;
export const ADMIT_PATIENT = `${apiUrl}${v1}sub-admin/admit-patient`;
export const ADD_PATIENT_SERVICE = `${apiUrl}${v1}sub-admin/add-admit-patient-service`;
export const GET_ADMITTED_PATIENT = `${apiUrl}${v1}sub-admin/get-admitted-patients?`;
export const VERIFY_PAYMENT_SUBADMIN = `${apiUrl}${v1}sub-admin/verify-payment`;
export const GET_ADMITTED_PATIENT_SERVICE = `${apiUrl}${v1}sub-admin/get-admitted-patient-services?admitPatientId=`;

