import React, { useState } from "react";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import {
  StyledCrossIcon,
  StyledSecondaryButton,
} from "../../assets/styles/CommonStyles";
import { addDoctorSchema } from "../../utils/forms/schema.addDoctor";
import { Formik } from "formik";
import { CircularProgress, Grid } from "@mui/material";
import strings from "../../constant/strings";
import FieldWrapper from "../InputField/TextFieldWrapper";
import SelectionDropdown from "../control/SelectionDropdown";
import {
  docDegree,
  language,
  occupationList,
  permission,
  status,
} from "../../constant/staticLists";
import { capitalizeFirstLetter } from "../../constant/staticHelper";
import PhoneField from "../PhoneField";
import * as mainService from "../../services/mainService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { medicationStyles } from "../../assets/styles/MedicationStyles";
import { getDoctorListAction } from "../../redux/appointment/patient/patientAppointmentActions";

const AddDoctorForm = ({ setOpenModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const medicationClasses= medicationStyles();
  const [onLoading, setOnLoading] = useState(false);
  const initialValues = {
    name: "",
    email: "",
    phone_number: "",
    department: "",
    permission: "",
    status: "",
    country_code: "",
    languages: [],
    degrees: [],
    experience: "",
    address: "",
  };

  const { userData } = useSelector((state) => ({
    userData: state.auth.userData,
  }));

  const occupationLists = Object.keys(occupationList).map((key) => ({
    label: capitalizeFirstLetter(key.replace(/_/g, " ").toLowerCase()),
    level: occupationList[key],
  }));

  const handleSubmit = async (values, {resetForm}) => {
    let phone = values.country_code.replace("+", "");
    const phone1 = values.phone_number.replace(phone, "");
    const dataValues = { ...values, phone_number: phone1 };
    setOnLoading(true);
    const res = await mainService.addDoctorApi({token: userData.token, data: dataValues});
    if (res.response_type === "fail") {
      setOnLoading(false);
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      resetForm();
      setOnLoading(false);
      setOpenModal(false);
      toast.success(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      dispatch(getDoctorListAction(userData.token))
    }
  };

  return (
    <>
      <div>
        <Grid className={classes.secondayCont}>
          <h2 className={classes.mainCont}>{strings.addDoctor}</h2>
          <StyledCrossIcon
            src={"/assets/img/x.png"}
            alt="User"
            onClick={() => setOpenModal(false)}
          />
        </Grid>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={addDoctorSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              errors,
              handleBlur,
              values,
              setFieldValue,
              setTouched,
              touched,resetForm
            }) => {
              const handleDropdownValue = (fieldName, data) => {
                if (data) {
                  if (fieldName === "degrees" || fieldName === "languages") {
                    let arr = [];
                    data.map((item) => arr.push(item.label));
                    setFieldValue(fieldName, arr);
                  } else {
                    setFieldValue(fieldName, data.level);
                  }
                }
              };

              const handlePhone = (e, value) => {
                setFieldValue("country_code", value);
                setFieldValue("phone_number", e);
              };

              return (
                <>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="name"
                        type="text"
                        label={`${strings.doctorFullname}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="email"
                        type="text"
                        label={`${strings.doctorEmail}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PhoneField
                        value={initialValues.phone_number}
                        name="phone_number"
                        onChange={(e, value) => handlePhone(e, value.dialCode)}
                        onBlur={() =>
                          setTouched({ ...touched, contactNumber: true })
                        }
                        touched={touched}
                        errors={errors}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="department"
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        label={`${strings.selectDepartment}`}
                        list={occupationLists}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("department", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="permission"
                        label={`${strings.selectPermission}`}
                        list={permission}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("permission", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectionDropdown
                        name="status"
                        label={`${strings.selectStatus}`}
                        list={status}
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("status", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FieldWrapper
                        name="experience"
                        type="text"
                        label={`${strings.selectExp}`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectionDropdown
                        name="degrees"
                        label={`${strings.selectDegree}`}
                        list={docDegree}
                        navigate="multiple"
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("degrees", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectionDropdown
                        name="languages"
                        label={`${strings.selectLanguage}`}
                        list={language}
                        navigate="multiple"
                        height={"36px"}
                        className={medicationClasses.customAutocomplete}
                        handleDropdownValue={(data) =>
                          handleDropdownValue("languages", data)
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FieldWrapper
                        name="address"
                        type="text"
                        label={`${strings.selectAddress}`}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      display="flex"
                      justifyContent={"center"}
                    >
                      <StyledSecondaryButton
                        name="submit"
                        disabled={Object.keys(errors).length > 0 || onLoading}
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        {onLoading ? (
                          <CircularProgress
                            style={{ color: "white" }}
                            size={20}
                            thickness={5}
                          />
                        ) : (
                          `${strings.add}`
                        )}
                      </StyledSecondaryButton>
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default AddDoctorForm;
