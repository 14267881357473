import { Grid, Typography } from "@mui/material";
import React from "react";
import strings from "../../../constant/strings";
import FieldWrapper from "../../InputField/TextFieldWrapper";
import colors from "../../../constant/colors";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { subadminDashboardStyles } from "../../../assets/styles/subadminDashboardStyle";

const MedicalDetails = ({ handleDropdownValue, setFieldValue, values }) => {
  const classes = commonMuiStyles();
  const styleClasses = subadminDashboardStyles();
  return (
    <>
    <Grid item xs={12}  className={classes.centerColumnStyle} style={{background: colors.yellowLight, marginLeft:'16px',padding:'0px'}} mt={2} >
      <div className={styleClasses.textBox}>
      <img src="/assets/img/bulb.png" alt="bulb" className={`${classes.iconStyle40}`} ></img>    
      </div>
      <Typography className={`${classes.fontText12} ${styleClasses.textBox}`} color={colors.yellow} textAlign='center'> 
      It is recommended for patient to get initial diagnosis from the doctor and get their vitals checked.</Typography>    
    </Grid>
      <Grid item xs={12}>
        <FieldWrapper
          type="text"
          name="diagonosis"
          label={`${strings.diagonosis}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          type="number"
          name="BPDiastolic"
          label={`${strings.diastolic}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          type="number"
          name="BPSystolic"
          label={`${strings.systolic}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          type="number"
          name="spo2"
          label={`${strings.spo2Per}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          type="number"
          name="temp"
          label={`${strings.bodyTemp}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          type="number"
          name="pulse"
          label={`${strings.pulsePerMin}`}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldWrapper
          type="number"
          name="rr"
          label={`${strings.respiratoryRate}`}
        />
      </Grid>
      <Grid item xs={12}>
        <FieldWrapper
          type="text"
          name="illnessSumary"
          label={`${strings.illness}`}
          multiline = {true}
          rows={4}
        //   sx={{'& .MuiOutlinedInput-root': {height: '200px'}}}
        />
      </Grid>
      <Grid item xs={9}>
        <FieldWrapper
          type="text"
          name={`cheifComplaints[0].complaints`}
          label='Chief Complaints And Duration'
        />
      </Grid>
      <Grid item xs={3}>
        <FieldWrapper
          type="text"
          name={`cheifComplaints[0].duration`}
          label={`${strings.duration}`}
        />
        {console.log(values)}
      </Grid>
      <Grid item xs={9}>
        <FieldWrapper
          type="text"
          name={`generalAppearce[0].general`}
          label='General Appearance'
        />
      </Grid>
      <Grid item xs={3}>
        <FieldWrapper
          type="text"
          name={`generalAppearce[0].comments`}
          label={`${strings.comment}`}
        />
      </Grid>
    </>
  );
};

export default MedicalDetails;
