import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { getProfileByOrganizationAPI, updateProfileByOrganizationAPI } from '../../../services/mainService';
import { organizationProfileAction, updateOrganizationProfileAction } from './organizationAction';
import { getProfileByOrganizationFailure, getProfileByOrganizationRequest, getProfileByOrganizationSuccess, updateProfileByOrganizationFailure, updateProfileByOrganizationRequest, updateProfileByOrganizationSuccess } from './organizationSlice';


  function* getProfileByOrganization(action) {
    try {
       yield put(getProfileByOrganizationRequest()); 
      const apiResponse = yield call(getProfileByOrganizationAPI, action.payload);
     if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
         yield put(getProfileByOrganizationSuccess(listData));
  
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put(getProfileByOrganizationFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  
  function* updateProfileByOrganization(action) {
    try {
       yield put(updateProfileByOrganizationRequest()); 
      const apiResponse = yield call(updateProfileByOrganizationAPI, action.payload);
     if (Object.values(apiResponse).length === 0) {
        // yield put({ type: authActionTypes.LOG_OUT.SUCCESS, payload: {} });
      } else {
        if (apiResponse.response_type === "success") {
          const listData = apiResponse.response.data;
         yield put(updateProfileByOrganizationSuccess(listData));
         getProfileByOrganization(action.payload.token)
        } else {
          toast.error(apiResponse.response.message, { position: "bottom-center", closeButton: false, autoClose: 2000, style: {width: 'auto', borderRadius: 8 }});
          yield put( updateProfileByOrganizationFailure(apiResponse.response.message))
        }
      }
    } catch (error) { }
  }

  export function* organizationSaga() {
    yield takeLatest(organizationProfileAction.type, getProfileByOrganization);  
    yield takeLatest(updateOrganizationProfileAction.type, updateProfileByOrganization);     
  }
