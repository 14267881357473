import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { organizationProfileAction, updateOrganizationProfileAction } from "../../../../redux/user/organization/organizationAction";
import HeadingComponent from "../../HeadingComponent";
import strings from "../../../../constant/strings";
import {
  StyledCrossIcon,
  StyledHeadingText01,
  UserImage,
} from "../../../../assets/styles/CommonStyles";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { profileStyles } from "../../../../assets/styles/DoctorProfileSettingStyles";
import defaultUserPng from "../../../../assets/img/user.png";
import UploadFile from "../../../controls/UploadFile";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import TabComponent from "../../../Tab";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import ResetPassword from "../../../ResetPassword";
import OrgProfile from "../../../OrgProfile";
import axios from "axios";
import * as mainService from "../../../../services/mainService";
import { toast } from "react-toastify";
import { organizationlogoutAction } from "../../../../redux/auth/authActions";

import { ORGANIZATION_UPLOAD } from "../../../../services/apiUrl";
const OrganizationProfileSetting = () => {
  const dispatch = useDispatch();
  const styleClasses = commonMuiStyles();
  const profileSettingClasses = profileStyles();
  const classes = useStyles();
  const history = useHistory();
  const inputRefs = useRef();
  const [image, setImage] = useState();
  const [fileType, setFileType] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [values, setValues] = useState();
  const [onLoading, setLoading] = useState(false);
  const { orgProfileData, userData } = useSelector((state) => ({
    orgProfileData: state.organization.orgProfileData,
    userData: state.auth.userData,
  }));
  const tabs = [
    { id: 0, name: "Organisation details" },
    { id: 1, name: "Reset password" },
  ];
const url = ORGANIZATION_UPLOAD

  console.log("setImages",image)
  useEffect(() => {
    dispatch(organizationProfileAction(userData.token));
  }, []);
console.log("userData",userData.logo)
  const handleCross = () => {
    history.goBack();
  };

  const handleTab = (e, newValue) => {
    setTabValue(newValue);
  };
  useEffect(()=>{
    if (image){
      handleSubmit()
    }
  },[fileType])
  const handleSubmit = async (event) => {
    // event.preventDefault();
    
    const formData = new FormData();
    formData.append('file', image);

    try {
      console.log("formdata",formData)
      const response = await mainService.fileUpload(url, formData) 
      await setValues(response?.response?.data)
      if (image){
        await  handleSubmitupdate(response?.response?.data)
      }
  
     
    } catch (error) {
      console.error('Error:', error);
    }
  };
  const handleSubmitupdate = async (values) => {
    setLoading(true);
    const profileimg = {
      logo:values
    }
    console.log("profileimg",profileimg)
    
    const res = await mainService.updateProfileByOrganizationAPI({
      token: userData.token,
      data: profileimg,
    });
    console.log("res",res)
   
    if (res.response_type === "fail") {
      setLoading(false);
      console.log("jjj")
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      setLoading(false);
      await dispatch(updateOrganizationProfileAction(userData.token));
      toast.success("Profile updated successfully.", {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      dispatch(organizationlogoutAction(userData.token))
    }

  };

  return (
    <Grid container spacing={0}>
      <Grid
        item
        xs={12}
        className={`${classes.mainCont} ${profileSettingClasses.mainSecCont}`}
      >
        <StyledHeadingText01>{strings.orgProfile}</StyledHeadingText01>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="x"
          onClick={handleCross}
        />
      </Grid>
      <Grid item xs={12} className={profileSettingClasses.imageCont}>
        <div>
          <IconButton
            sx={{
              position: "absolute",
              // top: "200px",
              right: "calc(50% - 55px)",
            }}
          >
            <BorderColorIcon onClick={() => inputRefs.current.click()} />
          </IconButton>

          <UploadFile
            inputRefs={inputRefs}
            image={image}
            setImage={setImage}
            fileType={fileType}
            setFileType={setFileType}
            limit={5}
            navigate="single"
          />
          <UserImage
          // url={userData.logo}
            // src={ (userData.logo) || defaultUserPng }
            src={image||userData.logo || defaultUserPng}
            className={profileSettingClasses.imageStyle}
            alt="organization logo"
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TabComponent
          tabs={tabs}
          tabValue={tabValue}
          setTabValue={setTabValue}
          handleTab={handleTab}
          customTab={styleClasses.customTab}
          customTabIndicator={styleClasses.customTabIndicator}
        />
      </Grid>
      <Grid item xs={12}>
        {tabValue === 0 ? (
          <OrgProfile onLoading = { onLoading } setLoading = {setLoading} />
        ) : (
          <ResetPassword onLoading = { onLoading } setLoading= {setLoading} />
        )}
      </Grid>
    </Grid>
  );
};

export default OrganizationProfileSetting;
