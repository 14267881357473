import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";

export const OrgAppoinStyles = makeStyles(() => ({
  marginStyle: { padding: "4px" },
  imageStyle: {
    height: "24px",
    width: "24px",
  },
  profileImage: {
    width: "90px",
    height: "90px",
    borderRadius: "100px",
    background: colors.grey3,
    boxShadow: "2.25px 2.25px 8.4375px 0px rgba(192, 192, 192, 0.25)",
  },
}));
