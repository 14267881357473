export const apiUrl =  'https://dev.emed4u.com/' //'http://localhost:5000'; 



export const Countries = [
	{ value: "AED", label: "United Arab Emirates Dirham" },
	{ value: "AFN", label: "Afghanistan Afghani" },
	{ value: "ALL", label: "Albania Lek" },
	{ value: "AMD", label: "Armenia Dram" },
	{ value: "ANG", label: "Netherlands Antilles Guilder" },
	{ value: "AOA", label: "Angola Kwanza" },
	{ value: "ARS", label: "Argentina Peso" },
	{ value: "AUD", label: "Australia Dollar" },
	{ value: "AWG", label: "Aruba Guilder" },
	{ value: "AZN", label: "Azerbaijan Manat" },
	{ value: "BAM", label: "Bosnia and Herzegovina Convertible Mark" },
	{ value: "BBD", label: "Barbados Dollar" },
	{ value: "BDT", label: "Bangladesh Taka" },
	{ value: "BGN", label: "Bulgaria Lev" },
	{ value: "BHD", label: "Bahrain Dinar" },
	{ value: "BIF", label: "Burundi Franc" },
	{ value: "BMD", label: "Bermuda Dollar" },
	{ value: "BND", label: "Brunei Darussalam Dollar" },
	{ value: "BOB", label: "Bolivia Bolíviano" },
	{ value: "BRL", label: "Brazil Real" },
	{ value: "BSD", label: "Bahamas Dollar" },
	{ value: "BTN", label: "Bhutan Ngultrum" },
	{ value: "BWP", label: "Botswana Pula" },
	{ value: "BYN", label: "Belarus Ruble" },
	{ value: "BZD", label: "Belize Dollar" },
	{ value: "CAD", label: "Canada Dollar" },
	{ value: "CDF", label: "Congo/Kinshasa Franc" },
	{ value: "CHF", label: "Switzerland Franc" },
	{ value: "CLP", label: "Chile Peso" },
	{ value: "CNY", label: "China Yuan Renminbi" },
	{ value: "COP", label: "Colombia Peso" },
	{ value: "CRC", label: "Costa Rica Colon" },
	{ value: "CUC", label: "Cuba Convertible Peso" },
	{ value: "CUP", label: "Cuba Peso" },
	{ value: "CVE", label: "Cape Verde Escudo" },
	{ value: "CZK", label: "Czech Republic Koruna" },
	{ value: "DJF", label: "Djibouti Franc" },
	{ value: "DKK", label: "Denmark Krone" },
	{ value: "DOP", label: "Dominican Republic Peso" },
	{ value: "DZD", label: "Algeria Dinar" },
	{ value: "EGP", label: "Egypt Pound" },
	{ value: "ERN", label: "Eritrea Nakfa" },
	{ value: "ETB", label: "Ethiopia Birr" },
	{ value: "EUR", label: "Euro Member Countries" },
	{ value: "FJD", label: "Fiji Dollar" },
	{ value: "FKP", label: "Falkland Islands (Malvinas) Pound" },
	{ value: "GBP", label: "United Kingdom Pound" },
	{ value: "GEL", label: "Georgia Lari" },
	{ value: "GGP", label: "Guernsey Pound" },
	{ value: "GHS", label: "Ghana Cedi" },
	{ value: "GIP", label: "Gibraltar Pound" },
	{ value: "GMD", label: "Gambia Dalasi" },
	{ value: "GNF", label: "Guinea Franc" },
	{ value: "GTQ", label: "Guatemala Quetzal" },
	{ value: "GYD", label: "Guyana Dollar" },
	{ value: "HKD", label: "Hong Kong Dollar" },
	{ value: "HNL", label: "Honduras Lempira" },
	{ value: "HRK", label: "Croatia Kuna" },
	{ value: "HTG", label: "Haiti Gourde" },
	{ value: "HUF", label: "Hungary Forint" },
	{ value: "IDR", label: "Indonesia Rupiah" },
	{ value: "ILS", label: "Israel Shekel" },
	{ value: "IMP", label: "Isle of Man Pound" },
	{ value: "INR", label: "India Rupee" },
	{ value: "IQD", label: "Iraq Dinar" },
	{ value: "IRR", label: "Iran Rial" },
	{ value: "ISK", label: "Iceland Krona" },
	{ value: "JEP", label: "Jersey Pound" },
	{ value: "JMD", label: "Jamaica Dollar" },
	{ value: "JOD", label: "Jordan Dinar" },
	{ value: "JPY", label: "Japan Yen" },
	{ value: "KES", label: "Kenya Shilling" },
	{ value: "KGS", label: "Kyrgyzstan Som" },
	{ value: "KHR", label: "Cambodia Riel" },
	{ value: "KMF", label: "Comorian Franc" },
	{ value: "KPW", label: "Korea (North) Won" },
	{ value: "KRW", label: "Korea (South) Won" },
	{ value: "KWD", label: "Kuwait Dinar" },
	{ value: "KYD", label: "Cayman Islands Dollar" },
	{ value: "KZT", label: "Kazakhstan Tenge" },
	{ value: "LAK", label: "Laos Kip" },
	{ value: "LBP", label: "Lebanon Pound" },
	{ value: "LKR", label: "Sri Lanka Rupee" },
	{ value: "LRD", label: "Liberia Dollar" },
	{ value: "LSL", label: "Lesotho Loti" },
	{ value: "LYD", label: "Libya Dinar" },
	{ value: "MAD", label: "Morocco Dirham" },
	{ value: "MDL", label: "Moldova Leu" },
	{ value: "MGA", label: "Madagascar Ariary" },
	{ value: "MKD", label: "Macedonia Denar" },
	{ value: "MMK", label: "Myanmar (Burma) Kyat" },
	{ value: "MNT", label: "Mongolia Tughrik" },
	{ value: "MOP", label: "Macau Pataca" },
	{ value: "MRU", label: "Mauritania Ouguiya" },
	{ value: "MUR", label: "Mauritius Rupee" },
	{ value: "MVR", label: "Maldives (Maldive Islands) Rufiyaa" },
	{ value: "MWK", label: "Malawi Kwacha" },
	{ value: "MXN", label: "Mexico Peso" },
	{ value: "MYR", label: "Malaysia Ringgit" },
	{ value: "MZN", label: "Mozambique Metical" },
	{ value: "NAD", label: "Namibia Dollar" },
	{ value: "NGN", label: "Nigeria Naira" },
	{ value: "NIO", label: "Nicaragua Cordoba" },
	{ value: "NOK", label: "Norway Krone" },
	{ value: "NPR", label: "Nepal Rupee" },
	{ value: "NZD", label: "New Zealand Dollar" },
	{ value: "OMR", label: "Oman Rial" },
	{ value: "PAB", label: "Panama Balboa" },
	{ value: "PEN", label: "Peru Sol" },
	{ value: "PGK", label: "Papua New Guinea Kina" },
	{ value: "PHP", label: "Philippines Peso" },
	{ value: "PKR", label: "Pakistan Rupee" },
	{ value: "PLN", label: "Poland Zloty" },
	{ value: "PYG", label: "Paraguay Guarani" },
	{ value: "QAR", label: "Qatar Riyal" },
	{ value: "RON", label: "Romania Leu" },
	{ value: "RSD", label: "Serbia Dinar" },
	{ value: "RUB", label: "Russia Ruble" },
	{ value: "RWF", label: "Rwanda Franc" },
	{ value: "SAR", label: "Saudi Arabia Riyal" },
	{ value: "SBD", label: "Solomon Islands Dollar" },
	{ value: "SCR", label: "Seychelles Rupee" },
	{ value: "SDG", label: "Sudan Pound" },
	{ value: "SEK", label: "Sweden Krona" },
	{ value: "SGD", label: "Singapore Dollar" },
	{ value: "SHP", label: "Saint Helena Pound" },
	{ value: "SLL", label: "Sierra Leone Leone" },
	{ value: "SOS", label: "Somalia Shilling" },
	{ value: "SPL*", label: "Seborga Luigino" },
	{ value: "SRD", label: "Suriname Dollar" },
	{ value: "STN", label: "São Tomé and Príncipe Dobra" },
	{ value: "SVC", label: "El Salvador Colon" },
	{ value: "SYP", label: "Syria Pound" },
	{ value: "SZL", label: "eSwatini Lilangeni" },
	{ value: "THB", label: "Thailand Baht" },
	{ value: "TJS", label: "Tajikistan Somoni" },
	{ value: "TMT", label: "Turkmenistan Manat" },
	{ value: "TND", label: "Tunisia Dinar" },
	{ value: "TOP", label: "Tonga Pa'anga" },
	{ value: "TRY", label: "Turkey Lira" },
	{ value: "TTD", label: "Trinidad and Tobago Dollar" },
	{ value: "TVD", label: "Tuvalu Dollar" },
	{ value: "TWD", label: "Taiwan New Dollar" },
	{ value: "TZS", label: "Tanzania Shilling" },
	{ value: "UAH", label: "Ukraine Hryvnia" },
	{ value: "UGX", label: "Uganda Shilling" },
	{ value: "USD", label: "United States Dollar" },
	{ value: "UYU", label: "Uruguay Peso" },
	{ value: "UZS", label: "Uzbekistan Som" },
	{ value: "VEF", label: "Venezuela Bolívar" },
	{ value: "VND", label: "Viet Nam Dong" },
	{ value: "VUV", label: "Vanuatu Vatu" },
	{ value: "WST", label: "Samoa Tala" },
	{ value: "XAF", label: "Communauté Financière Africaine (BEAC) CFA Franc BEAC" },
	{ value: "XCD", label: "East Caribbean Dollar" },
	{ value: "XDR", label: "International Monetary Fund (IMF) Special Drawing Rights" },
	{ value: "XOF", label: "Communauté Financière Africaine (BCEAO) Franc" },
	{ value: "XPF", label: "Comptoirs Français du Pacifique (CFP) Franc" },
	{ value: "YER", label: "Yemen Rial" },
	{ value: "ZAR", label: "South Africa Rand" },
	{ value: "ZMW", label: "Zambia Kwacha" },
	{ value: "ZWD", label: "Zimbabwe Dollar" }
]

export function getPayLogo(name) {
	if(name === 'Apple pay') {
		return '/assets/img/apple-pay.png';
	} else if(name === 'Amazon pay') {
		return '/assets/img/amazon-pay.png';
	} else if(name === 'Google pay') {
		return '/assets/img/google-pay.png';
	} else if(name === 'Paypal') {
		return '/assets/img/paypal.png';
	} else if(name === 'Phonepe') {
		return '/assets/img/phonePe.png';
	} else if(name === 'Samsung pay') {
		return '/assets/img/samsung-pay.png';
	}
}

export const symptomsArr = [
	{ label: 'General', isDisabled: true },
	{ label: 'cachexia', isDisabled: false },
	{ label: 'loss of appetite', isDisabled: false },
	{ label: 'weight loss', isDisabled: false },
	{ label: 'weight gain', isDisabled: false },
	{ label: 'dry mouth', isDisabled: false },
	{ label: 'fatigue', isDisabled: false },
	{ label: 'malaise', isDisabled: false },
	{ label: 'asthenia', isDisabled: false },
	{ label: 'muscle weakness', isDisabled: false },
	{ label: 'pyrexia', isDisabled: false },
	{ label: 'jaundice', isDisabled: false },
	{ label: 'pain', isDisabled: false },
	{ label: 'abdominal pain', isDisabled: false },
	{ label: 'chest pain', isDisabled: false },
	{ label: 'dyspareunia', isDisabled: false },
	{ label: 'vaginismus', isDisabled: false },
	{ label: 'bruising', isDisabled: false },
	{ label: 'epistaxis', isDisabled: false },
	{ label: 'tremor', isDisabled: false },
	{ label: 'convulsions', isDisabled: false },
	{ label: 'muscle cramps', isDisabled: false },
	{ label: 'paralysis', isDisabled: false },
	{ label: 'tinnitus', isDisabled: false },
	{ label: 'dizziness / vertigo', isDisabled: false },
	{ label: 'syncope', isDisabled: false },
	{ label: 'fecal incontinence', isDisabled: false },
	{ label: 'cataplexy', isDisabled: false },
	{ label: 'hypothermia', isDisabled: false },
	{ label: 'hyperthermia', isDisabled: false },

	{ label: 'Neurological/Psychological', isDisabled: true },
	{ label: 'anxiety', isDisabled: false },
	{ label: 'ataxia', isDisabled: false },
	{ label: 'catatonia', isDisabled: false },
	{ label: 'dysarthria', isDisabled: false },
	{ label: 'somnolence', isDisabled: false },
	{ label: 'insomnia', isDisabled: false },

	{ label: 'Ocular', isDisabled: true },
	{ label: 'amaurosis fugax and amaurosis', isDisabled: false },
	{ label: 'blurred vision', isDisabled: false },
	{ label: 'double vision', isDisabled: false },
	{ label: 'mydriasis/miosis', isDisabled: false },
	{ label: 'nystagmus', isDisabled: false },
	{ label: 'Gastrointestinal', isDisabled: false },
	{ label: 'anorexia', isDisabled: false },
	{ label: 'bloating', isDisabled: false },
	{ label: 'belching', isDisabled: false },
	{ label: 'blood in stool: melena,hematochezia', isDisabled: false },
	{ label: 'constipation', isDisabled: false },
	{ label: 'diarrhea', isDisabled: false },
	{ label: 'dysphagia', isDisabled: false },
	{ label: 'flatulence', isDisabled: false },
	{ label: 'nausea', isDisabled: false },
	{ label: 'pyrosis', isDisabled: false },
	{ label: 'vomiting', isDisabled: false },

	{ label: 'Cardiovascular', isDisabled: true },
	{ label: 'chest pain', isDisabled: false },
	{ label: 'claudication', isDisabled: false },
	{ label: 'palpitation', isDisabled: false },
	{ label: 'tachycardia', isDisabled: false },
	{ label: 'bradycardia', isDisabled: false },
	{ label: 'arrhythmia', isDisabled: false },
	{ label: 'reticulocytosis', isDisabled: false },
	{ label: 'macrocytosis', isDisabled: false },

	{ label: 'Urologic', isDisabled: true },
	{ label: 'dysuria', isDisabled: false },
	{ label: 'polyuria', isDisabled: false },
	{ label: 'hematuria', isDisabled: false },
	{ label: 'urinary frequency', isDisabled: false },
	{ label: 'urinary incontinence', isDisabled: false },
	{ label: 'impotence', isDisabled: false },

	{ label: 'Pulmonary', isDisabled: true },
	{ label: 'hypoventilation', isDisabled: false },
	{ label: 'hyperventilation', isDisabled: false },
	{ label: 'bradypnea', isDisabled: false },
	{ label: 'apnea', isDisabled: false },
	{ label: 'cough', isDisabled: false },
	{ label: 'dyspnea', isDisabled: false },
	{ label: 'hemoptysis', isDisabled: false },
	{ label: 'pleuritic chest pain', isDisabled: false },
	{ label: 'sputum', isDisabled: false },
	{ label: 'tachypnea', isDisabled: false },

	{ label: 'Integumentary', isDisabled: true },
	{ label: 'rash', isDisabled: false },
	{ label: 'itching', isDisabled: false },
	{ label: 'edema', isDisabled: false },
	{ label: 'anasarca', isDisabled: false },
	{ label: 'blister', isDisabled: false },
	{ label: 'urticaria', isDisabled: false },
	{ label: 'paresthesia', isDisabled: false },
];


export const medicineType = [
	{id: 1, label: 'Tablet', value: 'Tablet'},
	{id: 2, label: 'Capsule', value: 'Capsule'},
	{id: 3, label: 'Lozenge', value: 'Lozenge'},
	{id: 4, label: 'Syrup', value: 'Syrup'},
	{id: 5, label: 'Drops', value: 'Drops'},
	{id: 6, label: 'Suppository', value: 'Suppository'},
	{id: 7, label: 'Ointment', value: 'Ointment'},
	{id: 8, label: 'Injection', value: 'Injection'},
	{id: 9, label: 'Powder', value: 'Powder'},
	{id: 10, label: 'Inhaler', value: 'Inhaler'},
	{id: 11, label: 'Spray', value: 'Spray'},
	{id: 12, label: 'Cream', value: 'Cream'},
	{id: 13, label: 'Gel', value: 'Gel'},
	{id: 14, label: 'Lotion', value: 'Lotion'},
	{id: 15, label: 'Mouthwash', value: 'Mouthwash'},
	{id: 16, label: 'Shampoo', value: 'Shampoo'},
	{id: 17, label: 'Solution', value: 'Solution'},
	{id: 18, label: 'Suspension', value: 'Suspension'},
	{id: 19, label: 'Sachet', value: 'Sachet'},
	{id: 20, label: 'Lipid', value: 'Lipid'},
	{id: 21, label: 'Foam', value: 'Foam'},
	{id: 22, label: 'Paste', value: 'Paste'},
	{id: 23, label: 'Skin patch', value: 'Skin patch'},
	{id: 24, label: 'Oil', value: 'Oil'},
	{id: 25, label: 'Leaves', value: 'Leaves'},
	{id: 26, label: 'Resin', value: 'Resin'},
	{id: 27, label: 'Ampoule', value: 'Ampoule'}
];

export const strengthType = [
	{id: 1, label: 'mg', value: 'mg'},
	{id: 2, label: 'mcg', value: 'mcg'},
	{id: 3, label: 'gm', value: 'gm'},
	{id: 4, label: 'units', value: 'units'},
	{id: 5, label: '%', value: '%'},
	{id: 6, label: 'ml', value: 'ml'},
	{id: 7, label: 'ounces', value: 'ounces'},
	{id: 8, label: 'teaspoon', value: 'teaspoon'},
	{id: 9, label: 'tablespoon', value: 'tablespoon'},
	{id: 10, label: 'inhaler', value: 'inhaler'},
	{id: 11, label: 'mg/hr', value: 'mg/hr'},
	{id: 12, label: 'mcg/hr', value: 'mcg/hr'}
];

export const when = [
	{id: 1, value: "Empty Stomach", label: "Empty Stomach"},
	{id: 2, value: "Before meal", label: "Before meal"},
	{id: 3, value: "After meal", label: "After meal"},
	{id: 4, value: "Before going to bed", label: "Before going to bed"},
];

export const methodOfIntake = [
	{id: 1, value: "orally", label: "orally",},
	{id: 2, value: "Injection", label: "Injection", },
	{id: 3, value: "Suppository", label: "Suppository",},
	{id: 4, value: "Apply on affected area", label: "Apply on affected area",},
	{id: 5, value: "In Eye", label: "In Eye",},
	{id: 6, value: "In Ear", label: "In Ear",},
	{id: 7, value: "Inhaled", label: "Inhaled",},
	{id: 8, value: "Intravenous", label: "Intravenous",},
	{id: 9, value: "Topical",  label: "Topical",},
	{id: 10, value: "Under tongue", label: "Under tongue",},
	{id: 11, value: "Subcutaneous", label: "Subcutaneous",},
];

export const HEALTH_CONDITION_STATUS_ENUM = ["Ongoing", "Healed"]
export const MEDICINE_FORM_ENUM = ["Tablet", "Capsule", "Lozenge", "Syrup", "Drops", "Suppository", "Ointment", "Injection", "Powder", "Inhaler", "Spray", "Cream", "Gel", "Lotion", "Mouthwash", "Shampoo", "Solution", "Suspension", "Sachet", "Lipid", "Foam", "Paste", "Skin patch", "Oil", "Leaves", "Resin", "Ampoule", "subcutaneous (S.c.)"]
export const STRENGTH_TYPE_ENUM = [ "mg", "mcg", "gm", "units", "%", "ml", "ounces", "teaspoon", "tablespoon", "inhaler", "mg/hr", "mcg/hr", "international units i.u."]
export const QUANTITY_ENUM = ["once daily", "twice daily", "when needed", "3 times daily", "4 times daily", "5 times daily", "every other day", "every 2 hours", "every 3 hours", "every 4 hours", "every 6 hours", "every 8 hours", "every 12 hours", "every hour", "every other hour", "once a week", "twice a week", "thrice a week", "4 days in a week", "5 days in a week"]