import React, {useState } from "react";
import { validExtensions } from "../../constant/staticLists";
import Dialogs from "./Dialogs";

const UploadFile = ({index, inputRefs, setImage, image, limit, setFileType, fileType, keyName, navigate }) => {
    //const [fileName, setFileName] = useState("No selected file")
    const [show, setShow] = useState("")
    const [open, setOpen] = useState(false)

  const handleImageUpload = (e, index) => {
    const files = e.target.files;
    const fileExtension = files[0].name.split(".").pop().toLowerCase();
    if (!validExtensions.includes(fileExtension)) {
      setOpen(true);
      setShow("Please select file format from pdf,png,jpeg,jpg");
    } else if (e.target.files[0].size / 1024 / 1024 > limit) {
      setOpen(true);
      setShow(
        `The size of file cannot exceed more than ${limit} MB.Please try with file of less size `
      );
    } else if (files && files[0]) {
      const updatedFileType = [...fileType];
      updatedFileType[index] = fileExtension
      setFileType(updatedFileType)
      // const newImage = URL.createObjectURL(files[0]);
      const newImage = files[0];
      setImage(prevSelectedFiles => ({
        ...prevSelectedFiles,
        [keyName]: newImage ,
      }));

    }
  };

  const handleSingleImageUpload = async(e) => {
    const files = e.target.files;
    const fileExtension = files[0].name.split(".").pop().toLowerCase();
    if (!validExtensions.includes(fileExtension)) {
      setOpen(true);
      setShow("Please select file format from pdf,png,jpeg,jpg");
    } else if (e.target.files[0].size / 1024 / 1024 > limit) {
      setOpen(true);
      setShow(
        `The size of file cannot exceed more than ${limit} MB.Please try with file of less size `
      );
    } else if (files && files[0]) {
      setFileType(fileExtension)
      // const newImage = URL.createObjectURL(files[0]);
    setImage(e.target.files[0])
    }
  }

  return (
    <>
      <input
        type="file"
        ref={(el) => (navigate === 'single' ? (inputRefs.current = el) : (inputRefs.current[index] = el))}
        hidden
        onChange={(e) => (navigate === 'single' ? handleSingleImageUpload(e) : handleImageUpload(e, index))}
      />
      {open && <Dialogs setOpen={setOpen} open={open} content={show} />}
    </>
  );
};

export default UploadFile;
