import { Box, Grid, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import strings from "../../../../constant/strings";
import HeadingComponent from "../../HeadingComponent/index";
import { subadminDashboardStyles } from "../../../../assets/styles/subadminDashboardStyle";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import {
  ContainerStyle,
  ContainerCentreStyle,
  StyledDiv,
  modalStyle,
  StyledSecondaryButton,
  LoadingContainer,
} from "../../../../assets/styles/CommonStyles";
import colors from "../../../../constant/colors";
import AddRoomForm from "../../../Modal/AddRoom";
import { AddOutlined } from "@mui/icons-material";
import * as mainService from "../../../../services/mainService";
import { useDispatch, useSelector } from "react-redux";
import { getRoomListAction } from "../../../../redux/ipd/ipdAction";

const SubadminRooms = () => {
  const dispatch = useDispatch();
  const classes = subadminDashboardStyles();
  const commonClass = commonMuiStyles();
  const [openModal, setOpenModal] = useState(false);
  const [roomForm, setRoomForm] = useState(0)
  const [selectedRoom, setSelectedRoom] = useState('');

  const {userData, roomList, loading } = useSelector((state) =>({
    userData: state.auth.userData,
    roomList: state.ipd.roomList,
    loading: state.ipd.loading
  }))

  useEffect(async()=>{
    roomList.length === 0 && dispatch(getRoomListAction(userData.token));
  },[])

  const roomIndication = [
    { color: "#2AAB6C", text: "Available" },
    { color: "#097EB7", text: "Occupied" },
    { color: "#F36363", text: "Not in service" },
  ];

  const renderColor = (type) => {
    switch (type) {
      case 0: return "#2AAB6C";
      case 1: return "#097EB7";
      case 2: return "#F36363";
    }
  };
  const handleRoom = (type,value) => {
    console.log(value)
   if(type === 1){
    setSelectedRoom(value)
   } 
    setRoomForm(type)
    setOpenModal(true)
  //  setOpenModal(true)
  }
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <HeadingComponent
            text={{
              primary: strings.roomView,
              secondary: strings.roomDescription,
            }}
          />
        </div>
        <div style={{display:'flex' , alignItems:'center'}}>
          <StyledSecondaryButton
            onClick={(e)=>handleRoom(0)}
            sx={{
              textTransform: "capitalize",
              width: "100% !important",
              fontWeight: 500,
              "& svg": {
                width: "19px",
                height: "19px",
                marginRight: "5px",
              },
            }}
          >
            <AddOutlined />
            {"Add Room"}
          </StyledSecondaryButton>
        </div>
      </div>

      <StyledDiv style={{cursor: 'pointer'}}>
        <Grid container spacing={0} className={classes.contBorderStyle} mt={2}>
          <Grid
            item
            xs={12}
            className={`${commonClass.centerStyle} ${classes.paddingStyle} ${classes.roomTypeCont}`}
          >
            {roomIndication.map((item) => (
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className={classes.roomColorInd}
                  style={{ backgroundColor: item.color }}
                ></div>
                <Typography
                  className={classes.fontText14}
                  style={{ color: colors.grey1 }}
                  mr={4}
                >
                  {item.text}
                </Typography>
              </div>
            ))}
          </Grid>
          { loading ? <LoadingContainer>{strings.loading}</LoadingContainer> : !!roomList && roomList.map((room, index) => (
            <Grid
              item
              lg={2}
              md={4}
              xs={6}
              mb={2}
              onClick={()=>handleRoom(1,room)}
            >
              <RoomView
                roomNumber={room.number}
                backgroundColor={renderColor(room.status)}
                type={room.type}
              />
            </Grid>
          ))}
        </Grid>
        <Modal
          keepMounted
          open={openModal}
          //onClose={handleClose}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box style={modalStyle}>
            <AddRoomForm setOpenModal={setOpenModal} roomForm={roomForm} selectedRoom = { selectedRoom }  />
          </Box>
        </Modal>
      </StyledDiv>
    </>
  );
};

const RoomView = ({ roomNumber, backgroundColor, type }) => {
  const classes = subadminDashboardStyles();
  const commonClass = commonMuiStyles();

  const renderType = () => {
    switch(type){
      case 0: return 'General Ward';
      case 1: return 'ICU';
    }
  }

  return (
    <>
      <ContainerCentreStyle
        className={classes.roomCont}
        style={{ backgroundColor: backgroundColor }}
      >
        <StyledDiv className={commonClass.centerStyle}>
          <img src="/assets/img/room.svg" alt="room-image" />
          <Typography className={classes.font12}>
            Room no. {roomNumber}
          </Typography>
          <Typography className={classes.font12}> {renderType()}</Typography>
        </StyledDiv>
      </ContainerCentreStyle>
    </>
  );
};

export default SubadminRooms;
