import { makeStyles } from "@mui/styles";
import colors from "../../../../../../constant/colors"

const useStyles = makeStyles(() => ({

    root: {
        "& .MuiFormControlLabel-root": {
          marginLeft: "0px",
        //   marginBottom: "16px",
        }},
        
    checkBoxContainer: {
        borderRadius: "8px", 
    },

    uncheckedCheckbox: {
        border: `2px solid ${colors.black2}`,
    },

    checkedCheckbox : {
        border: `2px solid ${colors.blue}`,
        color: `${colors.blue}`,
    },

    imageContainer: {
        height: "16px",
        weight: "16px",
        display: "flex",
        justifyContent: "flex-end"
    }

  
}));

export { useStyles };
