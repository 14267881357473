import React, { useEffect } from "react";
import strings from "../../../../constant/strings";
import { useDispatch, useSelector } from "react-redux";
import { getPatientListAction } from "../../../../redux/list/listAction";
import ListComponent from "./ListComponent";
import { Button } from "@mui/material";

const PatientLists = ({setScreen}) => {
  const dispatch = useDispatch();

  const { userData, PatientListItem } = useSelector((state) => ({
    userData: state.auth.userData,
    PatientListItem: state.organizationList.PatientList,
  }));

  useEffect(() => {
    dispatch(getPatientListAction(userData.token));
  }, []);

  return (
    <>
      <ListComponent
        listHeading={strings.patientListing}
        searchPlaceholder={strings.searchPatient}
        totals = { strings.totalPatient }
        list = {PatientListItem}
        viewText=  { strings.viewPatient }
        setScreen = {setScreen}
        navigate = 'patient'
      />
    </>
  );
};

export default PatientLists;
