import React, { useRef, useState, useEffect } from "react";
import { Grid } from "@mui/material";
import axios from "axios";
import { useStyles } from "../../../../assets/styles/RadioButtonStyles";
import {
  StyledSecondaryButton,
  UploadDocumentStyle,
  UploadStyledText,
  ButtonImage,
} from "../../../../assets/styles/CommonStyles";
import { ORGANIZATION_UPLOAD } from "../../../../services/apiUrl";
import * as mainService from "../../../../services/mainService"
const UploadDocuments = ({ handleForward, handleBackward, data, setData }) => {
  const classes = useStyles();
  const inputRefs = useRef([]);
  const [fileData, setFileData] = useState({
    imagePreviews: [],  // To store image URLs for preview
    selectedFiles: {},  // To store file keys and URLs
  });
  const [checkFile, setCheckFile] = useState(true);
const url = ORGANIZATION_UPLOAD
  const textArray = [
    "Logo",
    "Medical License",
    "Registration License",
    "Address Proof",
    "Identity Proof",
    "Other Document",
  ];

  useEffect(() => {
    checkFieldAreEmpty();
  }, [fileData.selectedFiles]);

  const handlePlusClick = (index) => {
    inputRefs.current[index].click();
  };

  const getSelectedFileKey = (index) => {
    switch (index) {
      case 0:
        return "logo";
      case 1:
        return "medical_license";
      case 2:
        return "registration_license";
      case 3:
        return "address_proof";
      case 4:
        return "identity_proof";
      case 5:
        return "other_document";
      default:
        return null;
    }
  };

  const checkFieldAreEmpty = () => {
    const requiredFields = [
      "logo",
      "medical_license",
      "registration_license",
      "address_proof",
      "identity_proof",
    ];

    const allFieldsFilled = requiredFields.every((field) =>
      fileData.selectedFiles.hasOwnProperty(field)
    );

    setCheckFile(!allFieldsFilled);
  };

  const handleImageUpload = async (e, index) => {
    const files = e.target.files;

    if (files && files[0]) {
      const fileKey = getSelectedFileKey(index);
      const imagePreviewUrl = URL.createObjectURL(files[0]);

      const formData = new FormData();
      formData.append("file", files[0]);

      try {
        const response = await mainService.fileUpload(url, formData) 

        setFileData((prevData) => ({
          imagePreviews: {
            ...prevData.imagePreviews,
            [fileKey]: imagePreviewUrl,
          },
          selectedFiles: {
            ...prevData.selectedFiles,
            [fileKey]: response.response.data,
          },
        }));

      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleDocuments = () => {
    setData((prev) => ({
      ...prev,
      ...fileData.selectedFiles,
    }));
    handleForward();
  };

  return (
    <>
      <Grid container spacing={2} marginTop={"16px"}>
        {textArray.map((textItem, index) => (
          <Grid item xs={6} key={index}>
            {fileData.imagePreviews[getSelectedFileKey(index)] ? (
              <div style={{ textAlign: "center" }}>
                <img
                  src={fileData.imagePreviews[getSelectedFileKey(index)]}
                  alt="filesName"
                  width={"188px"}
                  height={"96px"}
                />
              </div>
            ) : (
              <UploadDocumentStyle onClick={() => handlePlusClick(index)}>
                <div style={{ marginTop: 5 }}>
                  <img
                    src={"/assets/img/plus.png"}
                    alt="plus"
                    style={{ height: "32px", width: "32px", marginTop: 29 }}
                  />
                </div>
                <UploadStyledText mt={5}>{textItem}</UploadStyledText>
              </UploadDocumentStyle>
            )}
            <input
              type="file"
              placeholder={textItem}
              ref={(el) => (inputRefs.current[index] = el)}
              hidden
              onChange={(e) => handleImageUpload(e, index)}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} padding="20px 0 0 0">
        <Grid item xs={6}>
          <div className={classes.buttonContainerLeft}>
            <StyledSecondaryButton variant="contained" onClick={handleBackward}>
              <ButtonImage src={"/assets/img/back.png"} alt="backButton" />
            </StyledSecondaryButton>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.buttonContainerRight}>
            <StyledSecondaryButton
              disabled={checkFile}
              variant="contained"
              onClick={handleDocuments}
              style={{ background: checkFile === true ? "#F5F7F8" : "" }}
            >
              <ButtonImage
                src={"/assets/img/forward.png"}
                alt="forwardButton"
              />
            </StyledSecondaryButton>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default UploadDocuments;
