import { object, string, number } from "yup";
import * as Yup from 'yup';

export const addPatientSchema = object({
  name: string()
    .required("Name is required"),
  email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
  gender: string()
    .required("Please select gender"),
  bloodGroup: string()
    .required("Blood group is required"),
  phoneNumber: string()
    .required("Contact number is required"),
  address: string()
    .required("Address is required"),
});

export const addPatientIdSchema = object({
  patientId:  string()
  .required("Patient Id is required"),
})