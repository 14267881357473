export default {
    loginAs: "Login as...",
    signUp: "Signup",
    signInNow: "Sign In Now",
    dontHaveAnAccount: "Doesn't have an account?",
    haveAnAccount: "I have an account with HMS?",
    forgottenPassword: "Forgotten Password?",
    emailAddress: "Email Address",
    password: "Password",
    createAccount: "Create your account",
    createOwnAccount: "I want to create my account as....",
    proceed: "Proceed",
    fullName: "Full name",
    confirmPassword: "Confirm password",
    alreadyAccount: "Already have an account?",
    login: "Log In",
    proceed: "Proceed",
    normalUser: "Normal User",
    doctor: "Doctor",
    clinic: "Clinicians / Hospitals",
    subAdmin: "Organization Sub-Admin",
    orgName: "Clinic name, organisation and practioner",
    address: "Address",
    country: "Country",
    or: "OR",
    home: "Home",
    homeHeadingText: "All your health parameters along with real time ECG at one place",
    appointments: "Appointments",
    today: "Today",
    upcoming: "Upcoming",
    viewMore: "View More",
    healthParameter: "Health Parameters",
    viewHealthParameter: "View health parameters",
    lipidProfile: "Lipid Profile",
    signUpLogin: "Login",
    weight:'Weight(Kg)',
    height:"Height(CM)",
    bmi:"bmi",
    weeklyAnalysis:"View weekly health analysis",
    addFullLipidProfile:"Add full lipid profile",
    health: "Health",
    sugarLevel: "Enter Blood sugar(mg/dL)",
    note: "Enter any note(Optional)",
    save: "Save",
    readingType: "Select reading type",
    bloodSugar: "Blood Sugar",
    respiratoryRate: "Respiratory Rate",
    breathPerMin: "Enter Breaths per min",
    heartRate: "Heart Rate",
    pulsePerMin: "Enter Pulse per min",
    bodyPart: "Select body part",
    bloodOxygen: "Blood Oxygen",
    readingMethod: "Select reading method",
    spo2Per: "Enter SPO2 percentage",
    bodyTemp: "Body Temperature",
    enterTemp: "Enter body temperature",
    selectScale: "Select scale",
    bloodPressure: "Blood Pressure",
    systolic: "Enter Systolic (mmHg)",
    diastolic: "Enter Diastolic (mmHg)",
    triglycerides: "Enter Triglycerides",
    cholesterol: "Enter Cholestrol",
    ldlc: "Enter LDL_C",
    vldl: "Enter VLDL",
    hdlc: "Enter HDL-C",
    appointments: 'Appointments',
    appointmentsSecondary: 'An aggregated view of all your scheduled & previous appointments.',
    healthConditions: 'Health Conditions',
    medications: 'Medications',
    surguries: 'Surguries',
    allergies: 'Allergies',
    vaccianations: 'Vaccinations',
    enterDignosis: 'Enter Diagnosis',
    treatedBy: 'Condition Treated By ?',
    homeHeadingText1: "All of your Paitents IPD and OPD View in one place",
    opd: "Out Paitent Department (OPD)",
    patientListing: "Patient Listing",
    ipd: "In Patient Department (IPD)",
    upgradeToPlan: "Upgrade to the full plan",
    addPatient: "Add Patient",
    manually : "Manually",
    patientId: "Patient ID",
    patientFullName: "Enter patient's name ",
    patientEmail: "Enter patient email",
    selectGender: "Select gender",
    bloodGroup: "Select blood group",
    contactNumber: "Enter contact number",
    enterCity: "Enter City",
    occupation: "Enter occupation",
    patientFullAddress: "Enter patient full address",
    add: "Add",
    enterPatientId: "Enter Patient ID",
    continue: "Continue",
    congratulations: "Congratulations",
    selectDepartment: "Select Department",
    selectBoth:"(you can select both)",
    selectRange: "Select practictioner range",
    completed: "Completed",
    enterPatientId: "Enter Patient ID",
    appointmentsOpd: "Appointments OPD",
    appointmentOpdHeading: "All scheduled appointments with day & weekly overview",
    generateQRinfo: "This will convert the selected features into a QR code for easy sharing & QR will be valid till selected time interval.",
    acknowledgement: "Yes I acknowledge to share my details with Emed4u.",
    billingAndInvoice: "Billing & Invoice",
    billingContent: "All invoice and billing data in one place",
    bookAppointment: "Book an Appointment",
    appointmentDetails: "Appointment details",
    patientInputs: "Patient Inputs",
    appointmentCheckout: "Appointment checkout",
    doctorList: 'Doctor\'s List',
    age: 'Enter age',
    Enterweight: 'Enter weight ',
    schedule: "Schedule",
    scheduleHeading: "All scheduled appointments with day & weekly overview",
    onlineAppointment: "Online Appointments",
    inclinicAppointment: "In Clinic Appointments",
    createSlot: "Create a Slot",
    searchByDoctorName: "Search by doctor name, specialisation",
    searchByPatientName: "Search by  patient name & ID",
    patientInformation: "Patient Information",
    doctorInformation: "Doctor Information",
    subadminInformation: "Sub-Adminstration Information",
    message: "Messages",
    medicalHistory: "Medical History",
    reportsPrescription: "Reports & E-Prescription",
    previousAppointment: "Previous Appointments",
    hideDetail: "Hide Details",
    viewDetail: "View Details",
    personalInformation: "Previous Appointments with medication",
    prevReports: "Previous Reports",
    prevPrescription: "Previous E-Prescription",
    noReportsAvailable: "No Reports available",
    noPrescriptionAvailable: "No Prescription available",
    showMore: "Show More",
    showLess: "Show Less",
    call: "Call",
    loading: "Loading......",
    sendMedication: "Send Medication",
    diagnosisAndObeservation : "Diagnosis and observation",
    enterDiagnosisObservation: "Enter Diagnosis and observation",
    formOfMedication: " Select form of medication",
    enterMedicationName: "Enter medication name",
    enterMedicineStrength: "Enter medicine Strength",
    enterStrengthType: "Enter Strength type",
    selectDosageIntake: "Select dosage quantity intake",
    addMoreModication: "Add More Medication",
    otherInstruction: "Other Instructions",
    enterNoteToPatient: "Enter note to patient eg: take medicine 30 mins before meal.",
    settlePayment: "Settle payment (if any pending amount)",
    enterPendingPayment: "Enter any pending payment left from patient side",
    sendFinalPayment: "Send final report to patient",
    notNow: "Not now",
    addMoreMedication: "Add more medication",
    cancel: "Cancel",
    noAppointmentAvailable: "No appointments available",
    npPrescriptionAvailable: "No prescription available",
    noInvoiceAvailable: "No invoice available",
    uploadFile: "Upload File ( File size must be less than 10mb ) ",
    bookAgain: "Book again",
    uploadFile: "Upload File",
    doctorsProfile: "Doctor’s Profile",
    doctorName: "Enter Doctor Name",
    selectAddress: "Enter Address",
    enterCurrentPassword: "Enter current password",
    enterNewPassword: "Enter new password",
    enterConfirmPassword: "Enter confirm password",
    opdIpdList: 'All patient into IPD and OPD will be here at one place',
    searchByPatNameDocName: 'Search by  patient name  , Doctor name ',
    backToAppointment: 'Back to Appointment List',
    opdList: 'All scheduled appointments with day & weekly overview',
    appointmentsIPD: 'Appointments IPD',
    sendActivationLink : 'Send Activation Link',
    appointmentSummary: 'Appointment Booking Summary',
    patientEmergencyContact: "Patient's Emergency Contact",
    patient: 'Patient',
    totalPatient: 'Total Patients: ',
    doctorIpdOpd: 'All doctors into IPD and OPD will be here at one place',
    addDoctor: 'Add Doctor',
    totalDoctor: 'Total Doctors: ',
    subAdmin: 'Sub-Administration',
    subAdminIpdOpd: 'All sub-admin into IPD and OPD will be here at one place',
    addSubAdmin: 'Add Sub-Administrator',
    totalSubAdmin: 'Total sub-admin',
    searchBySubAdmin: 'Search by name ',
    selectPermission: 'Select Permission',
    selectStatus: 'Select Status',
    doctorFullname: 'Enter Doctor full name',
    doctorEmail: 'Enter Doctor email',
    selectExp: 'Enter Experience',
    selectDegree: 'Select Degree',
    selectLanguage: 'Select Language',
    enterFullname: 'Enter full name',
    selectDesignation: 'Select Designation',
    noList : 'No list available',
    orgProfile: 'Organisation’s Profile',
    orgName: 'Enter organization name',
    ownerName: 'Enter Owner name',
    subadminProfile: "Sub-admin Profile",
    enterSubadminName: "Enter sub-admin's name",
    subadminEmail: "Enter sub-admin's email" ,
    searchPatient: "Search by Patient’s name, Id",
    searchDoc: "Search by Doctor’s name, Id",
    viewPatient: 'View Patient’s list',
    viewDoctor : 'View Doctor’s list',
    roomView : 'Room View',
    roomDescription: 'All rooms availability with day and week overview',
    backToIpd: 'Back to Patient IPD list',
    serviceList: 'Service Listing',
    prevIPD: 'Previous Ipd',
    dateOfObservation: 'Date of observation',
    patientDetails: 'Patient Details',
    editRoom: 'Edit room',
    addRoom: 'Add room',
    roomNumber: 'Room Number',
    selectType: 'Select room type',
    availability: 'Availability',
    done: 'Done',
    diagonosis: 'Provisional Diagnosis',
    illness: 'Present illness summary...',
    duration: 'Duration',
    comment: 'Comment',
    personalInfo: "Personal Information",
    patientMedical: 'Patient Medical Details',
    patientMedHistory: 'Patient Medical History',   
    selectTime: 'Select time interval',
    selectDesignation: 'Select Designation',
    patientUniqueId:"Patient's UniqueId"
}