import React from "react";
import Tables from "../../controls/Tables";
import { prevIPD } from "../../../constant/staticLists";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";

export const prevIPDData = [
  { name: "Dr. Doctor 1", duration: "21 Nov 2022 - 31 Nov 2022" },
  { name: "Dr. Doctor 2", duration: "21 Dec 2022 - 31 Jan 2023" },
  { name: "Dr. Doctor 3", duration: "21 Jan 2023 - 28 Feb 2023" },
  { name: "Dr. Doctor 1", duration: "21 Feb 2023 - 31 March 2023" },
];
function PrevIPD() {
  const classes = commonMuiStyles();
  return (
    <>
      <div className={classes.scrollVertically} style={{ height: "220px" }}>
        <Tables
          fieldsOrder={prevIPD}
          data={prevIPDData}
          navigate="scrollable"
        />
      </div>
    </>
  );
}

export default PrevIPD;
