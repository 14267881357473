import { Grid } from "@mui/material";
import React from "react";
import OrganizationProfileSetting from "../../components/ui/Organisation/ProfileSetting";

const OrganisationProfileSetting = () => {
  return (
    <Grid container spacing={0} >
    <OrganizationProfileSetting />
    </Grid>
  )
}

export default OrganisationProfileSetting;