import { makeStyles } from "@mui/styles";
import colors from "../../constant/colors";
import fontWeights from "../../constant/fontWeights";

const subadminDashboardStyles = makeStyles(() => ({
  contBorderStyle: {
    borderRadius: "8px",
    border: " 1.2px solid var(--grey-2, #F5F5F7)",
    background: "#FFF",
    boxShadow:
      "4px 4px 15px 0px rgba(192, 192, 192, 0.25), -4px -4px 15px 0px rgba(192, 192, 192, 0.25)",
  },
  contStyle: {
    height: "322px",
  },

  roomColorInd: {
    height: "16px",
    width: "16px",
    borderRadius: "100%",
    marginRight: "8px",
  },

  roomCont: {
    borderRadius: "8px",
    margin: "0px 10px",
    padding: "10px 0px 5px",
  },

  paddingStyle: {
    padding: "25px",
  },

  font12: {
    color: "#FFF",
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "20px" /* 133.333% */,
  },
  roomTypeCont: {
    display: "flex",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },

  gradientFontColor: {
    background: colors.textColor,
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent",
  },
  textBox: {
    padding: '10px 40px'
  }
}));

export { subadminDashboardStyles };
