import { Box, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import strings from "../../../constant/strings";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import colors from "../../../constant/colors";
import { subadminDashboardStyles } from "../../../assets/styles/subadminDashboardStyle";
import PatientDetails from "./PatientDetails";
import {
  StyledViewMoreButton,
  modalStyle,
} from "../../../assets/styles/CommonStyles";
import DateOfObservation from "./DateOfObservation";
import Diagnosis from "./Diagnosis";
import ServiceListing from "./ServiceListing";
import PrevAppointment from "./PrevAppointment";
import PrevIPD from "./PrevIPD";
import Report from "./Report";
import PatientInfo from "../../Modal/PatientInfo";
import AddService from "../../Modal/AddService";
import AddMedication from "../../Modal/AddMedication";

function IPDPatientDetails({ handleBackward, selectedListItem, setSelectedListItem }) {
  const classes = commonMuiStyles();
  const dashboardClasses = subadminDashboardStyles();
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState("");

  const IPDComponent = [
    {
      name: strings.patientDetails,
      component: <PatientDetails selectedListItem = {selectedListItem} />,
      buttonText: "View more patient details",
    },
    { name: strings.dateOfObservation, component: <DateOfObservation selectedListItem = {selectedListItem} /> },
    { name: strings.diagnosisAndObeservation, component: <Diagnosis /> },
    {
      name: strings.serviceList,
      component: <ServiceListing selectedListItem = {selectedListItem}  />,
      buttonText: "Add services",
    },
    { name: strings.previousAppointment, component: <PrevAppointment /> },
    { name: strings.prevIPD, component: <PrevIPD /> },
  ];

  const handleOpen = (index) => {
    setModalType(index);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Grid container spacing={3} mb={4}>
        <Grid
          item
          xs={12}
          className={`${classes.fontText14} ${dashboardClasses.gradientFontColor}`}
          mt={2}
          onClick={handleBackward}
          sx={{ cursor: "pointer" }}
        >
          {strings.backToIpd}
        </Grid>
        {IPDComponent.map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div
              className={classes.fontText14}
              style={{ paddingBottom: "10px" }}
            >
              {item.name}
            </div>
            <div
              className={dashboardClasses.contBorderStyle}
              style={{
                minHeight: index === 0 || index === 3? "187px" : "235px",
              }}
            >
              {item.component}
            </div>
            {(index === 0 || index === 3) && (
              <StyledViewMoreButton onClick={() => handleOpen(index)}>
                {!!item && item?.buttonText}
              </StyledViewMoreButton>
            )}
          </Grid>
        ))}
        <Grid item xs={12}>
          <div
            className={dashboardClasses.contBorderStyle}
            style={{ minHeight: "230px" }}
          >
            <Report setOpenModal={setOpenModal} handleOpen={handleOpen} setSelectedListItem = { setSelectedListItem } />
          </div>
        </Grid>
      </Grid>
      <Modal
        keepMounted
        open={openModal}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box style={modalStyle}>
          {modalType === 0 ? (
            <PatientInfo setOpenModal={setOpenModal} />
          ) : modalType === 3 ?(
            <AddService setOpenModal={setOpenModal} id = {selectedListItem._id}  />
          ) : <AddMedication setOpenModal={setOpenModal} selectedListItem = {selectedListItem} />}
        </Box>
      </Modal>
    </>
  );
}

export default IPDPatientDetails;
