import React, { useEffect, useState,useLayoutEffect } from "react";
import EmployeeListUI from "../EmployeeListUI";
import strings from "../../../../constant/strings";
import AddPatient from "../../../Modal/AddPatient";
import { modalStyle } from "../../../../assets/styles/CommonStyles";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorListAction, getPatientListAction } from "../../../../redux/list/listAction";
import { DoctorListFields, PatientListFields } from "../../../../constant/staticLists";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import IPDPatientDetails from "../../IPDPatientDetails.js";
import PatientInfo from "../../../Modal/PatientInfo";
import DoctorInfo from "../../../Modal/DoctorInfo";
import AddDoctorForm from "../../../Modal/AddDoctor";
function DoctorList({ navigate}) {
  const [openModal, setOpenModal] = useState(false);
  const [openModala, setOpenModala] = useState(false);
  const [selectedListItems, setSelectedListItems] = useState();
  const [list, setList] = useState([]);
  const history = useHistory()
  const [screen, setScreen] = useState(1)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { DoctorList, userData } = useSelector((state) => ({
    userData: state.auth.userData,
    DoctorList: state.organizationList.DoctorList,
  }));
  useEffect(() => {
    if (navigate !== "subadmin")  dispatch(getDoctorListAction(userData.token));
  }, []);

  useEffect(() => {
    setList(DoctorList);
  }, [DoctorList]);
  useEffect(() => {
    if (loading) {
      // Re-fetch the doctor list when loading changes to true
      dispatch(getDoctorListAction(userData.token));
      setLoading(false); // Reset loading after fetching data
    }
  }, [loading, dispatch, userData.token]);
  const handleClose = () => {
    
    setOpenModala(false);
  };
  const handleForward = () => {
    setScreen((prev)=> prev+1)
    setOpenModala(true)
    
  }
  const handleBackward = () => {
    setScreen((prev)=> prev-1)
  }
  const setSelectedListItem=(item)=>{
    
    setSelectedListItems(item._id)
   
  }
  console.log("setOpenModal",openModala)
  const handlemodle=()=>{
   
    setOpenModal(!openModal)
  }
  return (
    <>
    {
      screen ===0 ?
      history.push("/organization/dashboard"):
      // alert("hello")
      screen === 1?
      <>
      <EmployeeListUI
      primaryText={strings.doctor}
      secondaryText={strings.doctorIpdOpd}
      addButton={strings.addDoctor}
      searchPlaceholderText={strings.searchByDoctorName}
      total={strings.totalDoctor}
      setOpenModal={setOpenModal}
      openModal={openModal}
      data={DoctorList}
      fieldsOrder={DoctorListFields}
      list={list}
      setList={setList}
      setScreen = {setScreen}
      handleForward = {handleForward}
      setSelectedListItem={setSelectedListItem}
      handleBackward={handleBackward}
    />

    <Modal
      keepMounted
      open={openModal}
      scroll="paper"
      //onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box style={modalStyle}>
        <AddDoctorForm setOpenModal={setOpenModal} openModal={openModal} />
      </Box>
    </Modal> </>:
    <>

     <Modal
     keepMounted
     open={handlemodle}
     onClose={handleClose}
     aria-labelledby="keep-mounted-modal-title"
     aria-describedby="keep-mounted-modal-description"
   >
     <Box style={modalStyle}>
       
         <DoctorInfo setOpenModal={setOpenModala}  setScreen={setScreen} id = {selectedListItems} setLoading={setLoading}/>
       
     </Box>
   </Modal></>
    }
     
    </>
  );
}

export default DoctorList;
