import React from "react";
import { useState } from "react";
import {
  StyledCrossIcon,
  StyledDivider,
  StyledImageCont,
  StyledMargin,
  StyledText,
  StyledMainModalCont,
  StyledDiv,
} from "../../../../assets/styles/CommonStyles";
import LogoIcon from "../../../Common/LogoIcon";
import strings from "../../../../constant/strings";
import { useStyles } from "../../../../assets/styles/RadioButtonStyles";
import DoctorSignUpSteps from "./commonComponents/DoctorSignUpSteps";
import "../NormalUser/style.css";
import OrganizationForm from "./OrganizationForm";
import UploadDocuments from "./UploadDocuments";
import HospitalDetails from "./HospitalDetails";
import Verification from "./Verification";

const Doctor = ({
  isSignUpModal,
  setSignUpModal,
  toggleSignUp,
  setLoginToggle,
  loginToggle
}) => {
  const classes = useStyles();
  const [data, setData] = useState();

  let initialValues = {
    org_name: "",
    name: "",
    phone_number: "",
    email: "",
    country_code: "+91",
    address: "",
    country: "IN",
  };
  const [step, setStep] = useState(0);
  const [stepImage, setStepImage] = useState({first: 'halfFilled', second: 'halfFilled', third: 'halfFilled'})

  const handleForward = () => {
    setStep(step+1)
    handleStepImage(step+1)
  }
  const handleBackward = () => {
    setStep(step-1)
    handleStepImage(step-1)
  }
  const handleStepImage = ( item ) => {
    switch(item){
      case 1 : setStepImage((prev) => ({...prev, first: "fullFilled", second: 'halfFilled'})); 
      break;
      case 2 : setStepImage((prev) => ({...prev, second: 'fullFilled', third: 'halfFilled'})); 
      break;
      case 3 : setStepImage((prev) => ({...prev, third: 'fullFilled'   })); 
      break;
    }
  }

  return isSignUpModal ? (
    <StyledMainModalCont visible={isSignUpModal}>
      <StyledImageCont>
        <StyledCrossIcon
          src={"/assets/img/x.png"}
          alt="User"
          onClick={() => setSignUpModal(false)}
        />
      </StyledImageCont>
      <LogoIcon />

      <DoctorSignUpSteps
        image1 = {`/assets/img/${stepImage.first}Img1.png`}
        image2 = {`/assets/img/${stepImage.second}Img2.png`}
        image3 = {`/assets/img/${stepImage.third}Img3.png`}
        lineStyle={step ===0 ?[0, 0, 0] : step===1 ? [1, 0, 0] : step===2 ? [1, 1, 0] : ''}
      />
      <StyledDiv>
        { step === 0 ? <OrganizationForm
          initialValues={initialValues}
          handleForward = {handleForward}
          data = {data}
          setData = {setData}
          
        /> : step ===1 ? <HospitalDetails         
        handleForward = {handleForward}
        handleBackward = {handleBackward}
        data = {data}
        setData = {setData} />
         : 
         step === 2 ? <UploadDocuments
         handleForward = {handleForward}
         handleBackward = {handleBackward}
         data = {data}
         setData = {setData}
          /> : 
         step === 3 ? <Verification data={data}
         setLoginToggle={setLoginToggle}
         loginToggle={loginToggle}
         setSignUpModal = {setSignUpModal} /> : ''}

        <StyledDivider>{strings.or}</StyledDivider>
        <StyledMargin>
          <StyledText>
            {strings.alreadyAccount}{" "}
            <span className="sign-color" onClick={toggleSignUp}>
              {strings.login}
            </span>
          </StyledText>
        </StyledMargin>
      </StyledDiv>
    </StyledMainModalCont>
  ) : ''
};

export default Doctor;
