import { Autocomplete, Grid, Select } from "@mui/material";
import styled from "styled-components";
import colors from "../../constant/colors";

export const PatientInputGrid = styled(Grid)(() => ({
    "& input": {
        boxSizing: "border-box",
        background: "#F8F7F7",
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        lineHeight: "24px",
        height: 48,
        color: "#2B2B2B",
        border: "none",
        borderRadius: "8px",
        "& + .MuiInputAdornment-root": {
            position: "absolute",
            right: 20,
            "& .MuiTypography-root": {
                color: "color(srgb 0.6361 0.6312 0.6314)",
                paddingRight: "6px",
                fontSize: "14px",
                fontFamily: "Poppins",
                fontStyle: "normal",
            },
        },
    },
    "& .MuiFormControl-root > label": {
        color: "#CCCCCC",
        fontFamily: "Poppins",
        fontStyle: "normal",
        marginTop: "-3px",
    },
    "& .MuiFormControl-root > .MuiInputLabel-shrink": {
        marginTop: 0,
    },
    "& fieldset": {
        border: "1px solid #CCCCCC",
        borderColor: "#CCCCCC !important",
        borderRadius: "8px",
    },
    "& .gender-select-box": {
        background: "#F8F7F7",
        height: 48,
    },
    [`& input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button`]: {
        "-webkit-appearance": "none",
        margin: 0,
    },
    '& input[type="number"]': {
        "-moz-appearance": "textfield",
    },
}));

export const UploadFileGrid = styled(Grid)(() => ({
    cursor: 'pointer',
    "& input": {
        opacity: 0,
        zIndex: -1,
    },
    padding: 0,
    height: 200,
    display: "inline-block",
    border: `2px dashed ${colors.textColor}`,
    background: colors.lightBackground,
    borderRadius: "8px",
    boxShadow: "border-box",
    width: "100%",
    "& > .MuiBox-root": {
        height: 200,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
       // background: colors.lightBackground,
        "& > .MuiBox-root": {
            width: 48,
            height: 48,
            background:
                "linear-gradient(90deg, rgba(9, 126, 183, 0.2) 0%, rgba(42, 171, 108, 0.2) 100%)",
            borderRadius: 48,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 16,
            "& > .MuiSvgIcon-root": {
                fill: "url(#header-shape-gradient)",
                margin: "auto",
                height: 24,
                width: 24,
            },
        },
        "& .MuiTypography-root": {
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "18px",
            textTransform: "capitalize",
            background: colors.textColor,
            "-webkit-background-clip": "text",
            backgroundClip: "text",
            "-webkit-text-fill-color": "#5E5E5E",
            textFillColor: "#5E5E5E",
        },
        "& .filetypes": {
            textTransform: "lowercase",
            fontWeight: 400,
            fontSize: 14,
            lineHeight: "18px",
            marginBottom: -30,
        },
    },
}));

export const FileListsStyledGrid = styled(Grid)(() => ({
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    letterSpacing: "0em",
    textSlign: "left",
    "& .svg": {
        height: "24px",
        width: "24px",
    },
}));

export const StyledGenderSelect = styled(Select)(() => ({
    background: '#F8F7F7',
    height: '48px',
}));

export const StyledAutocomplete = styled(Autocomplete)`
    .MuiInputBase-input {
      background-color: transparent;
    },
    .MuiFormLabel-root {
      padding-top: 6px;
    }
  `;