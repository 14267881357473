import { Button, Grid } from "@mui/material";
import React from "react";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";
import strings from "../../../../constant/strings";
import HeadingComponent from "../../HeadingComponent";
import TabComponent from "../../../Tab";
import Tables from "../../../controls/Tables";
import { orgIPDAppointmentList, orgOPDAppointmentList, tabs, tabsIPD } from "../../../../constant/staticLists";
import SearchFields from "../../../../pages/PatientPanel/PatientAppointments/SearchFields";
import { useState } from "react";
import { useSelector } from "react-redux";

function IpdAppointmentList ({ step, setAppointmentListType }){
  const classes = useStyles();
  const styleClasses = commonMuiStyles();
  const [tabValue, setTabValue] = useState(0);


  const { userData, doctorAppointmentList } = useSelector((state) => ({
    userData: state.auth.userData,
    doctorAppointmentList: state.doctorAppointment.doctorAppointmentList,
  }));
  return (
    <>
      <Grid container spacing={2}>
      {/* <button onClick={()=>setAppointmentListType(2)}>IDp</button> */}
        <Grid item xs={12}>
          <HeadingComponent
            navigate="organisation"
            text={{
              opd: strings.ipd,
            }}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <TabComponent
            tabs={tabsIPD}
            tabValue={tabValue}
            setTabValue={setTabValue}
            //handleTab={handleTab}
            customTab={styleClasses.customTab}
            customTabIndicator={styleClasses.customTabIndicator}
          />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          { step === 1 ? <SearchFields
            pr={"16px"}
            //handleValue={handleValue}
            placeholder={strings.searchByPatNameDocName}
          /> : ''}
        </Grid>
        <Grid item xs={12}>
          <Tables
            data={doctorAppointmentList}
            fieldsOrder={orgIPDAppointmentList}
            //   step={step}
            //   setStep={setStep}
            //handleForward={handleForward}
            //   setSelectedListItem={setSelectedListItem}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default IpdAppointmentList;