import { Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HeadingComponent from "../../HeadingComponent";
import strings from "../../../../constant/strings";
import { useStyles } from "../../../../assets/styles/PatientDashboardStyles";
import TabComponent from "../../../Tab";
import { orgAppointmentList, tabs } from "../../../../constant/staticLists";
import { commonMuiStyles } from "../../../../assets/styles/CommonMUIStyles";

import AppointmentDetails from "./AppointmentDetails";
import colors from "../../../../constant/colors";
import AppointmentList from "./AppointmentList";
import AppointmentBookingSummary from "./AppointmentBookingSummary";


const Home = () => {
  const classes = useStyles();
  const styleClasses = commonMuiStyles();
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [appointmentList, setAppointmentList] = useState(0);
  const [selectedListItem, setSelectedListItem] = useState("");
  const { userData, doctorAppointmentList } = useSelector((state) => ({
    userData: state.auth.userData,
    doctorAppointmentList: state.doctorAppointment.doctorAppointmentList,
}))

  const handleForward = () => {
    setStep(step + 1);
  }

  const handleBackward = () => {
    setStep(step - 1);
  }

  return (
    <>
    {step === 0 ? (
        <AppointmentList
          step={step}
          setStep={setStep}
          handleForward={handleForward}
          setSelectedListItem={setSelectedListItem}
          selectedListItem = {selectedListItem}
        />
      ) : step === 1 ? (
        <AppointmentBookingSummary
          step={step}
          setStep={setStep}
          handleForward={handleForward}
          handleBackward={handleBackward}
          selectedListItem={selectedListItem}
        />
      ) : (
        <AppointmentDetails
          step={step}
          setStep={setStep}
          handleForward={handleForward}
          handleBackward={handleBackward}
          selectedListItem= {selectedListItem}
          patientID={selectedListItem.patientInfo._id}
        />
      )}
      
    </>
  );
};

export default Home;
