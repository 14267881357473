import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { commonMuiStyles } from "../../assets/styles/CommonMUIStyles";
import colors from "../../constant/colors";
import {
  StyledCrossIcon,
  StyledSecondaryButton,
  StyledSlotDivider,
} from "../../assets/styles/CommonStyles";
import { CircularProgress, Grid, TextField, Typography } from "@mui/material";
import strings from "../../constant/strings";
import { Formik } from "formik";
import FieldWrapper from "../InputField/TextFieldWrapper";
import { addServiceSchema } from "../../utils/forms/schema.addService";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers";
import { IPDdashoboardUseStyles } from "../../assets/styles/IPDDashboard";
import { useStyles } from "../../assets/styles/BloodSugarStyles";
import { useDispatch, useSelector } from "react-redux";
import { getCurrencyAction } from "../../redux/public/publicAction";
import SelectionDropdown from "../control/SelectionDropdown";
import { medicationStyles } from "../../assets/styles/MedicationStyles";
import * as mainService from '../../services/mainService';
import { toast } from "react-toastify";
import { getAdmitedPatientServiceAction } from "../../redux/ipd/ipdAction";

function AddService({ setOpenModal , id}) {
  const dispatch = useDispatch();
  const classes = commonMuiStyles();
  const medicationClasses = medicationStyles()
  const [onLoading, setOnLoading] = useState(false);
  const patientID = '65e6d113e06202ea2cb5b9b5';
  let initialValues = { name: "", date: "", time: "", amount: "", currency: "" };

  const { currencies, userData } = useSelector((state) => ({
    currencies: state.public.currencies,
    userData: state.auth.userData
  }));

  useEffect(async () => {
  currencies?.length === 0 && dispatch(getCurrencyAction());
  }, []);

  const handleSubmit = async(values, { resetForm }) => {
    const newDate = `${values.date} ${values.time}`;   
    delete values.time;
    delete values.date;
    const newValues = {admitPatientId: patientID, date: newDate, timezone: "Asia/Calcutta", ...values}
    setOnLoading(true);
    const res = await mainService.addPatientServiceApi(newValues, userData.token);
    if (res.response_type === "fail") {
      setOnLoading(false);
      toast.error(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
    } else {
      resetForm();
      setOnLoading(false);
      setOpenModal(false);
      toast.success(res?.response?.message, {
        position: "bottom-center",
        closeButton: false,
        autoClose: 2000,
        style: { width: "auto", borderRadius: 8 },
      });
      dispatch(getAdmitedPatientServiceAction({admitPatientId: id , token: userData.token }))
    }
  };
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={0}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Typography className={classes.fontText24} color={colors.grey1}>
              {strings.serviceList}
            </Typography>
            <StyledCrossIcon
              src={"/assets/img/x.png"}
              alt="cross"
              onClick={() => setOpenModal(false)}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledSlotDivider />
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={addServiceSchema}
              onSubmit={handleSubmit}
            >
              {({
                handleSubmit,
                errors,
                values,
                setFieldValue,
                setTouched,
                touched,
                resetForm,
              }) => {
                const handleDate = (value, field) => {
                  if (field === "date") {
                    let formattedDate = moment(value.$d).format("YYYY-MM-DD");
                    setFieldValue("date", formattedDate);
                  } else {
                    let formattedTime = moment(value.$d).format("HH:mm:ss");
                    setFieldValue("time", formattedTime);
                  }
                };

                const handleDropdownValue = (value, fieldName) => {
                  if(value){
                    setFieldValue(fieldName, value.level)
                  }
                }

                return (
                  <>
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12}>
                        <FieldWrapper
                          name="name"
                          type="text"
                          label="Enter Service name"
                        ></FieldWrapper>
                      </Grid>
                      {console.log("values", values)}
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.styleDatePicker}
                      >
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <DemoItem>
                            <DesktopDatePicker
                              name="date"
                              type="text"
                              label="Select service Date"
                              disableFuture
                              onChange={(value) => handleDate(value, "date")}
                              // defaultValue={dayjs(moment(new Date).format('YYYY-MM-DD'))}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        className={classes.styleDatePicker}
                      >
                        <DemoContainer components={["DesktopDatePicker"]}>
                          <DemoItem>
                            <TimePicker
                              label="Select service time"
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                              onChange={(value) => handleDate(value, "time")}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <SelectionDropdown
                          name="currency"
                          height={"36px"}
                          className={medicationClasses.customAutocomplete}
                          label={`Select currency type`}
                          handleDropdownValue={(data) =>
                            handleDropdownValue(data, "currency")
                          }
                          list={currencies}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <FieldWrapper
                          name="amount"
                          type="number"
                          label="Enter Service amount"
                        ></FieldWrapper>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        alignItems="center"
                        display="flex"
                        justifyContent={"center"}
                      >
                        <StyledSecondaryButton
                          name="submit"
                          disabled={Object.keys(errors).length > 0 || onLoading}
                          onClick={handleSubmit}
                          variant="contained"
                        >
                          {onLoading ? (
                            <CircularProgress
                              style={{ color: "white" }}
                              size={20}
                              thickness={5}
                            />
                          ) : (
                            "Save"
                          )}
                        </StyledSecondaryButton>
                      </Grid>
                    </Grid>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </>
  );
}

export default AddService;
