import {object, string, number, array} from 'yup';

export const addDoctorSchema = object({
    name : string()
    .required('Doctor name is required'),
    email: string()
    .email("Please enter a valid email address")
    .matches(
      /^[a-zA-Z0-9+_.-]+@[_a-zA-Z0-9-]+(?:\.[_a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/i,
      "Invalid email"
    )
    .required("Email is required"),
    phone_number: number()
    .positive("The number must be positive")
    .integer("The number must be an integer")
    .typeError("Please enter a valid phone number")
    .min(10, "Minimum 10 characters required")
    .required("Mobile Number is required"),
    department: string()
    .required('Please select department'),
    permission: string()
    .required('Please select permission'),
    status: string()
    .required('Please select status'),
    degrees: array().nullable()
    .min(1, 'Atleast one degree is required')
    .required('Select degree'),
    languages: array().nullable()
    .min(1, 'Atleast one language is required')
    .required('Language is required'),
    experience: number()
    .positive("Experience must be positive number")
    .integer("Experience must be an integer")
    .typeError("Please enter valid experience")
    .required("Experience is required"),
    address: string()
    .min(10, 'Characters cannot be less than 10')
    .required('Address is required'),    
})