import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  IconButton,
  Accordion,
  AccordionDetails,
  Pagination,
  Grid,
  TableCell,
} from '@mui/material';
import { SimCardDownloadOutlined, DeleteOutline } from '@mui/icons-material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ButtonStyle, StyledTableCell, StyledTypeBox, ContainerStyle, ButtonStyleContainer, IpdActionCell } from '../../assets/styles/CommonStyles';
import { useStyles } from '../../assets/styles/TableStyles';
import { appointmentTabList, CURRENCIES, getBackgroundColor, getStatusText } from '../../constant/staticLists';
import { appointmentStyles } from '../../assets/styles/DoctorAppointmentStyles';
import TabComponent from '../Tab';
import "../../assets/styles/expendibleStyles.css";
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import strings from '../../constant/strings';
import defaultUserPng from "../../assets/img/user.png";
import { commonMuiStyles } from '../../assets/styles/CommonMUIStyles';
import { Button } from '@mui/base';
import colors from '../../constant/colors';
import { renderGender, renderPermission } from '../../utils/GenericFunctions';

const isTimeBetween = (startTime, endTime) => {
  const currentTime = moment.utc().format("HH:mm:ss.SSS");
  const startUTC = moment.utc(startTime, "HH:mm:ss.SSS").format("HH:mm:ss.SSS");
  const endUTC = moment.utc(endTime, "HH:mm:ss.SSS").format("HH:mm:ss.SSS");
  return currentTime >= startUTC && currentTime <= endUTC;
};

export const types = {
  1: 'Prescription',
  2: 'Report',
  3: 'Letter',
}

const Tables = ({ data, fieldsOrder, tabValue, setTabValue, handleTab, selectedItem, children, onmedicationClick, openModal, handleCancleAppointment, appointmentDecision, navigate, handleDeleteReports, list, page, setPage, handleBookAgain, handleForward, setSelectedListItem }) => {
  const history = useHistory();

  const [expandedRow, setExpandedRow] = useState(null);
  const classes = useStyles();
  const appointmentClasses = appointmentStyles();
  const styleClasses = commonMuiStyles()
  const [curr,setCurr]= useState()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const getCodeByEnum = (enumValue) => {
        
    const currency = CURRENCIES.find((CURRENCIES) => CURRENCIES.enum === enumValue);
   
    return  currency?currency.symbol:null; // Return null if not found
  };
  useEffect(()=>{
    setCurr(getCodeByEnum(data?.currency))
  },[])


  const handleExpand = (item, index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
      selectedItem(item);
    }
  };

  const renderTime = (item) => {
    const availableTimeSlot1 = moment(item?.start_time).format('hh:mm A')
    const availableTimeSlot2 = moment(item?.end_time).add(1, 'seconds').format('h:mm A')
    return (`${availableTimeSlot1} - ${availableTimeSlot2}`)
  }

  const onClickButton = () => history.push("/doctor/messages");

  const onMedicationClick = (item) => onmedicationClick(openModal, item);

  const buttonCell = (item) => {
    const startTime = item?.start_time?.split("T")[1];
    const endTime = item?.end_time?.split("T")[1];
    const isBetween = isTimeBetween(startTime, endTime);
    return <div className={!isBetween ? appointmentClasses.disabledTextStyle : classes.textStyle}
      disabled={!isBetween} onClick={() => isBetween ? onClickButton() : null}>
      {strings.call}
    </div>
  };

  const medicationCell = (item) => {
    const startTime = item?.start_time?.split("T")[1];
    const endTime = item?.end_time?.split("T")[1];
    const isBetween = isTimeBetween(startTime, endTime);
    return <div className={`${classes.cursorStyle} ${classes.textStyle}`}
      disabled={!isBetween}
      onClick={() => onMedicationClick(item)}
    // onClick={() => isBetween ? onMedicationClick(item) : onMedicationClick(item)}
    >
      {strings.sendMedication}
    </div>
  };

  const getAppointmentType = (item) => {
    switch (item.appointment_type) {
      case 0: return "Online";
      case 1: return "In-Clinic";
    }
  }

  const renderStatus = (item) => <span
    className={appointmentClasses.statusStyle}
    style={{ background: getBackgroundColor(item?.status) }}
  >
    {getStatusText(item?.status)}
  </span>

  const getAppointedDoctor = (item) => {
    
    return (<div className={classes.detailCont}>
      {/* <img className={classes.imageStyle} src={item.profile_image?{uri:profile_image}:defaultUserPng} alt="doctor" /> */}
      <div style={{ marginLeft: 8 }}>
        <div>{item.name}</div>
        <div>{item.department}</div>
      </div>
    </div>)
  }

  const getDoctorName = (item) => item.name;

  const handleDownloadReport = (url) => {
    const a = document.createElement('a');
    a.setAttribute('download', 'reactflow.png');
    a.setAttribute('href', url);
    a.click();
  }

  const renderInvoice = (item) => <IconButton onClick={() => handleDownloadReport(item.invoiceUrl)}><SimCardDownloadOutlined /></IconButton>;

  const renderDoctorAppointmentAction = (item) => <IconButton onClick={() => handleDownloadReport(item.fileUrl)}><SimCardDownloadOutlined /></IconButton>;

  const getPatientAppointmentAction = (item) => <div className={`${classes.cursorStyle} ${classes.textStyle}`} onClick={() => handleCancleAppointment(item?._id)}>{strings.cancel}</div>;

  const bookAgainAppointmentAction = (item) => <div className={`${classes.cursorStyle} ${classes.textStyle}`} onClick={() => handleBookAgain()}>{strings.bookAgain}</div>;

  const renderReportAction = (item) => <>
    <IconButton onClick={() => handleDownloadReport(item.report)}><SimCardDownloadOutlined /></IconButton>
    {navigate !== 'doctor' && <IconButton onClick={() => handleDeleteReports(item._id)} ><DeleteOutline /></IconButton>}
  </>;

  const renderBillingStatus = (item) => {
    switch (item.status) {
      case 0: return <span className={classes.paidStyle}>paid</span>;
      case 1: return <span className={classes.refundStyle}>refund</span>;
      case 2: return <span className={classes.failedStyle}>failed</span>;
    }
  }

  const renderDesignation = (item) => {
    switch(item){
      case 1 : return "Accountant";
    }
  }

  const handleDetails = (item) => {  
    setSelectedListItem(item)
    handleForward();
  }

  const renderTableCell = (key, item, index) => {
    
    if (key === 'actions') {
      return (
        <div className={appointmentClasses.secCont} onClick={() => handleExpand(item, index)}>
        
          <IconButton>
            {expandedRow === index ? (
              <><span className={classes.textStyle}>{strings.hideDetail}</span>
                <ExpandLessIcon className={classes.iconStyle} />
              </>
            ) : (
              <><span className={classes.textStyle}>{strings.viewDetail}</span>
                <ExpandMoreIcon className={classes.iconStyle} />
              </>
            )}
          </IconButton>
        </div>
      );
    }

    const renderEmployeeStatus = (status) =>{
      if(status === 0){ 
        return <span  style={{color:colors.red}}>Inactive</span>
      }
      else if( status ===1){
        return <span style={{color:colors.green}} > Active</span>
      } else { 
        return "Status not available"
      }
    }
    // const renderpatientname =(item)=>{
    //   console.log("itemaapp",item)
    //   alert("hello")
    //   return (<div className={classes.detailCont}>
    //     <img className={classes.imageStyle} src={item.profile_image?{uri:profile_image}:defaultUserPng} alt="doctor" />
    //     <div style={{ marginLeft: 8 }}>
    //       <div>{item.name}</div>
    //       <div>{item.department}</div>
    //     </div>
    //   </div>)
    // }
    const renderIPDaction = (item) => {
      return(<IpdActionCell>
      <div className={`${'delete'} ${'actionButton'}`}>Delete</div>
      <div className={` ${'edit'} ${'actionButton'} `}>Edit</div>     
      </IpdActionCell>)
    }

    if (key === 'accept_decline') {
      return (<> <ButtonStyleContainer>
        <Button className={'accept'} onClick={() => appointmentDecision(item, true)}> Accept </Button>
        <Button className={'decline'} onClick={() => appointmentDecision(item, false)}> Decline</Button>
      </ButtonStyleContainer>
      </>)
    };

    if (key === 'patient_name') return item.patientInfo?.name;
    if (key === 'doctor_appointement_action') return renderDoctorAppointmentAction(item);
    if (key === 'doctor_appointment_slot') return renderTime(item);
    if (key === 'appointment_start_time') return moment(item.start_time).format("DD-MM-YYYY");
    if (key === "medication") return medicationCell(item);
    if (key === 'button') return buttonCell(item);
    if (key === 'pending_amount') return` ${curr?curr:"$"} ${item.amount}`;
    if (key === 'appointment_type') return getAppointmentType(item);
    if (key === 'doctor_name') return getDoctorName(item);
    if (key === 'updatedAt') return moment(item.updatedAt).format("DD-MM-YYYY h:mm A");
    if (key === 'status') return renderStatus(item);
    if (key === 'profile_image' ) return item.profile_image
    if (key === "appointed_doctor") return getAppointedDoctor(item.doctorInfo);
    if (key === "patient_appointment_start_time") return moment(item.start_time).format("DD-MM-YYYY h:mm A");
    if (key === 'payment_type') return item?.payment_type == "1" ? "Online" : "Offline";
    if (key === 'patient_status') return renderStatus(item);
    if (key === 'patient_appoint_action') return getPatientAppointmentAction(item);
    if (key === 'all_type') return <StyledTypeBox row={item}>{types[item.type]}</StyledTypeBox>;
    if (key === 'doctor_appointement_report_action') return renderReportAction(item);
    if (key === 'createdAt') return moment(item).format('DD-MM-YYYY')
    if (key === 'invoiceUrl') return renderInvoice(item);
    if (key === 'billingStatus') return renderBillingStatus(item);
    if (key === 'book_again_appointment_action') return bookAgainAppointmentAction(item);
    if (key === 'orgActions') return <span onClick={()=> handleDetails(item)} className={`${classes.cursorStyle} ${classes.textStyle}`}>{strings.viewDetail}</span>;
    if (key === 'patient_id') return item.uniqueID;
    if (key === 'patient_contact') return `${item.countryCode}-${item.phoneNumber}`;
    if (key === 'gender') return renderGender(item.gender);
    if (key === 'emergency_name') return item?.emergencyContacts[0]?.name;
    if (key === 'emergency_contact') return !! item?.emergencyContacts[0] ? `+${item?.emergencyContacts[0]?.country_code}-${item?.emergencyContacts[0]?.phone_number}` : '';
    if (key === 'doctor_id') return item._id;
    if (key === 'contact') return `${item?.country_code}-${item?.phone_number}`;
    if (key === 'permission') return renderPermission(item.permission);
    if (key === 'designation') return renderDesignation(item.designation);
    if (key === 'employeeStatus') return renderEmployeeStatus(item.status);
    if (key === 'ipdAction') return renderIPDaction(item); 
    if (key =="actionorg") return <button>Selec</button>
    return item[key];
  };

  return (<>
    <Table>
      <TableHead className={navigate === "scrollable" ? classes.header : ''}>
        <TableRow>
          {Object.values(fieldsOrder).map((heading, index) => (
            <StyledTableCell key={index}>{heading}</StyledTableCell>
          ))}
         

        </TableRow>
      </TableHead>
      <TableBody >
        {data.map((item, index) => (
          
          <React.Fragment key={index}>
            <TableRow className={expandedRow === index ? classes.selectedRow : ''}>
              {Object.keys(fieldsOrder).map((key, i) => (
                
                <StyledTableCell key={i}                                                                                                                                                                                                                                                  >
                  {renderTableCell(key, item, index)}
                </StyledTableCell>
              ))}
                <TableCell align="right">
        {/* <Button variant="contained" color="primary" onClick={() => { console.log('Button clicked!'); }}>
          View Details
        </Button> */}
      </TableCell>
            </TableRow>
            
            {expandedRow === index && (
              <TableRow>
                <StyledTableCell colSpan={Object.keys(fieldsOrder).length}>
                  <Accordion elevation={0}>
                    <AccordionDetails className='custom-accordion-details'>
                      <TabComponent tabs={appointmentTabList} tabValue={tabValue} setTabValue={setTabValue} handleTab={handleTab}
                        customTab={styleClasses.customTab}
                        customTabIndicator={styleClasses.customTabIndicator}
                      />
                      <div className='accordian-detail'>{children} </div>
                    </AccordionDetails>
                  </Accordion>
                </StyledTableCell>
              </TableRow>

            )}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>

    
    {!!list && list?.length > 10 &&
      <ContainerStyle>
        <Pagination
          count={Math.ceil(list.length / 10)}
          page={page}
          showFirstButton
          showLastButton
          onChange={handleChangePage}
        />
      </ContainerStyle>}
  </>
  );
};

export default Tables;
