import { Grid, Link } from "@mui/material";
import React, { useEffect } from "react";
import strings from "../../../../constant/strings";
import { dashoboardUseStyles } from "../../../../assets/styles/DoctorDashboardStyles";
import { getPatientDetailsForDoctorAction } from "../../../../redux/appointment/doctor/doctorAppointmentActions";
import { useDispatch, useSelector } from "react-redux";
import { LoadingContainer } from "../../../../assets/styles/CommonStyles";

const patientInf = {
  name: 'Name',
  email: 'Email',
  phoneNumber: 'Contact Number',
  gender: "Gender",
  dob: "Date of birth",
  address: 'Address',
  weight: 'Weight',
  height: "Height",
  appointmentSymptoms: "Symptoms",
  appointmentDocuments : "Documents",
};

const user = localStorage.getItem('userType')

const renderValue = (label, value) => {
  if ( label === "Gender"){
    switch(value){
      case 1: return "Male";
      case 2: return "Female";
      case 3: return "Other";
      default: return value;
    }
  }
if (label === "Symptoms"){
 return value.join(', ')
}
if ( label === "Documents") {
  
  return value.map((item, index) => (
    <Link 
      key={index} 
      href={item} 
      target="_blank" 
      className="classes.linkStyle" 
      style={{ textDecoration: 'none' }}
    >
      Document{`${index+1}`}
    </Link>
  ));
}
if(label ==="Weight"){
  return `${value} KG`
}
if(label ==="Height"){
  return `${value} CM`
}
  else return value;
}

const InfoGridItem = ({ label, value }) => {
  const classes = dashoboardUseStyles();
  return (<>
  {
    label === "Symptoms" || label === "Documents" ? <Grid item xs={12} className={classes.innerTextCont}>
    <span className={classes.labelStyle}>{label}:</span>
    <span className={`${classes.labelStyle} ${classes.valueStyle}` }>{renderValue(label, value)}</span>
    </Grid> :
    <Grid item xs={12} sm={6} lg={3} className={classes.innerTextCont}>
    <span className={classes.labelStyle}>{label}:</span>
    <span className={`${classes.labelStyle} ${classes.valueStyle}`}>{renderValue(label, value)}</span>
  </Grid> 
  }
  </>   
  );
};

const PatientInformation = ({ item }) => {
  const classes = dashoboardUseStyles();

  const { patientDetails, patientInfoListLoading } = useSelector((state) => ({
    patientDetails: state.doctorAppointment.patientInfo,
    patientInfoListLoading: state.doctorAppointment.patientInfoListLoading
  }));
  const patientInfoForOrg = {...patientDetails, appointmentDocuments: item.documents, appointmentSymptoms: item.symptoms}

  patientDetails?.emergencyContacts?.map((value)=> console.log(value.name))

  return (
    <Grid container spacing={0} className={classes.mainCont}>
      <Grid item xs={12} className={classes.headingTextStyle}>
        {strings.patientInformation}
      </Grid>
      {patientInfoListLoading ? <LoadingContainer>{strings.loading}</LoadingContainer> : Object.keys(patientInf).map(key => (
        !!patientDetails &&
        <InfoGridItem key={key} label={patientInf[key]} value={(user === 'organization' || user === 'subadmin') ? patientInfoForOrg[key] : patientDetails[key]} />
      ))}
      { (user === 'organization' || user === 'subadmin') ? <Grid item xs={12} >
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.headingTextStyle} mt={4}>{strings.patientEmergencyContact}</Grid>
          
          {patientDetails?.emergencyContacts?.map((value)=>{return <Grid item xs={12} md={6} lg={4}>
            <div>
              <span className={classes.labelStyle}>Name :</span>
              <span className={`${classes.labelStyle} ${classes.valueStyle}` }>{value.name}</span>
            </div>
            <div>
              <span className={classes.labelStyle}>Contact number :</span>
              <span className={`${classes.labelStyle} ${classes.valueStyle}` }>+{value.country_code}-{value.phone_number}</span>
            </div>
            <div>
              <span className={classes.labelStyle}>Relationship :</span>
              <span className={`${classes.labelStyle} ${classes.valueStyle}` }>{value.relation}</span>
            </div>
          </Grid>})}
          
        </Grid>       
      </Grid> : ''}
      
    </Grid>
  )
}

export default PatientInformation;
