export default {
  white: "#ffffff",
  black: "#000000",
  black1: "#2B2B2B",
  black2: "#1C2134",
  grey: "#CCCCCC",
  grey1: "#5E5E5E",
  grey2: "#333333",
  grey3: '#cfcfd7',
  red: "#F36363",
  green: "#2AAB6C",
  white1: "#F5F5F7",
  white2: "#f0f0f0",
  white3: "#F8F7F7",
  white4: "#F8F9FC",
  yellow : '#EAAD11',
  yellowLight: "#EAAD111A",
  // errorTextColor: "#9F215F",
  errorTextColor: "#D32F2F",
  warning: "#F36363",
  //primary: "linear-gradient(90deg, rgba(107,166,182,1) 0%, rgba(46,52,140,1) 85%)",
  primary: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
  textColor: 'linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)',
  lightBackground: 'linear-gradient(90deg, rgba(198,221,244,1) 0%, rgba(232,243,246,1) 100%)',
  mediumBackground: 'linear-gradient(180deg, rgba(24,69,150,0.8) 0%, rgba(63,162,199,0.8) 100%)',
  blackGradient: "linear-gradient(0deg, #000000, #000000)",
  shadowGradient: "rgba(192, 192, 192, 0.25)",
  blue: '#2AAB6C',
  linearGradiant:"linear-gradient(135deg, rgba(9, 126, 183, 1) 0%, rgba(42, 171, 108, 1) 100%)",
}
//export default {
//     white: "#ffffff",
//     black: "#000000",
//     black1: "#2B2B2B",
//     black2: "#1C2134",
//     grey: "#CCCCCC",
//     grey1: "#5E5E5E",
//     grey2: "#333333",
//     red: "#D3F2F",
//     green: "#2AAB6C",
//     white1: "#F5F5F7",
//     white2: "#f0f0f0",
//     white3: "#F8F7F7",
//     white4: "#F8F9FC",
//     // errorTextColor: "#9F215F",
//     errorTextColor: "#D32F2F",
//     warning: "#F36363",
//     primary: "linear-gradient(90deg, #097EB7 0%, #2AAB6C 100%)",
//     blackGradient: "linear-gradient(0deg, #000000, #000000)",
//     shadowGradient: "rgba(192, 192, 192, 0.25)"
//   primary: "linear-gradient(90deg, rgba(24,69,150,1) 0%, rgba(60,117,170,1) 44%, rgba(63,162,199,1) 88%)",

//   }