import React from "react";
import { commonMuiStyles } from "../../../assets/styles/CommonMUIStyles";
import { prevIPDAppointment } from "../../../constant/staticLists";
import Tables from "../../controls/Tables";

const prevIPDAppointmentData = [
  { name: "Doctor 1", date: "21-Dec-2023", type: "Online" },
  { name: "Doctor 2", date: "22-Dec-2023", type: "In-Clinic" },
  { name: "Doctor 3", date: "23-Dec-2023", type: "Online" },
  { name: "Doctor 4", date: "26-Dec-2023", type: "Online" },
  { name: "Doctor 5", date: "28-Dec-2023", type: "In-Clinic" },
];

function PrevAppointment() {
  const classes = commonMuiStyles();
  return (
    <>
      <div className={classes.scrollVertically} style={{ height: "220px" }}>
        <Tables
          fieldsOrder={prevIPDAppointment}
          data={prevIPDAppointmentData}
          navigate="scrollable"
        />
      </div>
    </>
  );
}

export default PrevAppointment;
