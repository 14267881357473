import { Box, Grid } from "@mui/material";
import React, { useEffect } from "react";
import styled from "styled-components";
import { OrgAppoinStyles } from "../../../../assets/styles/OrgAppointmentStyles";
import colors from "../../../../constant/colors";
import strings from "../../../../constant/strings";
import {
  BookingSummaryList,
  patientInfoList,
} from "../../../../constant/staticLists";
import { StyledButton } from "../../../../assets/styles/CommonStyles";
import moment from "moment";
import { getPatientDetailsForDoctorAction } from "../../../../redux/appointment/doctor/doctorAppointmentActions";
import { useDispatch, useSelector } from "react-redux";
import { renderGender } from "../../../../utils/GenericFunctions";

const MainContainer = styled.div(() => ({
  //height: "300px",
  background: "#FFF",
  boxShadow:
    "4px 4px 15px 0px rgba(192, 192, 192, 0.25), -4px -4px 15px 0px rgba(192, 192, 192, 0.25)",
  borderRadius: "8px",
  borderBottom: `4px solid ${colors.grey1}`,

  ".paddingItems": {
    padding: "20px",
  },
  ".textStyleOne": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },
  ".textStyleTwo": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
  },
  ".textStyleThree": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
  },
  ".textColorGrey": {
    color: colors.grey1,
  },
  ".heading": {
    textAlign: "center",
    background: colors.white1,
  },
}));

const DetailsCard = ({
  navigate,
  handleForward,
  heading,
  selectedListItem,
}) => {

  const orgAppoint = OrgAppoinStyles();
  const dispatch = useDispatch();

  const { userData, patientInfo } = useSelector((state) => ({
    userData: state.auth.userData,
    patientInfo: state.doctorAppointment.patientInfo,
  }));

  useEffect(() => {
    dispatch(
      getPatientDetailsForDoctorAction({
        token: userData.token,
        id: selectedListItem.patientInfo._id,
      })
    );
  }, []);

  const renderStatus = (status) => {
    console.log('status', status)
    switch(status){
      case 0: return <div style={{color: colors.yellow}}>Pending</div>
      case 1: return <div style={{color: colors.green}}>Approved</div>
      case 5: return <div style={{color: 'red'}}>Cancelled by doctor</div>
      case 3: return <div style={{color: 'red'}}>Cancelled by patient</div>
      case 4: return <div style={{color: colors.green}}>Completed</div>

    }
  };

  const renderItem = (id) => {
    switch (id) {
      case 0: return moment(selectedListItem.start_time).format("DD-MM-YYYY");
      case 1: return `${moment(selectedListItem.start_time).format(
          "hh:mm A"
        )} - ${moment(selectedListItem.end_time)
          .add(1, "seconds")
          .format("hh:mm A")}`;
      case 2:
        return selectedListItem.amount;
      case 3:
        return renderStatus(selectedListItem.status);
    }
  };

  const renderPatientDetails = (id) => {
    switch (id) {
      case 0:
        return (
          <div className="textStyleTwo ">
            {patientInfo.countryCode}-{patientInfo.phoneNumber}
          </div>
        );
      case 1:
        return (
          <div className="textStyleTwo">
            {renderGender(patientInfo.gender)}
          </div>
        );
    }
  };
  return (
    <>
      <MainContainer>
        <div className={`textStyleOne heading ${orgAppoint.marginStyle}`}>
          {heading}
        </div>
        <Grid container spacing={2} className="paddingItems">
          <Grid item xs={3} >
            <img
              src={navigate === "appointment"
                ? selectedListItem?.doctorInfo.profile_image?selectedListItem?.doctorInfo.profile_image:"/assets/img/user.png"
                : patientInfo?.profileImage?patientInfo?.profileImage:"/assets/img/user.png"
}              alt="doc-img"
              className={orgAppoint.profileImage}
            />
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <div className="textStyleOne">
              {navigate === "appointment"
                ? `Dr. ${selectedListItem.doctorInfo.name}`
                : patientInfo.name}
            </div>
            <div className="textStyleTwo textColorGrey">
              {navigate === "appointment"
                ? selectedListItem.doctorInfo.department
                : `DOB: ${patientInfo.dob}`}
            </div>
            <div className={`textStyleTwo textColorGrey `}>
              <img
                src="/assets/img/location.svg"
                className={orgAppoint.imageStyle}
              ></img>{" "}
              {navigate === "appointment"
                ? selectedListItem?.doctorInfo.address
                : patientInfo?.address}
            </div>
          </Grid>
          {navigate === "appointment"
            ? BookingSummaryList.map((item, id) => (
                <Grid item xs={6}>
                  <div className="textStyleThree">{item.name}</div>
                  <div className="textStyleTwo textColorGrey ">
                    {renderItem(id)}
                  </div>
                </Grid>
              ))
            : patientInfoList.map((item, id) => (
                <Grid item xs={6}>
                  <div className="textStyleThree">{item.name}</div>
                  {renderPatientDetails(id)}
                  {/* <div className="textStyleTwo textColorGrey"> 2e232</div> */}
                </Grid>
              ))}
          {navigate === "patientInfo" && (
            <Grid item xs={12}>
              <StyledButton onClick={handleForward}>
                View Patient Details
              </StyledButton>
            </Grid>
          )}
        </Grid>
      </MainContainer>
    </>
  );
};

export default DetailsCard;
