import { createAction } from '@reduxjs/toolkit';

//patient

export const loginAction = createAction('auth/login', (credentials) => {
  return {
    payload: credentials
  };
});

export const logoutAction = createAction('auth/logout', (token) => {
    return {
      payload: token,
    };
  });

  export const resetPasswordAction = createAction('auth/reset/password', (credentials, token) => {
    const payload = {credentials, token }
    return {
      payload: payload
    };
  });

  //doctor

  export const doctorlogoutAction = createAction('auth/doctor/logout', (token) => {
    return {
      payload: token,
    };
  });

export const doctorLoginAction = createAction('auth/doctor/login', (credentials) => {
  return {
    payload: credentials,
  };
});

//organization

export const organizationLoginAction = createAction('auth/organization/login', (credentials) => {
  return {
    payload: credentials,
  };
});

export const resetOrgPasswordAction = createAction('auth/organization/update-profile', (credentials, token) => {
  const payload = {credentials, token }
  return {
    payload: payload
  };
});
export const organizationlogoutAction = createAction('auth/organization/logout', (token) => {
  return {
    payload: token,
  };
});

//subadmin

export const SubadminLoginAction = createAction('auth/subadmin/login', (credentials) => {
  return {
    payload: credentials
  };
});
export const subadminlogoutAction = createAction('auth/subadmin/logout', (token) => {
  console.log("hello",token)
  return {
    payload: token,
  };
});

