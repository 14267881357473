import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pagination, PaginationItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { patientAppointmentHeadingList, patientAppointmentHeadingListTab3, patientAppointmentHeadingListTab4 } from "../../../constant/staticLists";
import * as mainService from "../../../services/mainService.js";
import strings from "../../../constant/strings";
import Tables from "../../../components/controls/Tables";
import { LoadingContainer, NoDetailsContainer } from "../../../assets/styles/CommonStyles";

const AppointMentList = ({ searchValue, tabValue, setBookAppointment, setStep }) => {
  const { userData, patientAppointmentList, loading } = useSelector((state) => ({
    userData: state.auth.userData,
    patientAppointmentList: state.appointment.patientAppointmentList || [],
    loading: state.appointment.loading
  }));
  
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(7); // You can adjust this as needed

  useEffect(() => {
    if (searchValue !== "") {
      let filteredList = patientAppointmentList.filter((item) =>
        item.doctorInfo.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setList(filteredList);
    } else {
      setList(patientAppointmentList);
    }
  }, [patientAppointmentList, searchValue]);

  useEffect(() => {
    // Reset to the first page when the list or search value changes
    setPage(1);
  }, [list]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleCancelAppointment = async (id) => {
    let value = { token: userData.token, data: { appointmentId: id } };
    const res = await mainService.cancelAppointmentByPatientApi(value);
    if (res.response_type === "fail") {
      // toast.error(res?.response?.message, { position: "bottom-center", closeButton: false })
    } else {
      // toast.success(res?.response?.message, { position: "bottom-center", closeButton: false })
      let updatedList = list.filter((item) => item._id !== id);
      setList(updatedList);
    }
  };

  const handleBookAgain = () => {
    setBookAppointment(1);
  };

  // Calculate the start and end index for pagination
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedList = list.slice(startIndex, endIndex);

  return (
    <>
      {loading ? (
        <LoadingContainer>{strings.loading}</LoadingContainer>
      ) : paginatedList && paginatedList.length > 0 ? (
        <>
          <Tables
            data={paginatedList}
            handleCancleAppointment={handleCancelAppointment}
            handleBookAgain={handleBookAgain}
            fieldsOrder={
              tabValue === 0 || tabValue === 1
                ? patientAppointmentHeadingList
                : tabValue === 2
                ? patientAppointmentHeadingListTab3
                : patientAppointmentHeadingListTab4
            }
          />
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Pagination
              count={Math.ceil(list.length / rowsPerPage)}
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                  {...item}
                />
              )}
            />
          </div>
        </>
      ) : (
        <NoDetailsContainer>{strings.noAppointmentAvailable}</NoDetailsContainer>
      )}
    </>
  );
};

export default React.memo(AppointMentList);
