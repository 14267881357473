import React, { useState } from "react";
import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { DeptHeading } from "../../../../../../assets/styles/CommonStyles";
import { useStyles } from "./MUIstyles";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Box } from "@mui/system";
import colors from "../../../../../../constant/colors";
import { useEffect } from "react";

const DeptCheckBox = ({ data, department, setDepartment }) => {
  const classes = useStyles();
  const [checkedValue, setCheckedValue] = useState([]);

  useEffect(()=>{ handleDepartment(checkedValue)},[checkedValue] )

  const handleChange = (value) => {
    if (checkedValue.includes(value)) {
      setCheckedValue(checkedValue.filter((item) => item !== value));
    } else {
      setCheckedValue([...checkedValue, value]);
    }
  };

  const handleDepartment = (value) => {
    if(value.length===2)
    setDepartment(3)
    else if(value.length===0){ setDepartment("")}
    else if(value.length===1){
      if(value[0]==="IPD")
      setDepartment(1)
    else if(value[0]==="OPD"){
      setDepartment(2)
    }
}

  }
  return (
    <>
      <Grid container spacing={2} display='flex' alignItems= 'center'  justifyContent='center' style={{marginTop: '8px'}}>
        {data.map((data, index) => (          
            <Grid item xs={5}
              key={index}
              className={`${classes.checkBoxContainer} ${
                checkedValue.includes(data.title)
                  ? classes.checkedCheckbox
                  : classes.uncheckedCheckbox
              }`}
              style={{margin: '0px 2px', display:'flex', alignItems: 'center', justifyContent: 'center'}}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedValue.includes(data.title)}
                    onChange={() => handleChange(data.title)}
                    hidden
                  />
                }
                label={
                    <Box textAlign="center">
                    <div className={classes.imageContainer}>
                      {checkedValue.includes(data.title) ? (
                        <CheckCircleOutlineIcon
                        sx={{ color: "linear-gradient(135deg, rgba(9, 126, 183, 1) 0%, rgba(42, 171, 108, 1) 100%)" }}
                        fontSize="small"
                      />
                      ) : (
                        <CheckCircleOutlineIcon
                          color = 'disabled'
                          fontSize="small"
                        />
                      )}
                    </div>
                    <DeptHeading style={{color: checkedValue.includes(data.title) ? colors.blue : '' }}> {data.title} </DeptHeading>
                    <DeptHeading
                      style={{color: checkedValue.includes(data.title) ? colors.blue : '', fontWeight: "400", fontSize: "12px" }}> {data.title}
                      {data.fullName}
                    </DeptHeading>
          </Box>
            
                }
                checked={checkedValue.includes(data.title)}
              />
            </Grid>
          
        ))}
      </Grid>
    </>
  );
};

export default DeptCheckBox;
