import React, { useEffect, useState,useLayoutEffect } from "react";
import EmployeeListUI from "../EmployeeListUI";
import strings from "../../../../constant/strings";
import AddPatient from "../../../Modal/AddPatient";
import { modalStyle } from "../../../../assets/styles/CommonStyles";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getPatientListAction } from "../../../../redux/list/listAction";
import { PatientListFields } from "../../../../constant/staticLists";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import IPDPatientDetails from "../../IPDPatientDetails.js";
import PatientInfo from "../../../Modal/PatientInfo";

function PatientList({ navigate}) {
  const [openModal, setOpenModal] = useState(false);
  const [openModala, setOpenModala] = useState(false);
  const [selectedListItems, setSelectedListItems] = useState();
  const [list, setList] = useState([]);
  const history = useHistory()
  const [screen, setScreen] = useState(1)
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { PatientList, userData } = useSelector((state) => ({
    userData: state.auth.userData,
    PatientList: state.organizationList.PatientList,
  }));
  useEffect(() => {
    if (navigate !== "subadmin") dispatch(getPatientListAction(userData.token));
  }, []);

  useEffect(() => {
    setList(PatientList);
  }, [PatientList]);
  useEffect(() => {
    if (loading) {
      // Re-fetch the doctor list when loading changes to true
      dispatch(getPatientListAction(userData.token));
      setLoading(false); // Reset loading after fetching data
    }
  }, [loading, dispatch, userData.token]);
  const handleClose = () => {
    
    setOpenModala(false);
  };
  const handleForward = () => {
    setScreen((prev)=> prev+1)
    setOpenModala(true)
    
  }
  const handleBackward = () => {
    setScreen((prev)=> prev-1)
  }
  const setSelectedListItem=(item)=>{
    
    setSelectedListItems(item._id)
   
  }
  console.log("setOpenModal",openModala)
  const handlemodle=()=>{
   
    setOpenModal(!openModal)
  }
  return (
    <>
    {
      screen ===0 ?
      history.push("/organization/dashboard"):
      // alert("hello")
      screen === 1?
      <>
      <EmployeeListUI
      primaryText={strings.patient}
      secondaryText={strings.opdIpdList}
      addButton="Add Patient"
      searchPlaceholderText={strings.searchByPatientName}
      total={strings.totalPatient}
      setOpenModal={setOpenModal}
      openModal={openModal}
      data={PatientList}
      fieldsOrder={PatientListFields}
      list={list}
      setList={setList}
      setScreen = {setScreen}
      handleForward = {handleForward}
      setSelectedListItem={setSelectedListItem}
      handleBackward={handleBackward}
    />

    <Modal
      keepMounted
      open={openModal}
      scroll="paper"
      //onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box style={modalStyle}>
        <AddPatient setOpenModal={setOpenModal} openModal={openModal} />
      </Box>
    </Modal> </>:
    <>

     <Modal
     keepMounted
     open={handlemodle}
     onClose={handleClose}
     aria-labelledby="keep-mounted-modal-title"
     aria-describedby="keep-mounted-modal-description"
   >
     <Box style={modalStyle}>
       
         <PatientInfo setOpenModal={setOpenModala}  setScreen={setScreen} id = {selectedListItems} setLoading={setLoading}/>
       
     </Box>
   </Modal></>
    }
     
    </>
  );
}

export default PatientList;
