import React , {useState} from 'react';
import {StyledSecondaryButton, StyledDivider, StyledMargin, StyledText, ButtonImage} from "../../../../assets/styles/CommonStyles";
import { Grid,  CircularProgress } from '@mui/material';
import strings from "../../../../constant/strings";
import { useStyles } from "../../../../assets/styles/RadioButtonStyles";
import { PDData } from "../../CommonSignUpModal/staticData"
import DeptCheckBox from './commonComponents/DeptCheckBox.js';
import {ranges} from "../../CommonSignUpModal/staticData";
import RadioButtonBox from './commonComponents/RadioButtonBox.js';
import { useEffect } from 'react';


const HospitalDetails = ({handleForward, handleBackward, setData}) => {
    const classes = useStyles();
    const [department, setDepartment] = useState("");
    const [practictioner_range, setPractictionerRange] = useState({from:'', to:''})
    const [checked, setChecked] = useState(true);

    useEffect(()=>{
      handleCheck();
    },[department,practictioner_range])

    const handleCheck=()=> {
      if(department!=='' && practictioner_range.from !== "" && practictioner_range.to !== "" ){
        if(checked){
          setChecked(false)
        }
      } else {
        if(!checked){
          setChecked(true)
        }
      }
      //console.log('checkButton')
      //setChecked(checkButton)  
    }

    const handleDetails = () => {
    console.log(setData(prev => {return { ...prev, department: department, practictioner_range: practictioner_range }}))  
    handleForward()
    }

  return (
    <>
      <Grid container spacing={2}>
      <Grid  item xs = {12}>
    <StyledMargin>
          <StyledText style={{textAlign:"left"}}>{strings.selectDepartment}
          <span style={{fontSize:"12px", lineHeight: "24px"}}> {strings.selectBoth}
            </span></StyledText>
    </StyledMargin>
    </Grid>
    <Grid  item xs = {12}>
    <DeptCheckBox data={PDData} department={department} setDepartment={setDepartment} /></Grid> 
    <Grid  item xs = {12}>
    <StyledMargin>
          <StyledText style={{textAlign:"left"}}>{strings.selectRange}
          </StyledText>
     </StyledMargin>
    </Grid> 
    <Grid  item xs = {12}>
    <StyledMargin>
    <RadioButtonBox data={ranges} practictioner_range={practictioner_range} setPractictionerRange={setPractictionerRange} />
     </StyledMargin>
    
    </Grid> 
    <Grid  item xs = {12}>
    <Grid container spacing={2} padding="20px 0 0 0">
          <Grid item xs={6}>
          <div className={classes.buttonContainerLeft}>
                <StyledSecondaryButton
                  variant="contained"
                  onClick={handleBackward}>
                  <ButtonImage
                    src={"/assets/img/back.png"}
                    alt= "backButton"
                    ></ButtonImage>
                </StyledSecondaryButton>
                </div>
          </Grid>
          <Grid item xs={6}>
          <div className={classes.buttonContainerRight}>
                <StyledSecondaryButton
                  disabled={checked}
                  variant="contained"
                  onClick={handleDetails}
                  style={{background: checked ? '#F5F7F8':''}}>                 
                  <ButtonImage
                    src={"/assets/img/forward.png"}
                    alt= "forwardButton"
                    ></ButtonImage>
                </StyledSecondaryButton>
                </div>
          </Grid>
        </Grid>
    </Grid> 
      </Grid>
    </>
  );
};

export default HospitalDetails;
